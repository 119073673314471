import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import PhoneInput from 'react-phone-input-2';
import { INDUSTRY_TYPES, COUNTRIES } from 'utils/constants';
import schema from './schema';

function Form({ submit, clean }) {
  const { translate } = useContext(I18nContext);
  const {
    handleSubmit, register, errors, control, reset,
  } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  });

  useEffect(() => {
    reset();
  }, [clean, reset]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      style={{ padding: 15 }}
      autoComplete="off"
      noValidate
    >
      <Container maxWidth="sm">
        <Typography className="text-center" variant="h4">
          {translate('createYourAccount')}
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {translate('personalInformation')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="user.name"
              error={errors.user && !!errors.user.name}
              label={translate('fullName')}
              helperText={
                errors.user
                && errors.user.name
                && translate(errors.user.name.message)
              }
              type="text"
              variant="outlined"
              inputRef={register}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="user.email"
              error={errors.user && !!errors.user.email}
              label={translate('email')}
              helperText={
                errors.user
                && errors.user.email
                && translate(errors.user.email.message)
              }
              type="email"
              variant="outlined"
              inputRef={register}
              fullWidth
              required
            />
          </Grid>
          <Grid xs={3} className="phone-input" item>
            <Controller
              control={control}
              name="user.phone_country_code"
              defaultValue="57"
              as={(
                <PhoneInput
                  country="co"
                  defaultCountry="co"
                  onlyCountries={COUNTRIES}
                  placeholder="code"
                  inputProps={{ disabled: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="user.phone_number"
              error={errors.user && !!errors.user.phone_number}
              label={translate('phone')}
              helperText={
                errors.user
                && errors.user.phone_number
                && translate(errors.user.phone_number.message)
              }
              type="text"
              variant="outlined"
              inputProps={{ maxLength: '11' }}
              inputRef={register}
              autoComplete="phone"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="user.password"
              error={errors.user && !!errors.user.password}
              label={translate('password')}
              helperText={
                errors.user
                && errors.user.password
                && translate(errors.user.password.message)
              }
              type="password"
              variant="outlined"
              inputRef={register}
              autoComplete="new-password"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{translate('companyData')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="organization.name"
              error={errors.organization && !!errors.organization.name}
              label={translate('organizationName')}
              helperText={
                errors.organization
                && errors.organization.name
                && translate(errors.organization.name.message)
              }
              type="text"
              variant="outlined"
              inputRef={register}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="organization.industry_type_cd">
                {translate('organizationType')}
                *
                {' '}
              </InputLabel>
              <Controller
                name="organization.industry_type_cd"
                control={control}
                label={translate('organizationType')}
                defaultValue={INDUSTRY_TYPES[0]._id}
                as={(
                  <Select>
                    {INDUSTRY_TYPES.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {translate(option.name)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="organization.website"
              error={errors.organization && !!errors.organization.website}
              label={translate('webPage')}
              placeholder="https://piboxenterprise.picap.app"
              helperText={
                errors.organization
                && errors.organization.website
                && translate(errors.organization.website.message)
              }
              type="text"
              variant="outlined"
              inputRef={register}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={(
                <FormControl error={!!errors.terms} required>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={(
                      <Button
                        color="primary"
                        component={Link}
                        to="/terms"
                        target="_blank"
                      >
                        {translate('acceptTermsAndConditions')}
                      </Button>
                    )}
                  />
                  <FormHelperText>
                    {errors.terms && translate(errors.terms.message)}
                  </FormHelperText>
                </FormControl>
              )}
              name="terms"
              rules={register}
              defaultValue={false}
              control={control}
            />
          </Grid>
          <Grid item xs={12} className="text-center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
            >
              {translate('createAccount')}
            </Button>
          </Grid>
          <Grid item xs={12} className="text-center">
            <Button color="primary" component={Link} to="/login" size="large">
              {translate('haveAnAccount')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
}

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  clean: PropTypes.bool.isRequired,
};

export default Form;
