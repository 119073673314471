import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, MoreVert } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles';
import clsx from 'clsx';

const MenuMobile = ({ classes, sections, translate }) => {
  const [openMenuLateral, setOpenMenuLateral] = useState(false);
  const [openSubMenuMobile, setOpenSubMenuMobile] = useState(true);

  const subMenuOptions = (section) => (
    <ListItem
      button
      className={classes.listItem}
      onClick={() => setOpenSubMenuMobile(!openSubMenuMobile)}
    >
      <ListItemText
        primary={translate(section.label)}
        style={{ paddingRight: '8px' }}
      />

      {openSubMenuMobile ? <ExpandLess color="primary" /> : <ExpandMore />}
    </ListItem>
  );

  const menuOptionsInternal = (section, indexMenu) => (
    <ListItem
      button
      className={clsx(
        classes.listItem,
        indexMenu === 0 && classes.backgroundSelectedMenu
      )}
    >
      <Link to={`${section.src}`} className={classes.link}>
        <ListItemText
          primary={translate(section.label)}
          style={{ paddingRight: '8px' }}
        />
      </Link>
    </ListItem>
  );

  const menuOptionsExternal = (section) => (
    <ListItem
      button
      component="a"
      href="https://www.enviosylogistica.com"
      className={classes.listItem}
    >
      <ListItemText
        primary={translate(section.label)}
        style={{ paddingRight: '8px' }}
      />
    </ListItem>
  );

  const menuOptions = (section, index) =>
    section.src !== null
      ? menuOptionsInternal(section, index)
      : menuOptionsExternal(section);

  return (
    <div className={classes.container}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={() => setOpenMenuLateral(true)}
        className={classes.iconMenu}
      >
        <MoreVert />
      </IconButton>
      <Drawer
        anchor="right"
        open={openMenuLateral}
        onClose={() => setOpenMenuLateral(false)}
      >
        {sections.map((section, index) => (
          <div key={`content-${index}`}>
            {section.options.length > 0
              ? subMenuOptions(section)
              : menuOptions(section, index)}

            {section.options.length > 0 && openSubMenuMobile && (
              <Collapse in={true} timeout="auto" unmountOnExit>
                {section.options.map((option, indexSubMenu) => (
                  <ListItem
                    key={`list-${indexSubMenu}`}
                    style={{ paddingLeft: '35px' }}
                  >
                    <Link to={`${option.src}`} className={classes.link}>
                      <ListItemText primary={translate(option.label)} />
                    </Link>
                  </ListItem>
                ))}
              </Collapse>
            )}
          </div>
        ))}
      </Drawer>
    </div>
  );
};

export default withStyles(styles)(MenuMobile);
