import React, { useContext, Fragment, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { I18nContext } from 'translations';

const Language = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { langCode, translate, dispatch } = useContext(I18nContext);

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const onLanguageSelect = (_, code) => {
    dispatch({ type: 'SET_LANGUAGE', payload: code });
    setAnchorEl(null);
    localStorage.setItem('lang', code);
  };

  const renderOption = (code, language) => (
    <MenuItem
      onClick={(e) => onLanguageSelect(e, code)}
      value={code}
      selected={code === langCode}
    >
      {language}
    </MenuItem>
  );

  return (
    <Fragment>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        style={{ minWidth: 'auto' }}
      >
        <img src={`/images/landing/flag-${langCode}.png`} alt="" width="30" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {renderOption('es', translate('spanish'))}
        {renderOption('en', translate('english'))}
        {renderOption('pt', translate('portuguese'))}
      </Menu>
    </Fragment>
  );
};

export default Language;
