import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Map } from '@material-ui/icons';
import addon from 'utils/addon';
import { ADDONS } from 'utils/constants';

function RoutesMenu({
  translate, classes, handleCollapse, openCollapse,
}) {
  return (
    <>
      <ListItem
        button
        onClick={() => handleCollapse('routes')}
        className={
          openCollapse.routes ? classes.activeCollapse : classes.itemMenu
        }
      >
        <ListItemIcon>
          <Map color="primary" />
        </ListItemIcon>
        <ListItemText primary={translate('menu.routes')} />
        {openCollapse.routes ? <ExpandLess color="primary" /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse.routes} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/optimize-routes"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.optimizeRoutes')} />
          </ListItem>
          {/* <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/headquarters"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.headquarters')} />
          </ListItem> */}
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/vehicles"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.vehicles')} />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/routes-file-list"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.optimizationHistory')} />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}

const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: false,
};
export default addon(RoutesMenu, addonOptions);
