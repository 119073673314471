import React, {
  useEffect, useContext, useState,
} from 'react';
import { Snackbar, Typography } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  getRoutefileList,
  updateRouteFileStatus,
} from 'context/routes/actions';
import Loading from 'components/Loading';
import RouteFileTable from 'components/Tables/Routes/RouteFileTable';

const RouteFileList = () => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(10);
  const [{ optimizedRoutes }, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);

  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const getFileList = async (page) => {
    setLoading(true);
    const callback = await getRoutefileList(dispatch, {
      page,
      per_page: perPage,
    });
    callback ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    getFileList(1);
  }, []); // eslint-disable-line

  useEffect(() => {
    const updateFileStatus = () => {
      if (
        optimizedRoutes
        && !optimizedRoutes.updatingFileStatus
        && optimizedRoutes.fileStatusList
        && optimizedRoutes.pendingFiles
        && optimizedRoutes.pendingFiles.length
      ) {
        updateRouteFileStatus(
          dispatch,
          optimizedRoutes.pendingFiles,
          optimizedRoutes.fileStatusList,
        );
      }
    };

    const interval = setInterval(updateFileStatus, 5000);

    if (
      optimizedRoutes
      && optimizedRoutes.pendingFiles
      && !optimizedRoutes.pendingFiles.length
    ) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [dispatch, optimizedRoutes]);

  return (
    <div>
      {loading && <Loading />}
      <Typography variant="h4" style={{ margin: '5px 0 25px' }}>
        {translate('routes.listOfUploadedFiles')}
      </Typography>
      {optimizedRoutes
        && optimizedRoutes.routeFileList
        && optimizedRoutes.fileStatusList && (
          <RouteFileTable
            routes={optimizedRoutes.routeFileList}
            recordsPerPage={perPage}
            getRoutes={getFileList}
            statusList={optimizedRoutes.fileStatusList}
          />
      )}
      <Snackbar
        open={!!message}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
      />
    </div>
  );
};

export default RouteFileList;
