import { GET_REPORT } from './constants';

const reportReducer = (state, action) => {
  switch (action.type) {
    case GET_REPORT: 
      return {
        ...state,
        reports: action.reports
      }
    default:
      return state;
  }
}

export default reportReducer;
