import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  Typography,
} from '@material-ui/core';

function InvalidCell({ error, content }) {
  return (

    <TableCell>
      {content}
      {Boolean(error) && (
      <Typography variant="caption" color="error">
        <br />
        {error}
      </Typography>
      )}
    </TableCell>

  );
}

InvalidCell.propTypes = {
  error: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InvalidCell;
