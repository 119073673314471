import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import MenuUser from 'components/Layout/MenuUser';
import Language from 'components/Language';
import { closeSession } from 'context/users/actions';
import styles from './style';

const Header = () => {
  const { translate } = useContext(I18nContext);
  const classes = styles();
  const [{ checkin }, dispatch] = useStateValue();
  const [anchorEl, setAnchorEl] = useState(null);

  const logout = async () => {
    await closeSession(dispatch);
    window.location.href = '/login';
  };
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <AppBar position="sticky" color="default">
      <Toolbar className={classes.bar}>
        <Link to="/">
          <img src="/logo.png" className={classes.logo} alt="logo" />
        </Link>
        <Box>
          {checkin.checkin ? (
            <MenuUser
              closeSession={logout}
              handleOpenMenu={handleOpenMenu}
              checkin={checkin.checkin}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              handleCloseMenu={handleCloseMenu}
              translate={translate}
            />
          ) : (
            <Button href="/login" color="primary">
              {translate('logIn')}
            </Button>
          )}
          <Language />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
