import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Box } from '@material-ui/core';
import { Loading, PaperBlock, BackButtonTitle } from 'components';
import { modalMessage } from 'utils';
import { useStateValue } from 'context/store';
import { createVehicle } from 'context/vehicles/actions';
import { I18nContext } from 'translations';
import Form from './form/FormVehicle';

AddVehicle.propTypes = {
  history: PropTypes.object.isRequired,
};

function AddVehicle({ history }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [, dispatch] = useStateValue();
  const { langCode, translate } = useContext(I18nContext);
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('vehicles.successfull.created.title'),
      translate('vehicles.successfull.created.description'),
      'success',
      translate('continue'),
    ).then((result) => {
      result.value && history.push('/app/vehicles');
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    values['volume'] = 0; //temporary option
    const callback = await createVehicle(
      dispatch,
      { vehicle_profile: { ...values } },
      langCode,
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('vehicles.add')}
          link="/app/vehicles"
          buttonName={translate('back')}
        />
        <Grid container spacing={5} justify="center">
          <Grid item md={8}>
            <Box mt={4}>
              <Form submit={onSubmit} translate={translate} />
            </Box>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

export default AddVehicle;
