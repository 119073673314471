import { db, timestamp } from '.';

const organizations = 'organizations';
const events = 'events';
const chats = 'chats';
const messages = 'messages';

/* Task status */

export const listenerEvents = (organizationId) => db.collection(organizations)
  .doc(organizationId)
  .collection(events);

export const removeEvent = (organizationId, doc) => db.collection(organizations)
  .doc(organizationId)
  .collection(events)
  .doc(doc)
  .delete()
  .then(() => {
  })
  .catch((error) => {
    console.error(error);
  });

/* Chat */

const chatsCollection = db.collection(chats);
const messagesCollection = (chatId) => chatsCollection.doc(chatId).collection(messages);

export const queryChats = (operatorId) => chatsCollection.where('operatorId', '==', operatorId);
export const queryMessages = (chatId) => messagesCollection(chatId).orderBy('createdAt', 'asc');

export const newMessage = (chatId, msg) => {
  const message = {
    ...msg,
    createdAt: timestamp.now(),
  };
  return messagesCollection(chatId)
    .add(message)
    .catch((error) => console.error(error));
};

export const updateChat = (chatId, data) => chatsCollection
  .doc(chatId)
  .set(data, { merge: true })
  .catch((error) => console.error(error));

export const deleteChat = (chatId) => chatsCollection
  .doc(chatId)
  .delete()
  .catch((error) => console.error(error));
