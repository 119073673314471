import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, TextField, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import schema from './schema';

const styles = makeStyles(() => ({
  marginBottomField: {
    marginBottom: '25px'
  }
}));

function Form({ submit, translate }) {
  const classes = styles();
  const { handleSubmit, register, errors } = useForm({
    validationSchema: schema
  });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>

      <TextField
        name="email"
        error={!!errors.email}
        label={translate('email')}
        helperText={errors.email && translate(errors.email.message)}
        type="email"
        variant="outlined"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
      />

      <TextField
        name="password"
        error={!!errors.password}
        label={translate('password')}
        helperText={errors.password && translate(errors.password.message)}
        type="password"
        variant="outlined"
        inputRef={register}
        autoComplete="no-password"
        fullWidth
        className={classes.marginBottomField}
      />

      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.marginBottomField}
      > 
        {translate('logIn')}
      </Button>

      <Grid container>
        <Grid item xs={12} sm={6} className="text-left text-center-xs">
          {/*<Button
            color="primary"
            component={Link}
            to="/signup"
            size="large"
          >
            {translate('createYourAccount')}
          </Button>*/}
        </Grid>
        <Grid item xs={12} sm={6} className="text-right text-center-xs">
          <Button
            color="primary"
            component={Link}
            to="/recover-password"
            size="large"
          >
            {translate('forgotMyPassword')}
          </Button>
        </Grid>
      </Grid>

    </form>
  );
}

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default Form;
