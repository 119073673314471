import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, Timer, SettingsEthernet } from '@material-ui/icons';
import ListTask from './components/ListTask/ListTask';
import { I18nContext } from 'translations';
import styles from './style';
import { Pagination } from 'components';

function ListAccordionTask({
  classes,
  data,
  page,
  recordsPerPage,
  shareLink,
  selectedTask,
  handleEvidence,
  handleCancel,
  handleChangePage,
  handleChangeSelectedTask,
}) {
  const { translate } = useContext(I18nContext);
  const [expandedRoute, setExpandedRoute] = useState(null);

  const handleAccordion = (routeId) => (event, isExpanded) => {
    setExpandedRoute(isExpanded ? routeId : null);
  };

  useEffect(() => {
    if (data.data.length) {
      if (!selectedTask) {
        setExpandedRoute(data.data[0]._id);
      } else {
        let index = data.data.findIndex(
          (route) => route._id === selectedTask.route
        );
        if (index < 0) index = 0;
        const id = data.data[index]._id;
        setExpandedRoute(id);
        scrollToTask(selectedTask.id);
      }
    } // eslint-disable-next-line
  }, [selectedTask]);

  const scrollToTask = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  return (
    <>
      {data.data.map((item, index) => (
        <Accordion
          key={index.toString()}
          className={classes.containerAccordion}
          expanded={item._id === expandedRoute}
          onChange={handleAccordion(item._id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.titleAccordion}
          >
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <Grid item container lg={12} xs={12}>
                  <b>
                    {`${translate('routes.optimizedRoute')} # 
                  ${
                    page === 0
                      ? index + 1
                      : (page - 1) * recordsPerPage + index + 1
                  }
              `}
                  </b>
                </Grid>
                <Grid item container md={12} xs={12}>
                  <Box mt={1}>
                    {item.driver ? (
                      <span className={classes.driverName}>
                        Conductor asignado:&nbsp;
                        {item.driver.name}
                      </span>
                    ) : (
                      <span className={classes.noDriver}>
                        {translate('routes.withoutDriver')}
                      </span>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid item container lg={6} xs={12}>
                <Box display="flex" alignItems="center">
                  {item.estimated_transport_time && (
                    <Tooltip
                      title={translate('routes.estimatedTransportTime')}
                      placement="top"
                      arrow
                      enterTouchDelay={1}
                    >
                      <span className={classes.routeTime}>
                        <Timer />
                        &nbsp;
                        {item.estimated_transport_time}
                      </span>
                    </Tooltip>
                  )}
                  &nbsp;&nbsp;
                  {item.estimated_distance && (
                    <Tooltip
                      title={translate('routes.estimatedDistance')}
                      placement="top"
                      arrow
                      enterTouchDelay={1}
                    >
                      <span className={classes.routeTime}>
                        <SettingsEthernet />
                        &nbsp;
                        {item.estimated_distance}
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <ListTask
              data={item.tasks}
              shareLink={shareLink}
              handleEvidence={handleEvidence}
              handleCancel={handleCancel}
              handleChangeSelectedTask={handleChangeSelectedTask}
              selectedTask={selectedTask}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="text-right">
        <Pagination
          handleChangePage={handleChangePage}
          page={page}
          perPage={recordsPerPage}
          totalPages={parseInt(data.pages, 10)}
        />
      </div>
    </>
  );
}

ListAccordionTask.propTypes = {
  data: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number,
};

export default withStyles(styles)(ListAccordionTask);
