import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  Box,
  Paper,
} from '@material-ui/core';
import { ExpandMore, Edit } from '@material-ui/icons';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { TYPE_CD, COUNTRIES } from 'utils/constants';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import Drivers from 'components/Modals/Drivers';
import MomentUtils from '@date-io/moment';

function Form({
  submit,
  handleAddress,
  isValidOriginAddress,
  translate,
  langCode,
  tasks,
  optimize,
}) {
  const [address, setAddress] = useState([
    {
      name: tasks ? tasks.address : '',
      lat: tasks ? tasks.lat : '',
      lon: tasks ? tasks.lon : '',
    },
  ]);
  const [driver, setDriver] = useState(null);
  const [isValidAddress, setIsValidAddress] = useState(!!tasks);
  const [openDriver, setOpenDriver] = useState(false);

  const { register, handleSubmit, control, errors, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { tasks: [{}] },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  const handleDriver = () => setOpenDriver(!openDriver);
  const assignDriver = (selectDriver) => {
    setValue('driver_id', selectDriver._id);
    setDriver(selectDriver.name);
    setOpenDriver(false);
  };

  const handleSuggest = (info, index, id) => {
    const updateRoutes = [...address];
    updateRoutes[index] = { ...address[index], ...info };
    setAddress(updateRoutes);
    if (info.lat) {
      handleAddress(info, index, id);
      setIsValidAddress(true);
    } else {
      setIsValidAddress(false);
    }
  };

  const addTasks = () => {
    append({});
    setIsValidAddress(false);
  };

  const removeTasks = (index, id) => {
    remove(index);
    handleAddress(null, index, id);
    setIsValidAddress(true);
  };

  useEffect(() => {
    tasks &&
      handleSuggest({ name: tasks.address, lat: tasks.lat, lon: tasks.lon }, 0); // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      {!tasks && !optimize && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Button
            type="button"
            color="secondary"
            variant="contained"
            size="large"
            onClick={handleDriver}
          >
            {translate('task.selectDriver')}
          </Button>
          <TextField
            name="driver_id"
            inputRef={register}
            style={{ width: 0 }}
            className="opacity-0"
          />
          {driver && (
            <Typography variant="h6" style={{ marginLeft: 10 }}>
              {`Conductor: ${driver}`}
            </Typography>
          )}
        </Grid>
      )}

      {fields.map((item, index) => (
        <Accordion defaultExpanded name={item.id} key={item.id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">{translate('task')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} justify="center">
              <Grid item md={6} sm={12} xs={12} className="text-left">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="type_cd">
                    {translate('type')}*{' '}
                  </InputLabel>
                  <Controller
                    control={control}
                    name={`tasks[${index}].type_cd`}
                    defaultValue={tasks ? tasks.type_cd : 0}
                    label={`${translate('type')}* `}
                    variant="outlined"
                    as={
                      <Select>
                        {TYPE_CD.filter((option) => option.select).map(
                          (option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {translate(option.name)}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  type="text"
                  name={`tasks[${index}].external_id`}
                  defaultValue={tasks ? tasks.external_id : item.external_id}
                  variant="outlined"
                  label={translate('orderId')}
                  fullWidth
                  required
                  inputRef={register({
                    required: `${translate('requiredField')}`,
                  })}
                  error={
                    errors.tasks &&
                    errors.tasks[index] &&
                    !!errors.tasks[index].external_id
                  }
                  helperText={
                    errors.tasks &&
                    errors.tasks[index] &&
                    errors.tasks[index].external_id &&
                    errors.tasks[index].external_id.message
                  }
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12} className="text-left">
                <AutocompleteInput
                  title={translate('address')}
                  name={`tasks[${index}].address`}
                  address={tasks && address ? address[0] : address}
                  pos={{ lat: '4.653400', lon: '-74.109309' }}
                  lang={langCode}
                  handleChange={(info) => handleSuggest(info, index, item.id)}
                />
                <small className="helper-text">
                  {translate('selectAddressFromList')}
                </small>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  type="text"
                  name={`tasks[${index}].secondary_address`}
                  defaultValue={
                    tasks ? tasks.secondary_address : item.secondary_address
                  }
                  label={translate('task.towerAptLocal')}
                  fullWidth
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name={`tasks[${index}].description`}
                  label={translate('description')}
                  defaultValue={tasks ? tasks.description : item.description}
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  required
                  inputRef={register({
                    required: `${translate('requiredField')}`,
                  })}
                  error={
                    errors.tasks &&
                    errors.tasks[index] &&
                    !!errors.tasks[index].description
                  }
                  helperText={
                    errors.tasks &&
                    errors.tasks[index] &&
                    errors.tasks[index].description &&
                    errors.tasks[index].description.message
                  }
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Controller
                    as={
                      <DateTimePicker
                        format="DD/MM/YYYY hh:mm:ss"
                        inputVariant="outlined"
                        label={translate('task.initDate')}
                        fullWidth
                        onChange={() => null}
                        minDate={new Date()}
                        value=""
                      />
                    }
                    name={`tasks[${index}].init_date`}
                    control={control}
                    defaultValue={tasks ? tasks.init_date : null}
                    rules={register({
                      required: `${translate('requiredField')}`,
                    })}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Controller
                    as={
                      <DateTimePicker
                        format="DD/MM/YYYY hh:mm:ss A"
                        inputVariant="outlined"
                        label={translate('task.dueDate')}
                        fullWidth
                        onChange={() => null}
                        minDate={new Date()}
                        value=""
                        required
                        inputRef={register({
                          required: `${translate('requiredField')}`,
                        })}
                        error={
                          errors.tasks &&
                          errors.tasks[index] &&
                          !!errors.tasks[index].due_date
                        }
                      />
                    }
                    name={`tasks[${index}].due_date`}
                    control={control}
                    defaultValue={tasks ? tasks.due_date : null}
                    rules={register({
                      required: `${translate('requiredField')}`,
                    })}
                  />
                </MuiPickersUtilsProvider>
                {errors.tasks &&
                  errors.tasks[index] &&
                  !!errors.tasks[index].due_date && (
                    <FormHelperText error>
                      {errors.tasks[index].due_date.message}
                    </FormHelperText>
                  )}
              </Grid>

              <Grid item md={12} sm={12} xs={12} className="text-left">
                {translate('personalInformation')}
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  name={`tasks[${index}].customer.name`}
                  label={translate('fullName')}
                  defaultValue={tasks ? tasks.customer.name : item.name}
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{ maxLength: '70' }}
                  inputRef={register({
                    required: `${translate('requiredField')}`,
                  })}
                  error={
                    errors.tasks &&
                    errors.tasks[index] &&
                    errors.tasks[index].customer &&
                    !!errors.tasks[index].customer.name
                  }
                  helperText={
                    errors.tasks &&
                    errors.tasks[index] &&
                    errors.tasks[index].customer &&
                    errors.tasks[index].customer.name &&
                    errors.tasks[index].customer.name.message
                  }
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12} className="text-left">
                <Grid container spacing={1}>
                  <Grid
                    item
                    md={optimize ? 5 : 4}
                    sm={4}
                    xs={4}
                    className="phone-input"
                  >
                    <Controller
                      control={control}
                      name={`tasks[${index}].customer.phone_country_code`}
                      defaultValue={
                        tasks ? tasks.customer.phone_country_code : '57'
                      }
                      as={
                        <PhoneInput
                          country="co"
                          defaultCountry="co"
                          onlyCountries={COUNTRIES}
                          placeholder={translate('code')}
                          inputProps={{ disabled: true }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={optimize ? 7 : 8} sm={8} xs={8}>
                    <TextField
                      name={`tasks[${index}].customer.phone_number`}
                      label={translate('phone')}
                      defaultValue={
                        tasks ? tasks.customer.phone_number : item.phone_number
                      }
                      variant="outlined"
                      inputProps={{ maxLength: '11' }}
                      fullWidth
                      required
                      inputRef={register({
                        required: `${translate('requiredField')}`,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: `${translate('onlyNumbers')}`,
                        },
                      })}
                      error={
                        errors.tasks &&
                        errors.tasks[index] &&
                        errors.tasks[index].customer &&
                        !!errors.tasks[index].customer.phone_number
                      }
                      helperText={
                        errors.tasks &&
                        errors.tasks[index] &&
                        errors.tasks[index].customer &&
                        errors.tasks[index].customer.phone_number &&
                        errors.tasks[index].customer.phone_number.message
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name={`tasks[${index}].customer.email`}
                  defaultValue={tasks ? tasks.customer.email : item.email}
                  label={translate('email')}
                  variant="outlined"
                  fullWidth
                  required
                  inputRef={register({
                    required: `${translate('requiredField')}`,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: `${translate('invalidEmailAddress')}`,
                    },
                  })}
                  error={
                    errors.tasks &&
                    errors.tasks[index] &&
                    errors.tasks[index].customer &&
                    !!errors.tasks[index].customer.email
                  }
                  helperText={
                    errors.tasks &&
                    errors.tasks[index] &&
                    errors.tasks[index].customer &&
                    errors.tasks[index].customer.email &&
                    errors.tasks[index].customer.email.message
                  }
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12} className="text-right">
                {index > 0 && (
                  <Button
                    color="primary"
                    onClick={() => removeTasks(index, item.id)}
                  >
                    {translate('remove')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      {tasks && tasks.status_cd === 1 && (
        <Paper square>
          <Box p={2} mb={2}>
            <Grid
              container
              item
              spacing={1}
              md={12}
              sm={12}
              xs={12}
              className="text-left"
            >
              <Grid item md={12} sm={12} xs={12} className="text-left">
                <Box mt={1} mb={1}>
                  <Typography variant="h6">{translate('Conductor')}</Typography>
                </Box>
              </Grid>
              <Grid item md={9} sm={8} xs={8} className="text-left">
                <TextField
                  name="driver_id"
                  defaultValue={tasks ? tasks.driver._id : ''}
                  inputRef={register}
                  style={{ width: 0 }}
                  className="opacity-0"
                />
                <TextField
                  label={translate('name')}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                  value={driver ? driver : tasks ? tasks.driver.name : ''}
                />
              </Grid>
              <Grid
                container
                item
                md={3}
                sm={4}
                xs={4}
                justify="center"
                alignContent="center"
                className="text-center"
              >
                <Button
                  type="button"
                  color="primary"
                  size="large"
                  startIcon={<Edit />}
                  onClick={handleDriver}
                >
                  {translate('Editar conductor')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      {!tasks && (
        <Button
          type="button"
          color="secondary"
          size="large"
          onClick={addTasks}
          disabled={!isValidAddress}
        >
          {translate('task.addTask')}
        </Button>
      )}
      <div className="text-center">
        <Box mt={1}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={!isValidAddress || !isValidOriginAddress}
          >
            {translate('task.confirmTask')}
          </Button>
        </Box>
      </div>
      {openDriver && (
        <Drivers
          open={openDriver}
          handleClose={handleDriver}
          translate={translate}
          assignDriver={assignDriver}
        />
      )}
    </form>
  );
}

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  handleAddress: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  langCode: PropTypes.string.isRequired,
};

export default Form;
