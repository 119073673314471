import { LAUNCH_CHAT, CHAT_LAUNCHED } from './constants';

export const launchChat = async (task, dispatch) => {
  dispatch({
    type: LAUNCH_CHAT,
    task,
  });
};

export const chatLaunched = async (dispatch) => {
  dispatch({
    type: CHAT_LAUNCHED,
  });
};
