import React from 'react';
import { Container, Box } from '@material-ui/core';
import {
  Content,
  Header,
  Banner,
  Social,
  Contact,
  NumbersProduct,
  Multimedia,
  Footer,
} from '../Components';
import Features from './Components/Features';
const images = [{ id: 1, src: 'images/landing/client-banner.png' }];

const featuresItems = [
  {
    img: 'optimization-clock',
    labels: [
      { text: 'landing.client.features.icon.1.label.1', bold: false },
      { text: 'landing.client.features.icon.1.label.2', bold: true },
      { text: 'landing.client.features.icon.1.label.3', bold: false },
      { text: 'landing.client.features.icon.1.label.4', bold: true },
      { text: 'landing.client.features.icon.1.label.5', bold: false },
    ],
  },
  {
    img: 'optimization-truck',
    labels: [
      { text: 'landing.client.features.icon.2.label.1', bold: false },
      { text: 'landing.client.features.icon.2.label.2', bold: true },
      { text: 'landing.client.features.icon.2.label.3', bold: false },
    ],
  },
  {
    img: 'optimization-dolly',
    labels: [
      { text: 'landing.client.features.icon.3.label.1', bold: false },
      { text: 'landing.client.features.icon.3.label.2', bold: true },
      { text: 'landing.client.features.icon.3.label.3', bold: false },
    ],
  },
];

const Client = () => (
  <Content>
    <Header menuActive={1} />
    <Container maxWidth="md">
      <Box mt={6} mb={6}>
        <Banner
          title1="landing.client.title.1"
          title2="landing.client.title.2"
          images={images}
        />
        <Features title="landing.client.features.title" items={featuresItems} />
        <Multimedia
          src="client-video.mp4"
          title="landing.client.video.title"
          description={[
            { text: 'landing.client.video.description.1', bold: false },
            { text: 'Pibox Enterprise', bold: true },
            { text: 'landing.client.video.description.2', bold: false },
          ]}
        />
        <NumbersProduct />
      </Box>
    </Container>
    <Contact />
    <Social />
    <Footer />
  </Content>
);

export default Client;
