import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Container } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';

const styles = createStyles((theme) => ({
  container: {
    backgroundColor: '#333333',
    minHeight: 50,
    padding: '12px 0',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      opacity: 0.5,
    },
  },
}));

const Footer = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className={classes.container}>
      <Container maxWidth="xl">
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item md={3} xs={12} className="text-center">
            <Link to="/privacy" className={classes.link} target="_blank">
              <Typography variant="body2" className="text-white">
                {translate('landing.footer.politics')}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={3} xs={12} className="text-center">
            <Link to="/privacy" className={classes.link} target="_blank">
              <Typography variant="body2" className="text-white">
                {translate('landing.footer.privacy')}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={3} xs={12} className="text-left text-center-sm">
            <Link to="/terms" className={classes.link} target="_blank">
              <Typography variant="body2" className="text-white">
                {translate('landing.footer.terms')}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={3} xs={12} className="text-right text-center-sm">
            <Typography variant="body2" className="text-white">
              {translate('landing.footer.rights')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Footer);
