import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@material-ui/core';
import { AccountBalanceWallet } from '@material-ui/icons';

function PiboxMenu({ translate, classes }) {
  return (
    <>
      <ListItem button className={classes.activeCollapse}>
        <ListItemIcon>
          <AccountBalanceWallet color="primary" />
        </ListItemIcon>
        <ListItemText primary="Pibox" />
      </ListItem>
      <Collapse in>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            exact
            to="/app"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.tasks')} />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/bulk-load"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.bulkLoad')} />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/reports"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.reports')} />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}

export default PiboxMenu;
