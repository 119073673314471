import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import { ADDONS } from 'utils/constants';
import addon from 'utils/addon';

function AddonColumnProfileVehicles({ vehicleProfileName }) {
  return <TableCell>{vehicleProfileName}</TableCell>;
}
const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: false,
};

AddonColumnProfileVehicles.propTypes = {
  vehicleProfileName: PropTypes.string.isRequired,
};

AddonColumnProfileVehicles.defaultProps = {
  vehicleProfileName: '',
};

export default addon(AddonColumnProfileVehicles, addonOptions);
