import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { Multimedia } from '../../../Components';
import styles from './Styles';

const Banner = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item md={7} sm={12}>
        <Box textAlign="left">
          <Typography gutterBottom variant="h3">
            <b>{translate('landing.home.banner.title.1')}</b>&nbsp;
            <b style={{ color: '#8029CF' }}>
              {translate('landing.home.banner.title.2')}
            </b>
          </Typography>
          <Typography
            gutterBottom
            align="left"
            className={classes.subtitle}
            variant="h6"
          >
            {translate('landing.home.banner.subtitle.1')}
            <br />
            <b>{translate('landing.home.banner.subtitle.2')}</b>
          </Typography>
        </Box>
      </Grid>
      <Multimedia src={'home-video.mp4'} autoPlay={true} />
      <Grid item md={7} sm={12}>
        <Box textAlign="right" position="relative" mb={5}>
          <img
            src={`images/landing/free-trial.png`}
            alt=""
            width="140"
            className={classes.free}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Banner);
