import React from 'react';
import { Paper, Container, Typography, Button } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import styles from './style';

function ViewText({
  title,
  subtitle,
  text,
  rating,
  onChangeRating,
  handleRating,
  translate,
}) {
  const classes = styles();
  return (
    <>
      <Container maxWidth="md" className={classes.root}>
        <Paper
          elevation={4}
          variant="outlined"
          border={0}
          className={classes.wrapper}
        >
          <Typography variant="h5" className="text-center">
            {title} <br /> {subtitle}
          </Typography>{' '}
          <br />
          <br />
          <Typography variant="subtitle1" className="text-center">
            {text}
          </Typography>{' '}
          <br />
          {rating >= 0 && (
            <>
              <Rating
                name="simple-controlled"
                size="large"
                value={rating}
                onChange={(event, newValue) => onChangeRating(event, newValue)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleRating}
                className={classes.btn}
              >
                {translate('rate')}
              </Button>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}

export default ViewText;
