import { SEND_TICKET } from './constants';

const ticketReducer = (state, action) => {
  switch (action.type) {
    case SEND_TICKET: 
      return {
        ...state,
        tickets: action.tickets
      }
    default:
      return state;
  }
}

export default ticketReducer;
