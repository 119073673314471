import React, { useState, useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { driversBulkLoad } from 'context/drivers/actions';
import { uploadFile } from 'utils/aws';
import { modalMessage } from 'utils';
import BulkLoad from 'components/BulkLoad';

const DriversBulkLoad = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [invalidRows, setInvalidRows] = useState(null);
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();

  const columns = useMemo(() => [
    {
      name: 'name',
      value: (row) => row.name,
      error: (row) => row.errors && row.errors.name,
    },
    {
      name: 'countryCode',
      value: (row) => row.name,
      error: (row) => row.errors && row.errors.phone_country_code,
    },
    {
      name: 'customerPhone',
      value: (row) => row.phone_number,
      error: (row) => row.errors && row.errors.phone_number,
    },
    {
      name: 'email',
      value: (row) => row.email,
      error: (row) => row.errors && row.errors.email,
    },
    {
      name: 'vehicle',
      value: (row) => row.vehicle_name,
      error: (row) => row.errors && row.errors.vehicle_name,
    },
    {
      name: 'headquarters',
      value: (row) => row.headquarters_name,
      error: (row) => row.errors && row.errors.headquarters_name,
    },
    {
      name: 'days.sunday',
      value: (row) => row.sunday,
      error: (row) => row.errors && row.errors.sunday,
    },
    {
      name: 'days.monday',
      value: (row) => row.monday,
      error: (row) => row.errors && row.errors.monday,
    },
    {
      name: 'days.tuesday',
      value: (row) => row.tuesday,
      error: (row) => row.errors && row.errors.tuesday,
    },
    {
      name: 'days.wednesday',
      value: (row) => row.wednesday,
      error: (row) => row.errors && row.errors.wednesday,
    },
    {
      name: 'days.thursday',
      value: (row) => row.thursday,
      error: (row) => row.errors && row.errors.thursday,
    },
    {
      name: 'days.friday',
      value: (row) => row.friday,
      error: (row) => row.errors && row.errors.friday,
    },
    {
      name: 'days.saturday',
      value: (row) => row.saturday,
      error: (row) => row.errors && row.errors.saturday,
    },
  ], []);

  const alertMessage = (msg) => {
    setLoading(false);
    setMessage(msg);
  };

  const modalMsg = (invalidDrivers) => {
    setLoading(false);

    if (invalidDrivers.length) {
      setInvalidRows(invalidDrivers);
      return;
    }

    modalMessage(
      translate('bulkLoad.fileUploadedSuccessfully'),
      translate('driver.successfullyCreatedDrivers'),
      'success',
      translate('continue'),
    ).then(() => history.push('/app/drivers'));
  };

  const handleUpload = async (file) => {
    setLoading(true);
    const date = Date.parse(new Date());
    const response = await uploadFile(file[0], `uploads/drivers/bulk_import/${date}`);
    if (response) {
      const callback = await driversBulkLoad(dispatch, { file_url: response.Location });
      callback.status
        ? modalMsg(callback.invalidDrivers)
        : alertMessage(translate(callback.message));
    } else {
      alertMessage(translate('error.OppsAnErrorHasOccurredTryAgainLater'));
    }
  };

  return (
    <BulkLoad
      title="driver.addDrivers"
      format="/files/formatoDriversBulkLoad.xlsx"
      handleUpload={handleUpload}
      loading={loading}
      invalidRows={invalidRows}
      handleCloseInvalidRows={() => setInvalidRows(false)}
      columns={columns}
      rowId="email"
      message={message}
      handleCloseMessage={() => setMessage(null)}
    />
  );
};

export default withRouter(DriversBulkLoad);
