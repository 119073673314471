import {
  OPTIMIZE_ROUTES,
  INIT_VIEW,
  POINTS_MAP,
  ROUTE_FILE_LIST,
  ROUTE_FILE_STATUS_LIST,
  PENDING_ROUTE_FILES,
  UPDATING_FILE_STATUS,
  UNASSIGNED_ROUTES,
} from './constants';

const optimizedRoutesReducer = (state, action) => {
  switch (action.type) {
    case OPTIMIZE_ROUTES:
      return {
        ...state,
        optimizedRoutes: action.optimizedRoutes,
      };
    case UNASSIGNED_ROUTES:
      return {
        ...state,
        unassignedRoutes: action.unassignedRoutes,
      };
    case INIT_VIEW: {
      return {
        ...state,
        optimizedRoutes: action.optimizedRoutes,
      };
    }
    case POINTS_MAP: {
      return {
        ...state,
        pointsMap: action.pointsMap,
      };
    }
    case ROUTE_FILE_LIST: {
      return {
        ...state,
        routeFileList: action.routeFileList,
      };
    }
    case ROUTE_FILE_STATUS_LIST: {
      return {
        ...state,
        fileStatusList: action.fileStatusList,
      };
    }
    case PENDING_ROUTE_FILES: {
      return {
        ...state,
        pendingFiles: action.pendingFiles,
      };
    }
    case UPDATING_FILE_STATUS: {
      return {
        ...state,
        updatingFileStatus: action.updatingFileStatus,
      };
    }
    default:
      return state;
  }
};

export default optimizedRoutesReducer;
