import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-star',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    width: '100%'
  },
  wrapper: {
    padding: '50px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '40px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '50px 20px'
    }
  },
  logo: {
    width: '230px',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '200px'
    }
  },
}));

export default styles;
