import React, { useContext } from 'react';
import { I18nContext } from 'translations';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

function InputFilter({ onSearch }) {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container direction="row" justify="flex-end">
      <Grid item md={4} xs={12}>
        <TextField
          name="search"
          placeholder={translate('search')}
          fullWidth
          onKeyPress={(e) => onSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

InputFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default InputFilter;
