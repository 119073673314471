import React, { useContext } from 'react';
import {
  Zoom, Card, CardActions, Typography, Button, CardContent,
} from '@material-ui/core';
import { I18nContext } from 'translations';

function EndChat({ handleCancel, handleFinish, ending }) {
  const { translate } = useContext(I18nContext);

  return (
    <Zoom in>
      <Card raised>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate('chat.finish.title')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {translate('chat.finish.description')}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={handleCancel}>{translate('cancel')}</Button>
          <Button
            onClick={handleFinish}
            disabled={ending}
            variant="contained"
            color="primary"
          >
            {translate('chat.finish')}
          </Button>
        </CardActions>
      </Card>
    </Zoom>

  );
}

export default EndChat;
