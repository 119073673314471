const { LIST_HEADQUARTERS, SELECTED_HEADQUARTER } = require('./constants');

const headquartersReducer = (state, action) => {
  switch (action.type) {
    case LIST_HEADQUARTERS:
      return {
        ...state,
        data: action.headquarters,
        selectedHeadquarter: null,
      };
    case SELECTED_HEADQUARTER:
      return {
        ...state,
        selectedHeadquarter: action.headquarter,
      };
    default:
      return state;
  }
};

export default headquartersReducer;
