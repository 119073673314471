import React from 'react';
import { Paper, Typography, Container } from '@material-ui/core';
import styles from './style';

function Privacy() {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>
          <Typography variant="h5" className="text-center">
            <b>
              Aviso de privacidad de Pibox Enterprise
            </b>
          </Typography>
          <br />
          <Typography variant="subtitle1" className="text-center">
            Fecha de actualización: 19 de enero de 2021
          </Typography>
          <br />
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <p>
              Picap, Inc. (en lo sucesivo denominada como “Picap” o la “Compañía”), una sociedad con
              domicilio en 50 E 28TH ST, New York, NY. 100167967.
            </p>

            <p>
              Picap presta servicios en línea de transporte privado con chofer (en lo sucesivo
              denominados como los “Servicios”) en la aplicación denominada “Pibox Enterprise”
              y en los sitios web de Pibox Enterprise. Se considera como un Conductor a los
              usuarios que navegan o utilizan los servicios de Pibox Enterprise y los sitios
              web correspondientes de Pibox Enterprise (en lo sucesivo denominado como el
              “Conductor”).
            </p>

            <p>
              Este Aviso de Privacidad de Pibox Enterprise (en lo sucesivo denominado como el
              “Aviso de Privacidad”), conjuntamente con los Términos y Condiciones de Uso de
              Pibox Enterprise, establecen los tratamientos a los que se sujetará la Información
              Personal recabada.
            </p>

            <p>
              Por favor lea este Aviso de Privacidad para entender las consideraciones y prácticas
              con relación a los Información Personal (definida más adelante) del Conductor y el
              tratamiento que se dará a tales datos. Al acceder o navegar en Pibox Enterprise, el
              Conductor hará constar tener conocimiento del presente Aviso de Privacidad.
            </p>

            <p>
              Por favor lea este Aviso de Privacidad cuidadosamente, entiéndala y acéptala
              plenamente antes de utilizar Pibox Enterprise. Si el Conductor no está de acuerdo
              con cualquier punto de este Aviso de Privacidad, debe dejar de utilizar
              Pibox Enterprise inmediatamente.
            </p>

            <p>
              Cuando el Conductor comienza a utilizar la aplicación Pibox Enterprise, el Conductor
              entiende que Picap requiere recopilar, revelar, almacenar, utilizar, procesar,
              compartir, transmitir y proteger la Información Personal del Conductor en términos
              de este Aviso de Privacidad.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              1. Procesamiento de la Información Personal
            </b>
            <br />
            <p>
              Pibox Enterprise es propiedad de Picap y es operada por dicha compañía. Picap podrá
              obtener, revelar, utilizar, procesar, almacenar y transmitir la Información Personal
              del Conductor, y procesará y tratará la Información Personal del Conductor en términos
              de este Aviso de Privacidad y la Ley de datos personales que resulte aplicable.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              2. Recopilación de la Información Personal
            </b>
            <br />
            <br />
            <b className="text-left bold light-purple text-justify">
              2.1. Picap podrá recopilar del Conductor la siguiente Información Personal:
            </b>
            <br />
            <br />
            <b className="text-left bold light-purple text-justify">
              2.1.1. Información Personal
            </b>
            <br />

            <p>
              Su número de teléfono móvil, nombre, correo. Esta información es necesaria
              para cumplir con las obligaciones derivadas de los Términos y Condiciones
              de Uso de Pibox Enterprise a los que el Conductor se adhirió.
            </p>

            <p>
              Picap recopila dicha información conforme las leyes y reglamentos para darle
              de alta como Conductor nuestra plataforma, generarle un perfil en nuestros sistemas,
              así como para la seguridad personal y de bienes del Conductor. Si el Conductor
              no proporciona dicha información, podría perder el acceso completo a los servicios
              de Pibox Enterprise.
            </p>

            <br />
            <b className="text-left bold light-purple text-justify">
              2.1.2. Ubicación
            </b>
            <br />
            <p>
              Picap recabará la información de ubicación del Conductor cuando Pibox Enterprise
              se ejecute en primer plano (aplicación abierta y en pantalla) o en segundo plano
              (aplicación abierta, pero no en pantalla) en el dispositivo del Conductor. Picap
              obtendrá la ubicación del Conductor a través de la dirección GPS para proveer los
              Servicios de una mejor manera.
            </p>
            <br />
            <b className="text-left bold light-purple text-justify">
              2.1.3. Viaje
            </b>
            <br />
            <p>
              Picap recabará la información del lugar de partida y de destino, la localización,
              la duración y el kilometraje del Conductor. Picap para fines de seguridad y
              calidad podrá grabar el viaje del Conductor. Picap recopila la información antes
              mencionada de conformidad con las leyes y reglamentos aplicables, tomando en
              consideración la protección de la seguridad personal y de la propiedad del
              Conductor, así como la petición para tratar las disputas del Conductor de conformidad
              con las reglas de Pibox Enterprise.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              3. Permisos de Dispositivos
            </b>
            <br />
            <p>
              3.1. Pibox Enterprise podrá solicitar algunos permisos en el dispositivo del
              Conductor. Se considerará que los Conductores otorgan permisos de acceso en
              el dispositivo cuando accedan o utilicen Pibox Enterprise.
            </p>

            <p>
              3.2. El Conductor puede decidir cancelar todos o algunos de los permisos en
              el dispositivo, para impedir que Picap recopile los Datos Personales
              correspondientes. En los distintos dispositivos, la forma para otorgar y
              cancelar permisos puede ser distinta. Póngase en contacto con los desarrolladores
              del sistema y del dispositivo para obtener información detallada.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              4. Almacenamiento de Datos Personales
            </b>
            <br />
            <br />
            <b className="text-left bold light-purple text-justify">
              4.1 Periodo de Almacenamiento de Datos Personales
            </b>
            <br />

            <p>
              4.1.1. Picap mantendrá los Datos Personales del Conductor mientras éste utilice
              los servicios de Pibox Enterprise.
            </p>

            <p>
              4.1.2. A través de la función de configuración de Pibox Enterprise, no es
              posible que el Conductor cancele directamente su cuenta. Incluso si el Conductor
              solicita cancelar su cuenta, Picap podrá conservar los Datos Personales del Conductor
              conforme a los plazos previstos en la legislación aplicable.
            </p>

            <p>
              4.1.3. Si el Conductor desea eliminar sus Datos Personales, podrá contactar
              al área de operaciones de Picap o a la empresa asociada a quien presta servicio
              para presentar la solicitud correspondiente. Picap eliminará los Datos Personales
              del Conductor de conformidad con lo establecido por las leyes y reglamentos
              aplicables dentro del periodo de tiempo exigido.
            </p>
            <br />
            <br />
            <b className="text-left bold light-purple text-justify">
              4.2 Almacenamiento de Información
            </b>
            <br />

            <p>
              Los Datos Personales del Conductor recabados por Picap se transmitirán
              y almacenarán en centros de datos en México, Brasil, Colombia, los EUA
              y/o cualquier lugar en cualquier parte del mundo que pertenezca o que
              soliciten los servicios de Picap y sus filiales de conformidad con la
              legislación aplicable.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              5. Uso de la Información Personal
            </b>
            <br />

            <p>
              5.1. El Conductor reconoce y acepta que los Datos Personales del
              Conductor pueden ser utilizados en los escenarios de uso que están
              directa o razonablemente relacionados con los objetivos establecidos
              en el presente apartado.
            </p>

            <p>
              5.2. El Conductor reconoce y acepta que Picap podrá analizar los
              Datos Personales proporcionados para efectos de brindar condiciones
              de seguridad y proporcionar información relacionada con ésta.
            </p>

            <p>
              5.3. En el supuesto que Picap utilice los Datos Personales del Conductor
              más allá del alcance que está directa o razonablemente relacionado con
              los objetivos establecidos en el presente Aviso de Privacidad, Picap lo
              notificará al Conductor y, de ser necesario, solicitará su consentimiento.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              6. Uso de Datos Personales Sensibles
            </b>
            <br />

            <p>
              Para efectos de claridad, no obstante, cualquier cláusula o
              término en contrario de este Aviso de Privacidad, Picap no
              transferirá Datos Personales del Conductor a ningún tercero.
              Picap únicamente recopila, transfiere y almacena Personales
              Sensibles para proveer los Servicios.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              7. Derechos de los conductores
            </b>
            <br />
            <br />
            <b className="text-left bold light-purple text-justify">
              7.1. Acceso
            </b>
            <br />

            <p>
              El Conductor puede acceder a sus Datos Personales en Pibox Enterprise de
              las siguientes maneras:
            </p>
            <br />
            <b className="text-left bold light-purple text-justify">
              7.1.1. Cuenta
            </b>
            <br />

            <p>
              El Conductor puede acceder a sus Datos Personales y otra información
              que haya proporcionado a través de su cuenta en Pibox Enterprise.
            </p>
            <br />
            <b className="text-left bold light-purple text-justify">
              7.2. Rectificación
            </b>
            <br />

            <p>
              El Conductor tiene derecho a hacer correcciones y actualizaciones de sus
              Datos Personales. El Conductor podrá corregir o modificar sus Datos
              Personales en Pibox Enterprise presentando una solicitud de corrección
              al equipo de operaciones de Picap.
            </p>
            <br />
            <b className="text-left bold light-purple text-justify">
              7.3. Oposición
            </b>
            <br />
            <p>
              7.3.1. Picap protegerá el derecho del Conductor a oponerse a tratamientos específicos.
              El Conductor podrá retirar a Pibox Enterprise su autorización para acceder a sus
              Datos Personales a través de la característica de configuración del dispositivo para
              cancelar los permisos correspondientes del mismo (incluyendo ubicación, cámara, etc.).
            </p>
            <br />

            <p>
              7.3.2. Cuando el Conductor retire su consentimiento o autorización, Picap no podrá
              continuar prestando los servicios correspondientes a la autorización retirada
              del Conductor. Sin embargo, cuando el Conductor retire su consentimiento o
              autorización, esto no afectará el procesamiento de los Datos Personales con
              base en el consentimiento que haya otorgado antes de retirarlo.
            </p>

            <br />
            <b className="text-left bold light-purple text-justify">
              7.4 .Cancelación, limitación de los datos personales y revocación del consentimiento.
            </b>
            <br />
            <p>
              El Conductor para ejercer cualquiera de sus derechos de revocación del consentimiento;
              acceso; rectificación; cancelación y/o limitación del tratamiento puede hacerlo
              directamente contactando al equipo de operaciones de Picap o directamente de la
              empresa asociada el cual presta servicio.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              8. Cambios
            </b>
            <br />

            <p>
              8.1 En caso de que ocurra cualquiera de los siguientes cambios en Pibox Enterprise,
              Picap hará las modificaciones correspondientes a este Aviso de Privacidad
              oportunamente:
            </p>

            <ul>
              <li>Cambios de las funciones de negocios de Pibox Enterprise.</li>
              <li>Cambios a las reglas de uso de los Datos Personales del Conductor.</li>
              <li>
                Cambios en la información de contacto de Picap y en sus canales
                para presentar quejas.
              </li>
              <li>
                Otros cambios que puedan afectar la seguridad de los Datos Personales
                del Conductor o los derechos de privacidad del Conductor.
              </li>
            </ul>
            <br />

            <p>
              8.2. Picap no limitará los derechos del Conductor conforme a este Aviso de
              Privacidad sin el consentimiento expreso del Conductor.
            </p>

            <p>
              8.3. Salvo que se especifique lo contrario, el Aviso de Privacidad modificado
              entrará en vigor a partir de la fecha de publicación.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              9. Definición y Alcance de los Datos Personales
            </b>
            <br />

            <p>
              9.1. Datos Personales se refiere a toda aquella información que permita identificar
              la identidad de una persona física, por sí o conjuntamente (en lo sucesivo denominada
              como “Datos Personales”), en su caso, incluye, entre otra información, el nombre,
              número de teléfono móvil, correo, ubicación, información del dispositivo.
            </p>

            <p>
              9.2. Si el Conductor no proporciona alguna de Información Personal que se requiera,
              se le podrá negar el acceso a todos o parte de los Servicios, al ser el tratamiento
              de sus datos personales necesario para cumplir con las obligaciones derivadas de los
              Términos y Condiciones de Uso de Pibox Enterprise a los que el Conductor se adhirió.
            </p>
          </Typography>
          <br />
          <br />

        </Paper>
      </Container>
    </div>
  );
}

export default Privacy;
