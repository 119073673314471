import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { I18nContext } from 'translations';
import { Snackbar, Grid } from '@material-ui/core';
import { useStateValue } from 'context/store';
import { createAccount } from 'context/users/actions';
import { modalMessage } from 'utils';
import LayoutOuter from 'components/LayoutOuter';
import Loading from 'components/Loading';
import Form from './Form';

function Signup({ history }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clean, setClean] = useState(false);
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();

  const handleClose = () => setMessage(null);

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  const handleModalMessage = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), translate('linkSentEmailToContinueWithTheRegistration'), 'success', translate('accept'));
    setClean(true);
  }

  const onSubmit = async values => {
    const organization = {
      ...values.organization,
      email: values.user.email
    }
    setLoading(true);
    const callback = await createAccount(dispatch, { user: { ...values.user }, organization });
    callback.status ? handleModalMessage() : handleMessage(callback.message);
  }

  return (
    <div>
      {loading && <Loading />}
      <Grid container>
        <Grid item md={6} xs={12} className="hidden-sm">
          <LayoutOuter bg="linear-gradient(125deg, #6F2F82 35%, #ff0068 90%)">
            <img src="/images/pibox-enterprise.png" style={{ maxWidth: '400px' }} alt="logo" />
          </LayoutOuter>
        </Grid>
        <Grid item md={6} xs={12}>
          <LayoutOuter bg="white">
            <Form
              submit={onSubmit}
              clean={clean}
            />
          </LayoutOuter>
        </Grid>
      </Grid>
      <Snackbar
        open={message && true}
        autoHideDuration={15000}
        onClose={handleClose}
        message={message}
      />
    </div>
  );
}

Signup.propTypes = {
  history: PropTypes.object.isRequired
};

export default Signup;
