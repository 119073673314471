import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import schema from './schema';

const styles = makeStyles(() => ({
  marginBottomField: {
    marginBottom: '40px'
  }
}));

function Form({ onSubmit, translate }) {
  const classes = styles();
  const { handleSubmit, register, errors } = useForm({
    validationSchema: schema
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>

      <TextField
        name="email"
        error={!!errors.email}
        label={translate('email')}
        helperText={errors.email && translate(errors.email.message)}
        type="email"
        variant="outlined"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
      />

      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.marginBottomField}
      > 
        {translate('recovery.rememberPassword')}
      </Button>

      <div className="text-right">
        <Button
          color="primary"
          component={Link}
          to="/login"
          size="large"
        >
          {translate('logIn')}
        </Button>
      </div>

    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default Form;
