import { urlHeadquarters } from 'api';
import { get, post, put, remove } from 'api/requests';
import handleErrorMessage from 'context/common/actions';
import { LIST_HEADQUARTERS, SELECTED_HEADQUARTER } from './constants';

export const listHeadquarters = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await get(urlHeadquarters, params, lang)
    .then((json) => json.data)
    .then((data) => {
      dispatch({ type: LIST_HEADQUARTERS, headquarters: data });
      status = true;
    })
    .catch((error) => {
      const msg = handleErrorMessage(error, dispatch);
      status = false;
      message = msg;
    });
  return { status, message };
};

export const getHeadquarterById = async (dispatch, id, lang) => {
  let status = false;
  let message = null;
  await get(`${urlHeadquarters}/${id}`, {}, lang)
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: SELECTED_HEADQUARTER,
        headquarter: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const createHeadquarter = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await post(urlHeadquarters, { ...params }, lang)
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const updateHeadquarter = async (dispatch, params, id, lang) => {
  let status = false;
  let message = null;
  await put(`${urlHeadquarters}/${id}`, { ...params }, lang)
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const deleteHeadquarter = async (dispatch, params, id, lang) => {
  let status = false;
  let message = null;
  await remove(`${urlHeadquarters}/${id}`, { ...params }, lang)
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};
