import { SEND_TICKET } from './constants';
import { urlTickets } from 'api';
import { post } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';

export const createTicket = async (dispatch, values, langCode) => {
  let status = false;
  let message = null;
  await post(urlTickets, { ...values }, langCode )
    .then((json) => {
      return json.data;
    })
    .then((response) => {
      dispatch({
        type: SEND_TICKET,
        session: response
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message }
}
