import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Box } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { listDriver, updateDriver } from 'context/drivers/actions';
// import { listHeadquarters } from 'context/headquarters/actions';
import { PaperBlock, Loading, BackButtonTitle } from 'components';
import { modalMessage } from 'utils';
import FormDrivers from './form/FormDrivers';

function EditDriver({ history, match }) {
  const [{
    drivers,
    headquarters,
    // checkin
  }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const { langCode, translate } = useContext(I18nContext);
  // const per_page = 10;
  const {
    params: { id },
  } = match;
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('congratulations'),
      translate('driver.successfullyUpdateDriver'),
      'success',
      translate('continue'),
    ).then((result) => {
      result.value && history.push('/app/drivers');
    });
  };

  const editedSchedule = useMemo(() => {
    if (drivers.driver && drivers.driver.active_weekdays) {
      const previousSlots = drivers.driver.active_weekdays.map((slot) => ({
        ...slot,
        _destroy: '1',
        id: slot._id,
      }));
      return previousSlots.concat(schedule);
    }
    return schedule;
  }, [schedule, drivers.driver]);

  const onSubmit = async (values) => {
    setLoading(true);
    const callback = await updateDriver(
      dispatch,
      {
        user: { ...values, active_weekdays_attributes: editedSchedule },
        user_type: 'driver',
      },
      id,
      langCode,
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  const getDriver = async (driverId) => {
    setLoading(true);
    const callback = await listDriver(dispatch, driverId, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  // const getHeadquarters = async (page) => {
  //   setLoading(true);
  //   const callback = await listHeadquarters(
  //     dispatch,
  //     { page, per_page },
  //     langCode
  //   );
  //   callback.status ? setLoading(false) : handleMessage(callback.message);
  // };

  // useEffect(() => {
  //   const { addons } = checkin;
  //   if (addons) {
  //     addons.route_optimization && getHeadquarters(1);
  //   }
  // }, [checkin]); // eslint-disable-line

  useEffect(() => {
    if (id) {
      getDriver(id);
    }
  }, [id]); // eslint-disable-line

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('driver.editDriver')}
          link="/app/drivers"
          buttonName={translate('back')}
        />
        <Grid container spacing={5} justify="center">
          <Grid item md={8}>
            <Box mt={3}>
              {drivers.driver && (
                <FormDrivers
                  submit={onSubmit}
                  translate={translate}
                  driver={drivers.driver}
                  headquarters={headquarters}
                  handleChangeSchedule={setSchedule}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

EditDriver.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditDriver;
