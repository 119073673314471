import React, { useState, useContext } from 'react';
import {
  Grid, Typography, Button, Snackbar,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { I18nContext } from 'translations';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';

import InvalidRows from './InvalidRows';
import FormatUpdate from './FormatUpdate';

const BulkLoad = ({
  title, format, handleUpload, loading,
  invalidRows, columns, handleCloseInvalidRows, rowId,
  message, handleCloseMessage,
  lastFormatUpdate, formatKey,
}) => {
  const [file, setFile] = useState([]);
  const { translate } = useContext(I18nContext);

  return (
    <PaperBlock border={0}>
      <div className="white-page">
        {loading && <Loading />}
        <Grid container spacing={4} justify="space-around">
          <Grid item xs={12}>
            <FormatUpdate
              date={lastFormatUpdate}
              element={formatKey}
            />
            <Typography variant="h4" className="title text-center">
              {translate(title)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className="title text-center">
              {translate('bulkLoad.loadExcelFile')}
              <br />
              <Button color="secondary" href={format} size="large" download>
                {translate('bulkLoad.downloadFormatHere')}
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sm={12}>
            <Grid container spacing={5} style={{ marginBottom: 25 }}>
              <Grid item xs={12} sm={12}>
                <DropzoneArea
                  onChange={setFile}
                  acceptedFiles={['.xlsx', '.xls', '.csv']}
                  maxFileSize={5000000}
                  filesLimit={1}
                  dropzoneText={translate('bulkLoad.dragFileHereOrClick')}
                  getFileLimitExceedMessage={() => translate('bulkLoad.pleaseUploadOnlyOneFile')}
                  getFileAddedMessage={(fileName) => `${fileName} ${translate('bulkLoad.successfullyAdded')}`}
                  getFileRemovedMessage={(fileName) => `${fileName} ${'bulkLoad.removed'}`}
                  getDropRejectMessage={() => translate('bulkLoad.unsupportedFileType')}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  color="primary"
                  onClick={() => handleUpload(file)}
                  disabled={!file.length}
                >
                  {translate('bulkLoad.uploadExcel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        message={message}
      />
      {invalidRows && (
        <InvalidRows
          handleClose={handleCloseInvalidRows}
          rows={invalidRows}
          columns={columns}
          rowId={rowId}
        />
      )}
    </PaperBlock>
  );
};

export default BulkLoad;
