import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import { I18nContext } from 'translations';
import styles from './Styles';

const cards = [
  {
    image: 'number-products-flota',
    title: '10.000',
    label: 'landing.home.numbers.product.card.1.label',
  },
  {
    image: 'number-products-envios',
    title: 'landing.home.numbers.product.card.2.title',
    label: 'landing.home.numbers.product.card.2.label',
  },
  {
    image: 'number-products-gestion',
    title: 'landing.home.numbers.product.card.3.title',
    label: 'landing.home.numbers.product.card.3.label',
  },
];

const NumbersProduct = ({ classes }) => {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container item md={12} sm={12}>
      <Grid item md={12} sm={12}>
        <Box>
          <Typography
            gutterBottom
            variant="h5"
            align="left"
            className={classes.subtitle}
          >
            <b>{translate('landing.home.numbers.product.title')}</b>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <div className={classes.cardsBanner}>
          {cards.map((item, index) => (
            <div key={`card-${index}`} className={classes.card}>
              <img
                src={`images/landing/${item.image}.png`}
                height="70"
                alt="Empresas aliadas"
              />
              <div style={{ width: '65%' }}>
                <Typography
                  variant="h6"
                  align="left"
                  className={classes.subtitle}
                >
                  <b>{translate(item.title)}</b>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  align="left"
                  className={classes.subtitle}
                >
                  {translate(item.label)}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(NumbersProduct);
