import { CHECKIN } from './constants';
import { urlCheckin } from 'api';
import { post } from 'api/requests';
import device_metadata from 'utils/device';
import handleErrorMessage from 'utils/handleErrorMessage';
import { ADDONS } from 'utils/constants';

const validateAccessProfile = (checkin, addonName) => {
  return checkin.configuration &&
    checkin.configuration.addons &&
    checkin.configuration.addons.includes(addonName)
    ? true
    : false;
};

export const listCheckin = async (dispatch) => {
  let status = false;
  let message = null;
  await post(urlCheckin, { device_metadata })
    .then(({ data }) => {
      var addons = [];
      Object.keys(ADDONS).forEach(
        (item) =>
          (addons[ADDONS[item]] = validateAccessProfile(data, ADDONS[item]))
      );
      dispatch({
        type: CHECKIN,
        checkin: data,
        addons,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};
