import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Button,
  Tooltip,
} from '@material-ui/core';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import LocalSeeIcon from '@material-ui/icons/LocalSee';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import { TASK_STATUSES, TYPE_CD } from 'utils/constants';
import { I18nContext } from 'translations';
import { statusClass, textOverflow } from 'utils';
import moment from 'moment/moment';
import ChatAction from 'pages/Orders/components/PrivateActions.js/ChatAction';
import styles from './style';

function ListTask({
  data,
  shareLink,
  handleEvidence,
  handleCancel,
  selectedTask,
  handleChangeSelectedTask,
}) {
  const { translate } = useContext(I18nContext);
  const classes = styles();

  const handleClickRow = (e, task) => {
    handleChangeSelectedTask(e, task);
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('task')}</TableCell>
            <TableCell>{translate('status')}</TableCell>
            <TableCell>{translate('type')}</TableCell>
            <TableCell>{translate('destiny')}</TableCell>
            <TableCell>{translate('customer')}</TableCell>
            <TableCell>{translate('phone')}</TableCell>
            <TableCell>{translate('driver')}</TableCell>
            <TableCell>{translate('task.initDate')}</TableCell>
            <TableCell>{translate('task.dueDate')}</TableCell>
            <TableCell>{translate('actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry, index) => (
            <TableRow
              id={entry._id}
              key={index.toString()}
              selected={selectedTask && entry._id === selectedTask.id}
              onClick={(e) => handleClickRow(e, entry)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell className="bold">
                <p className={classes.textOverflow}>
                  {entry.external_id ? entry.external_id : '-'}
                </p>
              </TableCell>
              <TableCell style={{ width: 70 }}>
                <b className={statusClass(entry.status_cd)}>
                  {translate(TASK_STATUSES[entry.status_cd])}
                </b>
              </TableCell>
              <TableCell>{translate(TYPE_CD[entry.type_cd].name)}</TableCell>
              <TableCell>
                <p className={classes.address}>
                  {entry.address}
                  {' '}
                  <br />
                  {entry.secondary_address && (
                    <span>
                      {textOverflow(entry.secondary_address, 105)}
                      {' '}
                    </span>
                  )}
                </p>
              </TableCell>
              <TableCell>
                <p className={classes.name}>
                  {entry.customer ? entry.customer.name : '-'}
                </p>
              </TableCell>
              <TableCell className={classes.name}>
                {`${
                  entry.customer && entry.customer.phone_country_code
                    ? entry.customer.phone_country_code
                    : ''
                } - ${
                  entry.customer && entry.customer.phone_number
                    ? entry.customer.phone_number
                    : ''
                }`}
              </TableCell>
              <TableCell>
                <p className={classes.name}>
                  {entry.driver ? entry.driver.name : '-'}
                </p>
              </TableCell>
              <TableCell className={classes.name}>
                <p>
                  {entry.init_date
                    ? moment(entry.init_date).format('DD/MM/YYYY hh:mm a')
                    : '-'}
                </p>
              </TableCell>
              <TableCell className={classes.name}>
                <p
                  className={
                    [0, 1, 2, 3, 4, 9].includes(entry.status_cd)
                    && moment(entry.due_date).isBefore(moment())
                      ? 'status-failed'
                      : ''
                  }
                >
                  {entry.due_date
                    ? moment(entry.due_date).format('DD/MM/YYYY hh:mm a')
                    : '-'}
                </p>
              </TableCell>
              <TableCell style={{ width: 200 }}>
                <Tooltip title={translate('evidence')}>
                  <Button
                    onClick={() => handleEvidence(entry)}
                    size="small"
                    style={{ minWidth: 35 }}
                  >
                    <LocalSeeIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={translate('tracking')}>
                  <Button
                    onClick={() => shareLink(entry.tracking_link)}
                    size="small"
                    style={{ minWidth: 35 }}
                  >
                    <ShareOutlinedIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Editar">
                  <Button
                    style={{ minWidth: 35 }}
                    href={`/app/orders/${entry._id}`}
                    size="small"
                    disabled={![1, 3].includes(entry.status_cd)}
                  >
                    <CreateIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Cancelar">
                  <Button
                    component="div"
                    style={{ minWidth: 35 }}
                    onClick={() => handleCancel(entry._id)}
                    size="small"
                    disabled={![1, 3].includes(entry.status_cd)}
                  >
                    <CancelIcon />
                  </Button>
                </Tooltip>
                <ChatAction entry={entry} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

ListTask.propTypes = {
  data: PropTypes.array,
  shareLink: PropTypes.func.isRequired,
  handleEvidence: PropTypes.func.isRequired,
};

ListTask.defaultProps = {
  data: {},
};

export default ListTask;
