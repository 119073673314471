import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Tooltip,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import styles from 'components/Tables/style';
import CreateIcon from '@material-ui/icons/Create';

function VehiculesTable({ data, height }) {
  const { translate } = useContext(I18nContext);
  const classes = styles();

  if (!data || (data && data.length === 0)) {
    return <p>{translate('noData')}</p>;
  }
  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ height: height }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('vehicles.name')}</TableCell>
              {/* <TableCell>{translate('vehicles.volume')}</TableCell> */}
              <TableCell>{translate('vehicles.weight')}</TableCell>
              <TableCell align="center">{translate('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index.toString()}>
                <TableCell className="bold">
                  <p className={classes.name}>{item.name}</p>
                </TableCell>
                {/* <TableCell>
                  <p className={classes.name}>{item.volume}</p>
                </TableCell> */}
                <TableCell>
                  <p className={classes.name}>{item.weight}</p>
                </TableCell>
                <TableCell align="center">
                  <Fragment>
                    <Tooltip title={translate('edit')}>
                      <Button
                        style={{ minWidth: 35 }}
                        href={`/app/vehicles/${item._id}`}
                        size="small"
                      >
                        <CreateIcon />
                      </Button>
                    </Tooltip>
                  </Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

VehiculesTable.propTypes = {
  data: PropTypes.array,
  recordsPerPage: PropTypes.number.isRequired,
  assignDriver: PropTypes.any,
};

VehiculesTable.defaultProps = {
  data: {},
  assignDriver: null,
  height: 'auto',
};

export default VehiculesTable;
