import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Box,
  Paper,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { OPTIMIZATION_TYPES, AUTOMATIC_ASSIGN_TYPES } from 'utils/constants';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import { AddCircleOutline } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import styles from './style';

function OptimizeForm({
  register,
  translate,
  control,
  errors,
  langCode,
  handleSuggestOrigin,
  vehicles,
  watch,
}) {
  const classes = styles();

  const minValidation = useCallback(
    (value, min) => parseInt(value, 10) >= min || `${translate('minValidation')} ${min}`,
    [translate],
  );
  const maxValidation = useCallback(
    (value, max) => parseInt(value, 10) <= max || `${translate('maxValidation')} ${max}`,
    [translate],
  );

  const assignMode = watch('automatic_assign_cd', 0);

  return (
    <>
      <Paper elevation={0} square className={classes.section}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} className="text-left">
            <Typography variant="h6" className={classes.sectionTitle}>{translate('routes.params.basic')}</Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="text-left">
            <AutocompleteInput
              title={translate('routes.originAddress')}
              name="origin_address"
              address=""
              pos={{ lat: '4.653400', lon: '-74.109309' }}
              lang={langCode}
              handleChange={handleSuggestOrigin}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="text-left">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Controller
                as={(
                  <DateTimePicker
                    format="DD/MM/YYYY hh:mm:ss A"
                    inputVariant="outlined"
                    label={translate('routes.initDate')}
                    fullWidth
                    onChange={() => null}
                    minDate={new Date()}
                    value=""
                  />
                    )}
                name="init_date"
                control={control}
                defaultValue={new Date()}
                rules={register({
                  required: `${translate('requiredField')}`,
                })}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextField
              type="number"
              name="number_of_vehicles"
              variant="outlined"
              label={translate('routes.numberOfVehicles')}
              defaultValue={1}
              fullWidth
              inputProps={{ maxLength: '2' }}
              inputRef={register({
                required: `${translate('requiredField')}`,
                pattern: {
                  value: /^[0-9]+$/,
                  message: `${translate('onlyNumbers')}`,
                },
                validate: {
                  min: (value) => minValidation(value, 1),
                  max: (value) => maxValidation(value, 20),
                },
              })}
              error={!!errors.number_of_vehicles}
              helperText={
              errors.number_of_vehicles && errors.number_of_vehicles.message
            }
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="text-left">
            <TextField
              type="number"
              name="max_tasks_per_driver"
              defaultValue={1}
              variant="outlined"
              label={translate('routes.maximumNumberOfTasksPerDriver')}
              fullWidth
              inputRef={register({
                required: `${translate('requiredField')}`,
                pattern: {
                  value: /^[0-9]+$/,
                  message: `${translate('onlyNumbers')}`,
                },
                validate: {
                  min: (value) => minValidation(value, 1),
                  max: (value) => maxValidation(value, 150),
                },
              })}
              error={!!errors.max_tasks_per_driver}
              helperText={
                errors.max_tasks_per_driver && errors.max_tasks_per_driver.message
              }
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="text-left">
            <Controller
              as={<Checkbox />}
              name="minimize_number_of_vehicles_used"
              type="checkbox"
              defaultValue={false}
              control={control}
            />
            <label>{translate('routes.minimizeNumberVehicles')}</label>
          </Grid>

          <Grid item md={6} sm={12} xs={12} className="text-left">
            {/* <Controller
              as={<Checkbox />}
              name="return_to_origin"
              type="checkbox"
              defaultValue={false}
              control={control}
            />
            <label>{translate('routes.returnToOrigin')}</label> */}
          </Grid>
          <Grid
            item
            container
            md={12}
            sm={12}
            xs={12}
            alignContent="center"
            alignItems="center"
          >
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              alignContent="center"
              alignItems="center"
            >
              <Typography>{translate('routes.includeWeight')}</Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              alignContent="center"
              alignItems="center"
            >
              <Box mb={2}>
                <Controller
                  as={<Checkbox />}
                  name="weight_optimization"
                  type="checkbox"
                  defaultValue={false}
                  control={control}
                />
                {`${translate(
                  'vehicles.weight',
                )}`}
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12} className="text-left">
            <label>{translate('routes.typeOptimization')}</label>
            <Controller
              as={(
                <RadioGroup className={classes.radioGroup}>
                  {OPTIMIZATION_TYPES.map((ot) => (
                    <FormControlLabel
                      key={ot._id.toString()}
                      value={ot._id.toString()}
                      control={<Radio />}
                      label={translate(ot.name)}
                    />
                  ))}
                </RadioGroup>
            )}
              defaultValue={OPTIMIZATION_TYPES[0]._id.toString()}
              name="optimization_type_cd"
              control={control}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square className={classes.section}>
        <Grid container spacing={3} justify="flex-start">
          <Grid item xs={12} className="text-left">
            <Typography variant="h6" className={classes.sectionTitle}>{translate('routes.params.drivers')}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} className="text-left">
            <label>{translate('routes.automaticAssign')}</label>
            <Controller
              as={(
                <RadioGroup
                  className={classes.radioGroup}
                >
                  {AUTOMATIC_ASSIGN_TYPES.map((t) => (
                    <FormControlLabel
                      key={t._id.toString()}
                      value={t._id.toString()}
                      control={<Radio />}
                      label={translate(t.name)}
                    />
                  ))}
                </RadioGroup>
              )}
              defaultValue={AUTOMATIC_ASSIGN_TYPES[0]._id.toString()}
              name="automatic_assign_cd"
              control={control}
            />
          </Grid>
        </Grid>
      </Paper>
      {assignMode == 0 && ( //eslint-disable-line
      <Paper elevation={0} square className={classes.section}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} className="text-left">
            <Typography variant="h6" className={classes.sectionTitle}>{translate('routes.params.vehicles')}</Typography>
          </Grid>
          <Grid item md={10} sm={8} xs={8} className="text-left">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="vehicle_profile_id">
                {`${translate('routes.vehicleType')}`}
              </InputLabel>
              <Controller
                control={control}
                name="vehicle_profile_id"
                defaultValue=""
                label={`${translate('routes.vehicleType')}`}
                variant="outlined"
                as={(
                  <Select>
                    {Boolean(vehicles.length) && (
                    <MenuItem
                      value=""
                    >
                      <Typography color="textSecondary">{`${translate('select')}`}</Typography>
                    </MenuItem>
                    )}
                    {vehicles.length
                      ? vehicles.map((option) => (
                        <MenuItem
                          key={option._id}
                          value={option._id}
                        >
                          {translate(option.name)}
                          &nbsp;
                          <Typography component="span" color="textSecondary">
                            {`- ${option.weight} kg.`}
                          </Typography>
                        </MenuItem>

                      ))
                      : (
                        <MenuItem key="option_default" value="" disabled>
                          {translate('vehicles.empty.option')}
                        </MenuItem>
                      )}
                  </Select>
              )}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            item
            md={2}
            sm={4}
            xs={4}
            justify="center"
            alignContent="center"
          >
            <>
              <Button
                type="button"
                color="primary"
                size="large"
                startIcon={<AddCircleOutline />}
                href="vehicles"
              >
                {translate('routes.viewVehicleProfiles')}
              </Button>
            </>
          </Grid>

        </Grid>
      </Paper>
      )}
    </>
  );
}

OptimizeForm.propTypes = {
  translate: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};

export default OptimizeForm;
