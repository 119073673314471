import React, { useContext } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { TYPE_CD } from 'utils/constants';
import { textOverflow } from 'utils';
import styles from '../style';

function OptimizedRoutesTable({
  route, tasks, selectedTask, handleChangeSelectedTask,
}) {
  const { translate } = useContext(I18nContext);
  const classes = styles();

  const handleClickRow = (e, task) => {
    if (!route) return;
    handleChangeSelectedTask(e, task, route);
  };

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('task')}</TableCell>
            <TableCell>{translate('type')}</TableCell>
            <TableCell>{translate('destiny')}</TableCell>
            <TableCell>{translate('description')}</TableCell>
            <TableCell>{translate('customer')}</TableCell>
            <TableCell>{translate('phone')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.length > 0
          && tasks.map((task, index) => (
            <TableRow
              id={task._id}
              key={index.toString()}
              selected={selectedTask && task._id === selectedTask.id}
              onClick={(e) => handleClickRow(e, task)}
              style={{ cursor: route ? 'pointer' : 'inherit' }}
            >
              <TableCell className="bold">
                <p className={classes.name}>
                  {task.external_id ? task.external_id : '-'}
                </p>
              </TableCell>
              <TableCell>
                {task.type_cd >= 0
                  ? translate(TYPE_CD[task.type_cd].name)
                  : '-'}
              </TableCell>
              <TableCell>
                <p className={classes.address}>
                  {task.address}
                  {' '}
                  <br />
                  {task.secondary_address && (
                    <span>
                      {textOverflow(task.secondary_address, 105)}
                      {' '}
                    </span>
                  )}
                </p>
              </TableCell>
              <TableCell>
                <p>
                  {task.description ? task.description : '-'}
                </p>
              </TableCell>
              <TableCell>
                <p>
                  {task.customer ? task.customer.name : '-'}
                </p>
              </TableCell>
              <TableCell>
                <p>
                  {`${
                    task.customer && task.customer.phone_country_code
                      ? task.customer.phone_country_code
                      : ''
                  } - ${
                    task.customer && task.customer.phone_number
                      ? task.customer.phone_number
                      : ''
                  }`}
                </p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OptimizedRoutesTable;
