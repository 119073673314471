import {
  LIST_ORDERS,
  GET_EVIDENCE,
  UPDATE_STATUS,
  ACTIVE_DRIVERS_LOCATION,
  CANCEL_ICON_TASK,
  ACTIVE_TASKS_LOCATION,
  LIST_ORDER,
  GET_JOBS_TASK,
} from './constants';
import {
  urlJobs,
  urlTasks,
  urlDriversLocation,
  urlActiveTasks,
  urlJobsTask,
} from 'api';
import { listenerEvents } from 'firebase/utils';
import { get, post, put, patch } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';

export const listOrders = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await get(urlTasks, params, lang)
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: LIST_ORDERS,
        orders: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const createOrder = async (dispatch, values, lang) => {
  let status = false;
  let message = null;
  await post(urlJobs, { ...values }, lang)
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const getEvidence = (dispatch, info) => {
  dispatch({
    type: GET_EVIDENCE,
    orders: info,
  });
};

export const updateStatus = (dispatch, id) => {
  const events = listenerEvents(id);
  return events.onSnapshot((snapshot) => {
    if (!snapshot.empty) {
      snapshot.forEach(function (doc) {
        dispatch({
          type: UPDATE_STATUS,
          orders: { id: id, docId: doc.id, data: doc.data() },
        });
        if (doc.data().status_cd >= 5 && doc.data().status_cd <= 8) {
          dispatch({
            type: CANCEL_ICON_TASK,
            orders: { data: doc.data() },
          });
        }
      });
    }
  });
};

export const listDriversLocation = async (dispatch, lang) => {
  let status = false;
  let message = null;
  await get(urlDriversLocation, {}, lang)
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: ACTIVE_DRIVERS_LOCATION,
        orders: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const listActiveTasksLocation = async (dispatch, lang) => {
  let status = false;
  let message = null;
  await get(urlActiveTasks, {}, lang)
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: ACTIVE_TASKS_LOCATION,
        orders: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const listOrder = async (dispatch, id) => {
  let status = false;
  let message = null;
  await get(`${urlTasks}/${id}`, {})
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: LIST_ORDER,
        orders: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const updateOrder = async (dispatch, values, id, lang) => {
  let status = false;
  let message = null;
  await put(`${urlTasks}/${id}`, { ...values }, lang)
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const cancelTask = async (dispatch, id, lang) => {
  let status = false;
  let message = null;
  await patch(`${urlTasks}/${id}/cancel`, {}, lang)
    .then(() => (status = true))
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const getJobsTask = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await get(urlJobsTask, params, lang)
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: GET_JOBS_TASK,
        orders: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};
