import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  title: {
    '& h2': {
      fontWeight: 'bold',
      color: '#4a4a4a',
      fontSize: '1.6em'
    }
  },
  overflowHidden: {
    overflow: 'hidden',
    '& table tbody tr': {
      '& td:last-child': {
        borderBottom: '2px solid #F1F3F6'
      }
    }
  }
}));

export default styles;