import React from 'react';
import { useStateValue } from 'context/store';
import Unauthorized from 'components/Errors/Unauthorized';

const role = (Component, { roles, showError = false }) => ({ ...props }) => {
  const [{ checkin: { checkin } }] = useStateValue();
  if (!checkin) return false;
  if (!checkin.profile) return false;
  if (!roles.includes(checkin.profile.role_cd)) {
    return showError ? <Unauthorized /> : false;
  }

  return <Component {...props} />;
};

export default role;
