import {
  LIST_ORDERS,
  GET_EVIDENCE,
  UPDATE_STATUS,
  ACTIVE_DRIVERS_LOCATION,
  CANCEL_ICON_TASK,
  ACTIVE_TASKS_LOCATION,
  LIST_ORDER,
  GET_JOBS_TASK,
} from './constants';
import { removeEvent } from 'firebase/utils';

const orderReducer = (state, action) => {
  switch (action.type) {
    case LIST_ORDERS:
      return {
        ...state,
        list: action.orders,
      };
    case GET_JOBS_TASK:
      var listTask = [];
      if (action.orders) {
        action.orders.data.forEach((route) => {
          route.tasks.forEach((task) => (task['route_id'] = route._id));
          listTask.push(...route.tasks);
        });
      }
      return {
        ...state,
        list: { data: listTask },
        groupedList: action.orders,
      };
    case GET_EVIDENCE:
      return {
        ...state,
        evidence: action.orders,
      };
    case ACTIVE_DRIVERS_LOCATION:
      let result = [];
      if (state.drivers && state.drivers.length > action.orders.length) {
        result = state.drivers.filter(
          (item1) =>
            !action.orders.some(
              (item2) => item2.driver._id === item1.driver._id
            )
        );
      }
      return {
        ...state,
        drivers: action.orders,
        cancelDriver: result,
      };
    case ACTIVE_TASKS_LOCATION:
      return {
        ...state,
        tasks: action.orders,
      };
    case LIST_ORDER:
      return {
        ...state,
        tasks: { ...action.orders },
      };
    case CANCEL_ICON_TASK:
      return {
        ...state,
        cancelTask: action.orders.data.task_id,
      };
    case UPDATE_STATUS:
      state.list &&
        state.list.data.map((item, index) => {
          if (item._id !== action.orders.data.task_id) {
            removeEvent(action.orders.id, action.orders.docId);
            return item;
          }
          removeEvent(action.orders.id, action.orders.docId);
          return (item.status_cd = action.orders.data.status_cd);
        });
    /* falls through */
    default:
      return state;
  }
};

export default orderReducer;
