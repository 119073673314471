import React, { useContext } from 'react';

import {

  Button,
  Tooltip,
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import { I18nContext } from 'translations';
import { launchChat } from 'context/chat/actions';
import { useStateValue } from 'context/store';

function ChatAction({
  entry,
}) {
  const { translate } = useContext(I18nContext);
  const [{ checkin: { checkin } }, dispatch] = useStateValue();

  const handleCreateChat = () => {
    launchChat(entry, dispatch);
  };

  if (!checkin) return false;
  if (!entry.owner) return false;
  if (checkin.profile._id !== entry.owner._id) return false;
  if ([0, 5, 6, 7, 8].includes(entry.status_cd)) return false;

  return (

    <Tooltip title={translate('chat.icon')}>
      <Button
        component="div"
        style={{ minWidth: 35 }}
        onClick={handleCreateChat}
        size="small"
        disabled={[0].includes(entry.status_cd)}
      >
        <ChatIcon />
      </Button>
    </Tooltip>

  );
}

export default ChatAction;
