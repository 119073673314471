import { string, object, array } from 'yup';

const schema = object().shape({
  name: string().required('requiredField'),
  email: string().required('requiredField').email('invalidEmailAddress'),
  phone_number: string()
    .required('requiredField')
    .matches(/^[0-9]+$/, 'onlyNumbers'),
  headquarter_ids: array(),
});

export default schema;
