import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  },
  back: {
    position: 'absolute',
    left: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    }
  }
}));

function BackButtonTitle({ title, link, buttonName }) {
  const classes = styles();
  
  return (
    <Grid container spacing={5}>
      <Grid item md={12} xs={12} sm={12} className="relative">
        <Button
          color="primary"
          component={Link}
          to={link}
          size="large"
          startIcon={<ArrowBackIosIcon />}
          className={classes.back}>
          {buttonName}
        </Button>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

BackButtonTitle.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default BackButtonTitle;