import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { createSession } from 'context/users/actions';
import LayoutOuter from 'components/LayoutOuter';
import Loading from 'components/Loading';
import Form from './Form';
import styles from './style';

function Login({ history }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();
  const classes = styles();
  const handleClose = () => setMessage(null);

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  const onSubmit = async values => {
    setLoading(true);
    const callback = await createSession(dispatch, values);
    callback.status ? history.push('/app') : handleMessage(callback.message);
  }

  return (
    <LayoutOuter bg="linear-gradient(125deg, #6F2F82 5%, #ff0068 97%)">
      <Paper elevation={4} variant="outlined" className={classes.wrapper}>
        {loading && <Loading />}
        <img src="/logo.png" className={classes.logo} alt="logo" />
        <br />
        <Form submit={onSubmit} translate={translate} />
        <Snackbar
          open={message && true}
          autoHideDuration={6000}
          onClose={handleClose}
          message={translate(message)}
        />
      </Paper>
    </LayoutOuter>
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired
};

export default Login;
