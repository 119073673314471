import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { recoverPassword } from 'context/users/actions';
import LayoutOuter from 'components/LayoutOuter';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';
import styles from './style';
import Form from './Form';

function RecoverPassword({ history }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();
  const classes = styles();
  const handleClose = () => setMessage(null);

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage('', translate('recovery.linkHasBeenSentToTheEmail'), 'success', translate('accept'));
  }

  const onSubmit = async values => {
    setLoading(true);
    const callback = await recoverPassword(dispatch, { ...values });
    callback.status ?  handleMessageOk() : handleMessage(callback.message);
  }

  return (
    <LayoutOuter bg="linear-gradient(125deg, #6F2F82 5%, #ff0068 97%)">
      <Paper elevation={4} variant="outlined" className={classes.wrapper}>
        {loading && <Loading />}
        <img src="/logo.png" className={classes.logo} alt="logo" />
        <br />
        <Form
          onSubmit={onSubmit}
          translate={translate}
        />
        <Snackbar
          open={message && true}
          autoHideDuration={6000}
          onClose={handleClose}
          message={translate(message)}
        />
      </Paper>
    </LayoutOuter>
  );
}

RecoverPassword.propTypes = {
  history: PropTypes.object.isRequired
};

export default RecoverPassword;
