import React, { useContext } from 'react';
import { Grid, Typography, Container, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import styles from './Styles';

const icons = [
  {
    label: 'landing.description.icon.1',
    img: 'description-geo',
  },
  {
    label: 'landing.description.icon.2',
    img: 'description-ruteo',
  },
  {
    label: 'landing.description.icon.4',
    img: 'description-tracking',
  },
  {
    label: 'landing.description.icon.5',
    img: 'description-app',
  },
  {
    label: 'landing.description.icon.6',
    img: 'description-integracion',
  },
  {
    label: 'landing.description.icon.7',
    img: 'description-chat',
  },
];

const Description = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        <Grid container justify="flex-start" alignItems="center">
          <Grid item sm={12} md={7}>
            <Box color="white" mb={4}>
              <Typography
                gutterBottom
                align="left"
                variant="h3"
                className="text-center"
              >
                <b>{translate('landing.description.title.1')}</b>
                <br />
                <b>{translate('landing.description.title.2')}</b>
              </Typography>
              <Typography gutterBottom align="left" variant="h4">
                {translate('landing.description.subtitle')}
              </Typography>
              <Typography gutterBottom align="left" variant="h5">
                {translate('landing.description.information')}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={8}>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              className={classes.card}
            >
              {icons.map((item, index) => (
                <Box
                  key={`box-${index}`}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  css={{ width: 155, padding: '0px 5px' }}
                  color="white"
                >
                  <img
                    src={`images/landing/${item.img}.png`}
                    alt=""
                    width="80"
                  />
                  <Typography gutterBottom className="text-center">
                    {translate(item.label)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Description);
