import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, TextField, Grid, FormHelperText,
} from '@material-ui/core';
import {
  useForm,
} from 'react-hook-form';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import schema from './schema';
import styles from './style';

function HeadquarterForm({
  submit, translate, headquarter, langCode, handleChangeAddress, address,
}) {
  const classes = styles();
  const [isValidAddress, setIsValidAddress] = useState(Boolean(address));
  const {
    handleSubmit, errors, register,
  } = useForm({
    validationSchema: schema,
  });

  const handleSuggest = (info) => {
    if (info.lat) {
      setIsValidAddress(true);
      handleChangeAddress(info);
    } else {
      setIsValidAddress(false);
      handleChangeAddress({});
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            name="name"
            error={!!errors.name}
            label={translate('name')}
            className={classes.field}
            defaultValue={headquarter && headquarter.name}
            helperText={errors.name && translate(errors.name.message)}
            variant="outlined"
            inputRef={register}
            required
            inputProps={{ maxLength: '70' }}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <AutocompleteInput
            title={translate('address')}
            name="address"
            address={address || ''}
            pos={{ lat: '4.653400', lon: '-74.109309' }}
            lang={langCode}
            handleChange={handleSuggest}
          />
          <FormHelperText className={classes.helper}>
            {translate('selectAddressFromList')}
          </FormHelperText>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            name="secondary_address"
            error={!!errors.secondary_address}
            label={translate('headquarters.address.secondary')}
            className={classes.field}
            defaultValue={headquarter && headquarter.secondary_address}
            variant="outlined"
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} className="text-center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            className={classes.btn}
            disabled={!isValidAddress}
          >
            {headquarter
              ? translate('headquarters.edit')
              : translate('headquarters.add')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

HeadquarterForm.propTypes = {
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default HeadquarterForm;
