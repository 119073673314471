import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#F1F3F6',
  },
}));

function PaperBlock({ children, bg, border, padding }) {
	const classes = styles();
  return (
    <Paper className={classes.paper} style={{ backgroundColor: bg, borderRadius: border, padding: padding }}>
    	{children}
    </Paper>
  );
}

PaperBlock.propTypes = {
  children: PropTypes.node.isRequired
};

export default PaperBlock;
