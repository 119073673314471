import { LOADING, ERROR } from './constants';
import handleErrorMessage from 'utils/handleErrorMessage';

export const loading = async (dispatch, loading) => {
  dispatch({
    type: LOADING,
    loading,
  });
};

export const error = async (dispatch, error) => {
  dispatch({
    type: ERROR,
    error: handleErrorMessage(error, dispatch),
  });
};

export const dismissError = async (dispatch) => {
  dispatch({
    type: ERROR,
    error: null,
  });
};

export default handleErrorMessage;
