import { LAUNCH_CHAT, CHAT_LAUNCHED } from './constants';

const chatReducer = (state, action) => {
  switch (action.type) {
    case LAUNCH_CHAT:
      return {
        ...state,
        task: action.task,
      };
    case CHAT_LAUNCHED:
      return {
        ...state,
        task: null,
      };
    default:
      return state;
  }
};

export default chatReducer;
