import { LIST_OPERATORS, SELECTED_OPERATOR } from './constants';

const operatorReducer = (state, action) => {
  switch (action.type) {
    case LIST_OPERATORS:
      return {
        ...state,
        list: action.operators,
        selectedOperator: null,
      };
    case SELECTED_OPERATOR:
      return {
        ...state,
        selectedOperator: action.operators,
      };
    default:
      return state;
  }
};

export default operatorReducer;
