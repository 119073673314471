import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  containerMap: {
    height: '60vh',
    width: '100%',
    position: 'relative',
  },
  containerDriver: {
    margin: '-30px 30px 30px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  wrapper: {
    padding: '40px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '20px 10px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  root: {
    display: 'flex',
    alignItems: 'flex-star',
    justifyContent: 'center',
    height: '100%',
    minHeight: 'calc(100vh - 75px)',
    width: '100%',
  },
  btn: {
    marginTop: 30,
    minWidth: '170px',
    padding: '8px',
  },
  logo: {
    width: '155px',
    marginRight: theme.spacing(6),
  },
  bar: {
    justifyContent: 'space-between',
  },
}));

export default styles;
