import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { ROLES } from 'utils/constants';
import role from 'utils/role';

function OperatorsItem({
  translate, classes,
}) {
  return (
    <ListItem
      button
      className={classes.nested}
      component={NavLink}
      to="/app/operators"
      activeClassName={classes.active}
    >
      <ListItemText primary={translate('menu.operators')} />
    </ListItem>
  );
}

const roleOptions = {
  roles: [ROLES.admin],
  showError: false,
};

export default role(OperatorsItem, roleOptions);
