import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  card: {
    '& img': {
      margin: '0px 10px',
    },
  },
}));

const Social = ({ classes }) => (
  <Grid item xs={12} sm={12} md={12}>
    <Box
      color="white"
      mb={4}
      display="flex"
      justifyContent="center"
      className={classes.card}
    >
      <img
        src={`images/landing/facebook.png`}
        alt="facebook"
        width="30"
        height="30"
      />
      <img
        src={`images/landing/instagram.png`}
        alt="instagram"
        width="30"
        height="30"
      />
      <img
        src={`images/landing/twitter.png`}
        alt="twitter"
        width="30"
        height="30"
      />
    </Box>
  </Grid>
);

export default withStyles(styles)(Social);
