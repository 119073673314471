import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  logo: {
    width: '140px',
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      marginRight: theme.spacing(0),
    },
  },
  containerLogo: {
    flexGrow: 1,
  },
  containerOptions: {
    '& button, a': {
      padding: '25px 20px',
      borderRadius: 0,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  optionActive: {
    borderBottom: '2px solid #8029CF',
  },
  buttonSession: {
    padding: '5px 20px',
    borderRadius: '20px',
    margin: '0px 15px',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
}));

export default styles;
