import React, { useContext, useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import { I18nContext } from 'translations';
import { readElement } from 'context/users/actions';
import { useStateValue } from 'context/store';

const styles = makeStyles(() => ({
  alert: {
    textAlign: 'left',
    marginBottom: 16,
  },
}));

const FormatUpdate = ({
  date,
  element,
}) => {
  const { translate } = useContext(I18nContext);
  const classes = styles();
  const [, dispatch] = useStateValue();
  const [read, setRead] = useState(true);

  useEffect(() => {
    if (date && moment(date).diff(moment(), 'days') <= 7) {
      setRead(false);
    }
  }, [date]);

  const onClose = async () => {
    await readElement(dispatch, { [element]: true });
    setRead(true);
  };

  if (read) return false;

  return (
    <Alert color="warning" severity="info" className={classes.alert} onClose={onClose}>
      <AlertTitle>{translate('bulkLoad.fileWasUpdated')}</AlertTitle>
      {`${translate('bulkLoad.lastUpdateDate')}: ${moment(date).format('DD/MM/YYYY, h:mm A') || '-'}`}
    </Alert>
  );
};
export default FormatUpdate;
