import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { I18nContext } from 'translations';
import styles from './style';

function NotFound({ classes }) {
  const { translate } = useContext(I18nContext);
  return (
    <div className={classes.errorWrap}>
      <Typography className={classes.title} variant="h1">
        404
      </Typography>
      <Typography variant="h5">
        {translate('notFound.pageNotFound')} :(
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        component={Link}
        to="/app"
      >
        {translate('goToStart')}
      </Button>
    </div>
  );
}

export default withStyles(styles)(NotFound);
