import React from 'react';
import { Container, Box } from '@material-ui/core';
import {
  Content,
  Header,
  Banner,
  Social,
  Contact,
  NumbersProduct,
  Multimedia,
  Features,
  Footer,
} from '../Components';

const featuresItems = [
  {
    img: 'routing-box',
    title: 'landing.routing.features.icon.1.title',
    label: 'landing.routing.features.icon.1.subtitle',
  },
  {
    img: 'routing-map',
    title: 'landing.routing.features.icon.2.title',
    label: 'landing.routing.features.icon.2.subtitle',
  },
  {
    img: 'routing-chat',
    title: 'landing.routing.features.icon.3.title',
    label: 'landing.routing.features.icon.3.subtitle',
  },
  {
    img: 'routing-folder',
    title: 'landing.routing.features.icon.4.title',
    label: 'landing.routing.features.icon.4.subtitle',
  },
  {
    img: 'routing-file',
    title: 'landing.routing.features.icon.5.title',
    label: 'landing.routing.features.icon.5.subtitle',
  },
  {
    img: 'routing-route',
    title: 'landing.routing.features.icon.6.title',
    label: 'landing.routing.features.icon.6.subtitle',
  },
];

const images = [
  { id: 1, src: 'images/landing/enrutamiento-1.png' },
  { id: 2, src: 'images/landing/enrutamiento-2.png' },
  { id: 3, src: 'images/landing/enrutamiento-3.png' },
  { id: 4, src: 'images/landing/enrutamiento-4.png' },
  { id: 5, src: 'images/landing/enrutamiento-5.png' },
];

const Routing = () => (
  <Content>
    <Header menuActive={1} />
    <Container maxWidth="md">
      <Box mt={6} mb={6}>
        <Banner
          title1="landing.routing.banner.title.1"
          title2="landing.routing.banner.title.2"
          images={images}
        />
        <Features
          title="landing.routing.features.title"
          items={featuresItems}
        />
        <Multimedia
          src="routing-video.mp4"
          title="landing.features.video.title"
          description={[
            { text: 'Pibox Enterprise', bold: true },
            { text: 'landing.features.video.description', bold: false },
          ]}
        />
        <NumbersProduct />
      </Box>
    </Container>
    <Contact />
    <Social />
    <Footer />
  </Content>
);

export default Routing;
