const initialState = {
  session: {},
  orders: {},
  tracking: {},
  drivers: {},
  operators: {},
  checkin: {},
  optimizedRoutes: null,
  common: {},
  vehicles: {},
  headquarters: {},
  chat: {},
};

export default initialState;
