import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  section: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: '#6F6F71',
  },
}));

export default styles;
