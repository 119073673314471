import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Box } from '@material-ui/core';
import { Loading, PaperBlock, BackButtonTitle } from 'components';
import { modalMessage } from 'utils';
import { useStateValue } from 'context/store';
import { getByIdVehicle, updateVehicle } from 'context/vehicles/actions';
import { I18nContext } from 'translations';
import Form from './form/FormVehicle';

EditVehicle.propTypes = {
  history: PropTypes.object.isRequired,
};

function EditVehicle({ history, match }) {
  const {
    params: { id },
  } = match;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [
    {
      vehicles: { selectedVehicle },
    },
    dispatch,
  ] = useStateValue();
  const { langCode, translate } = useContext(I18nContext);

  const getElementByIdVehicle = async (id) => {
    setLoading(true);
    const callback = await getByIdVehicle(dispatch, id, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    id && getElementByIdVehicle(id);
  }, [id]); // eslint-disable-line

  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('vehicles.successfull.edited.title'),
      translate('vehicles.successfull.edited.description'),
      'success',
      translate('continue'),
    ).then((result) => {
      result.value && history.push('/app/vehicles');
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const callback = await updateVehicle(
      dispatch,
      { vehicle_profile: { ...values } },
      id,
      langCode,
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('vehicles.add')}
          link="/app/vehicles"
          buttonName={translate('back')}
        />
        <Grid container spacing={5} justify="center">
          <Grid item md={8}>
            <Box mt={4}>
              {selectedVehicle && (
                <Form
                  submit={onSubmit}
                  translate={translate}
                  vehicle={selectedVehicle}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

export default EditVehicle;
