import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Grid,
  GridList,
  GridListTile,
} from '@material-ui/core';
import { TASK_STATUSES } from 'utils/constants';
import { statusClass } from 'utils';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    '& h2': {
      fontWeight: 'bold',
      color: '#4a4a4a',
      fontSize: '1.5em',
    },
  },
  overflowHidden: {
    overflow: 'hidden',
    textAlign: 'center',
    '& img': {
      maxHeight: '450px',
      maxWidth: '100%',
    },
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

function Evidences({ open, handleClose, evidence, translate }) {
  const classes = styles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title" className={classes.title}>
        <Grid container>
          <Grid item md={7} sm={12} xs={12} className="text-center-sm">
            {translate('evidence')} - {translate('task')} {evidence.external_id}
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            xs={12}
            className="text-right text-center-sm"
          >
            <b className={statusClass(evidence.status_cd)}>
              {translate(TASK_STATUSES[evidence.status_cd])}
            </b>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.overflowHidden}>
        {evidence.photo_url ? (
          <img src={evidence.photo_url} alt="imagen" />
        ) : evidence.photos.length ? (
          <div className={classes.root}>
            <GridList className={classes.gridList} cols={2.5}>
              {evidence.photos.map((tile, index) => (
                <GridListTile
                  key={`img-${index}`}
                  rows={2.5}
                  cols={1}
                  style={{ margin: '0px 10px' }}
                >
                  <img src={tile} alt={'tile.title'} />
                </GridListTile>
              ))}
            </GridList>
          </div>
        ) : (
          <Typography>{translate('evidence.noEvidence')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Evidences.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  evidence: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default Evidences;
