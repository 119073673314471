import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Grid, Typography, Snackbar,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { listDrivers } from 'context/drivers/actions';
import { Loading, PaperBlock } from 'components';
import { ROLES } from 'utils/constants';
import role from 'utils/role';

import ListDrivers from './components/list/ListDrivers';

function Drivers() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [per_page] = useState(10);
  const [onDuty, setOnDuty] = useState(false);
  const unmounted = useRef(false);
  const { langCode, translate } = useContext(I18nContext);
  const [{ drivers }, dispatch] = useStateValue();
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const loadingAction = (value, action) => {
    setLoading(value);
    setOnDuty(action);
    unmounted.current = false;
  };

  const getDrivers = async (page) => {
    loadingAction(true, false);
    const callback = await listDrivers(
      dispatch,
      { page, per_page },
      langCode,
      false,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  const getDriversOnDuty = async (page) => {
    loadingAction(true, true);
    const callback = await listDrivers(
      dispatch,
      { page, per_page },
      langCode,
      true,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  const onSearch = async (e) => {
    if (e.charCode === 13) {
      loadingAction(true, false);
      const callback = await listDrivers(
        dispatch,
        { name: e.target.value, page: 1, per_page },
        langCode,
        false,
      );
      callback.status ? setLoading(false) : handleMessage(callback.message);
    }
  };

  useEffect(() => {
    getDrivers(1);
    return () => {
      unmounted.current = true;
    };
    // eslint-disable-next-line
  }, [langCode]);

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <Grid container style={{ marginBottom: '15px' }}>
        <Grid item md={6} xs={12} sm={12}>
          <Typography variant="h4" className="title text-left text-center-sm">
            {translate('driver.driversList')}
          </Typography>
        </Grid>
        <Grid item container spacing={1} md={6} xs={12} sm={12} justify="flex-end">
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              startIcon={<AddCircleOutlineIcon />}
              to="/app/drivers/new-driver"
            >
              {translate('driver.addDriver')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              startIcon={<CloudUploadIcon />}
              to="/app/drivers/bulk-load"
            >
              {translate('uploadFile')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {drivers.list && (
            <ListDrivers
              height="auto"
              data={drivers.list}
              recordsPerPage={per_page}
              onDuty={onDuty}
              onSearch={onSearch}
              getDrivers={getDrivers}
              getDriversOnDuty={getDriversOnDuty}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

const roleOptions = {
  roles: [ROLES.admin, ROLES.operator],
  showError: true,
};

export default role(Drivers, roleOptions);
