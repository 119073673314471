import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
// import { AddonFormControlHeadquarters } from 'components';
import { COUNTRIES } from 'utils/constants';
import PhoneInput from 'react-phone-input-2';
import schema from './schema';

function FormOperators({
  submit, translate, operator,
  // headquarters,
}) {
  function listOfDefaultHeadquarters() {
    const headquarter_ids = [];
    operator.headquarters.forEach(({ _id }, index) => {
      headquarter_ids[index] = _id;
    });
    headquarter_ids[headquarter_ids.length] = '';
    return headquarter_ids;
  }

  const {
    handleSubmit, errors, register, control,
  } = useForm({
    validationSchema: schema,
    mode: 'onChange',
    defaultValues: {
      headquarter_ids:
        operator && operator.headquarters ? listOfDefaultHeadquarters() : [''],
    },
  });

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            name="name"
            error={!!errors.name}
            label={translate('name')}
            defaultValue={operator && operator.name}
            helperText={errors.name && translate(errors.name.message)}
            variant="outlined"
            inputRef={register}
            required
            inputProps={{ maxLength: '70' }}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            name="email"
            error={!!errors.email}
            label={translate('email')}
            defaultValue={operator && operator.email}
            helperText={errors.email && translate(errors.email.message)}
            variant="outlined"
            inputRef={register}
            required
            fullWidth
          />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item md={3} sm={12} xs={12} className="phone-input text-left">
            <Controller
              control={control}
              name="phone_country_code"
              defaultValue={operator ? operator.phone_country_code : '57'}
              as={(
                <PhoneInput
                  country="co"
                  defaultCountry="co"
                  onlyCountries={COUNTRIES}
                  placeholder={translate('code')}
                  inputProps={{ disabled: true }}
                />
              )}
            />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <TextField
              name="phone_number"
              error={!!errors.phone_number}
              label={translate('phone')}
              inputProps={{ maxLength: '11' }}
              defaultValue={operator && operator.phone_number}
              helperText={
                errors.phone_number && translate(errors.phone_number.message)
              }
              variant="outlined"
              inputRef={register}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        {/* {headquarters.data && (
          <AddonFormControlHeadquarters
            control={control}
            driver={operator}
            headquarters={headquarters.data}
          />
        )} */}
        <Grid item md={12} sm={12} xs={12} className="text-center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
          >
            {operator
              ? translate('operator.edit')
              : translate('operator.create')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

FormOperators.propTypes = {
  operator: PropTypes.object,
  translate: PropTypes.any.isRequired,
  headquarters: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default FormOperators;
