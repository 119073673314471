import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Pagination = ({ handleChangePage, page, totalPages, perPage }) => (
  <div>
    <Typography variant="body2" component="span">
      {`${page === 1 ? page : ((page - 1) * perPage) + 1} - ${page * perPage} of ${totalPages * perPage}`}
    </Typography>
    <IconButton
      component="span"
      onClick={() => handleChangePage('dec')}
      disabled={page === 1  && true}
    >
      <ChevronLeftIcon />
    </IconButton>
    <IconButton
      component="span"
      onClick={() => handleChangePage('inc')}
      disabled={totalPages === page  && true}
    >
      <ChevronRightIcon />
    </IconButton>
  </div>
);

export default Pagination;