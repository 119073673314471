import { createStyles } from '@material-ui/core/styles';

const styles = createStyles(() => ({
  filterContainer: {
    margin: '12px 0',
    padding: 12,
    paddingTop: 24,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: '#f9fafb',
  },
  filterIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export default styles;
