import { string, object, boolean } from 'yup';

const schema = object().shape({
  user: object().shape({
    name: string().required('requiredField'),
    email: string().required('requiredField').email('invalidEmailAddress'),
    phone_number: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
    password: string().required('requiredField').min(8, 'minEightCharacters')
  }),
  organization: object().shape({
    name: string().required('requiredField'),
    website: string().required('requiredField').url('invalidFormat')
  }),
  terms: boolean().oneOf([true], 'mustAcceptTerms')
});

export default schema;