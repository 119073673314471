import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Snackbar,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { listDrivers } from 'context/drivers/actions';
import DriverTable from 'components/Tables/Drivers/DriverTable';
import DriverFilterTable from 'components/Tables/Drivers/DriverFilterTable';
import Loading from 'components/Loading';
import styles from './style';

function Drivers({ open, handleClose, assignDriver }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [per_page] = useState(10);
  const [onDuty, setOnDuty] = useState(false);
  const unmounted = useRef(false);
  const { langCode, translate } = useContext(I18nContext);
  const [{ drivers }, dispatch] = useStateValue();
  const classes = styles();
  const handleCloseMessage = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const loadingAction = (loading, action) => {
    setLoading(loading);
    setOnDuty(action);
    unmounted.current = false;
  };

  const getDrivers = async (page) => {
    loadingAction(true, false);
    const callback = await listDrivers(
      dispatch,
      { page, per_page },
      langCode,
      false,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  const getDriversOnDuty = async (page) => {
    loadingAction(true, true);
    const callback = await listDrivers(
      dispatch,
      { page, per_page },
      langCode,
      true,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  const onSearch = async (e) => {
    if (e.charCode === 13) {
      loadingAction(true, false);
      const callback = await listDrivers(
        dispatch,
        { name: e.target.value, page: 1, per_page },
        langCode,
        false,
      );
      callback.status ? setLoading(false) : handleMessage(callback.message);
    }
  };

  useEffect(() => {
    getDrivers(1);
    return () => {
      unmounted.current = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title" className={classes.title}>
        {translate('driver.driversList')}
      </DialogTitle>
      <DialogContent className={classes.overflowHidden}>
        {loading && <Loading />}
        <Grid container>
          <DriverFilterTable
            getDrivers={getDrivers}
            getDriversOnDuty={getDriversOnDuty}
            onDuty={onDuty}
            onSearch={onSearch}
          />
        </Grid>
        {drivers.list && (
          <DriverTable
            height="calc(100vh - 300px)"
            data={drivers.list}
            recordsPerPage={per_page}
            getDrivers={onDuty ? getDriversOnDuty : getDrivers}
            assignDriver={assignDriver}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('cancel')}
        </Button>
      </DialogActions>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        message={translate(message)}
      />
    </Dialog>
  );
}

Drivers.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  assignDriver: PropTypes.func.isRequired,
};

export default Drivers;
