import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Typography } from '@material-ui/core';
import { useStateValue } from 'context/store';
import { listOrder, updateOrder } from 'context/orders/actions';
import { I18nContext } from 'translations';
import { modalMessage } from 'utils';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';
import BackButtonTitle from 'components/Layout/BackButtonTitle';
import Form from 'components/Forms/Orders';
import moment from 'moment/moment';

function EditOrder({ history, match }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([]);
  const { langCode, translate } = useContext(I18nContext);
  const [{ orders }, dispatch] = useStateValue();
  const {
    params: { id },
  } = match;
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('congratulations'),
      translate('task.taskSuccessfullyEdited'),
      'success',
      translate('continue')
    ).then((result) => {
      result.value && history.push('/app');
    });
  };

  const handleAddress = (suggestion, index) => {
    if (suggestion) {
      address[index] = suggestion;
      setAddress(address);
    } else {
      const updateAddress = address.filter((item, i) => i !== index);
      setAddress(updateAddress);
    }
  };

  const getOrder = async (id) => {
    const callback = await listOrder(dispatch, id, langCode);
    !callback.status && handleMessage(callback.message);
  };

  useEffect(() => {
    id && getOrder(id);
  }, [id]); // eslint-disable-line

  const onSubmit = async (values) => {
    const data = values.tasks.filter((x) => x)[0];
    const dueDateFormat = moment(data.due_date).format('DD/MM/YYYY hh:mm:ss A');
    let task = {
      ...data,
      due_date:
        dueDateFormat === 'Invalid date'
          ? moment(data.due_date, 'DD/MM/YYYY hh:mm:ss A')
          : moment(dueDateFormat, 'DD/MM/YYYY hh:mm:ss A'),
      address: address[0].name,
      lat: address[0].lat,
      lon: address[0].lon,
    };
    task = values.hasOwnProperty('driver_id')
      ? { task, driver_id: values.driver_id }
      : task;

    setLoading(true);
    const callback = await updateOrder(dispatch, { task }, id, langCode);
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="light-gray-page">
        <BackButtonTitle
          title="Editar tarea"
          link="/app"
          buttonName={translate('back')}
        />
        <Typography variant="h6">{translate('fieldsAreRequired')}</Typography>

        <Grid container spacing={5} style={{ marginTop: 10 }} justify="center">
          <Grid item md={10} className="padding-0-xs">
            {orders.tasks && (
              <Form
                handleAddress={handleAddress}
                submit={onSubmit}
                translate={translate}
                langCode={langCode}
                tasks={orders.tasks}
                isValidOriginAddress
              />
            )}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

EditOrder.propTypes = {
  history: PropTypes.object.isRequired,
};

export default EditOrder;
