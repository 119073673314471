import React from 'react';
import { Container, Box } from '@material-ui/core';
import { Content, Header, Social, Contact, Footer } from '../Components';
import { Banner, Contents } from './Components';

const Client = () => (
  <Content>
    <Header menuActive={2} />
    <Container maxWidth="md">
      <Box mt={6} mb={6}>
        <Banner
          title1="landing.videos.title.1"
          title2="landing.videos.title.2"
        />
        <Contents />
      </Box>
    </Container>
    <Contact />
    <Social />
    <Footer />
  </Content>
);

export default Client;
