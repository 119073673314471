import React, { useContext } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import AndroidIcon from '@material-ui/icons/Android';
import { I18nContext } from 'translations';
import styles from './style';

function DownloadApp() {
  const classes = styles();
  const { translate } = useContext(I18nContext);
  return (
    <div className={classes.root}>
      <Paper elevation={4} variant="outlined" className={classes.wrapper}>
        <img src="/logo.png" alt="logo" className={classes.logo} />
        <Typography variant="h5" className="text-center">
          {translate('downloadApp')}
        </Typography>  
        <br /><br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="https://play.google.com/store/apps/details?id=com.pibox.enterprise&hl=es_CO"
          className={classes.btn}
          startIcon={<AndroidIcon />}
        >
          Android
        </Button>
      </Paper>
    </div>
  );
}

export default DownloadApp;
