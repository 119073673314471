import React from 'react';
import { useStateValue } from 'context/store';
import Unauthorized from 'components/Errors/Unauthorized';

const addon = (Component, { name, showError = false }) => ({ ...props }) => {
  const [{ checkin }] = useStateValue();
  if (!checkin.checkin) return false;
  if (!checkin.addons[name]) {
    return showError ? <Unauthorized /> : false;
  }

  return <Component {...props} />;
};

export default addon;
