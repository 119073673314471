import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StateProvider } from './context/store';
import { theme } from './utils/theme';
import Routes from './routes';
import { indexReducer } from './context/reducers';
import { I18nContextProvider } from './translations';
import initialState from './initialState';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <StateProvider reducer={indexReducer} initialState={initialState}>
        <I18nContextProvider>
          <Routes />
        </I18nContextProvider>
      </StateProvider>
    </MuiThemeProvider>
  );
}

export default App;
