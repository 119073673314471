import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  getHeadquarterById,
  updateHeadquarter,
} from 'context/headquarters/actions';
import { modalMessage } from 'utils';
import PaperBlock from 'components/PaperBlock';
import BackButtonTitle from 'components/Layout/BackButtonTitle';
import Loading from 'components/Loading';
import HeadquarterForm from 'pages/Headquarters/components/form';

function EditHeadquarter({ history, match }) {
  const [{ headquarters }, dispatch] = useStateValue();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({});
  const { langCode, translate } = useContext(I18nContext);
  const {
    params: { id },
  } = match;
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('headquarters.successfull.edited.title'),
      translate('headquarters.successfull.edited.description'),
      'success',
      translate('continue')
    ).then((result) => {
      result.value && history.push('/app/headquarters');
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const callback = await updateHeadquarter(
      dispatch,
      {
        ...values,
        address: address.name,
        lat: address.lat,
        lon: address.lon,
      },
      id,
      langCode
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  const getHeadquarter = async (headquarterId) => {
    setLoading(true);
    const callback = await getHeadquarterById(
      dispatch,
      headquarterId,
      langCode
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    id && getHeadquarter(id);
  }, [id]); // eslint-disable-line

  useEffect(() => {
    if (headquarters.selectedHeadquarter) {
      setAddress({
        name: headquarters.selectedHeadquarter.address,
        lat: headquarters.selectedHeadquarter.lat,
        lon: headquarters.selectedHeadquarter.lon,
      });
    }
  }, [headquarters.selectedHeadquarter]);

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('headquarters.edit')}
          link="/app/headquarters"
          buttonName={translate('back')}
        />
        <Grid container spacing={5} style={{ marginTop: 30 }} justify="center">
          <Grid item md={8}>
            {headquarters.selectedHeadquarter && (
              <HeadquarterForm
                submit={onSubmit}
                translate={translate}
                headquarter={headquarters.selectedHeadquarter}
                handleChangeAddress={setAddress}
                address={address}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

EditHeadquarter.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditHeadquarter;
