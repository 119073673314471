import React from 'react';
import Slider from 'react-slick';
import './carousel.css';

function Carousel({ images }) {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    adaptiveHeight: true,
    className: '',
    customPaging: () => <span></span>,
  };

  return (
    <Slider {...settings}>
      {images.map((image) => (
        <div key={image.id}>
          <img width="100%" src={image.src} alt="" />
        </div>
      ))}
    </Slider>
  );
}

export default Carousel;
