import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { COUNTRIES } from 'utils/constants';
import {
  AddonFormControlVehicles,
  // AddonFormControlHeadquarters,
} from './components';
import schema from './schema';
import styles from './style';
import DriverSchedule from './components/DriverSchedule';

function Form({
  submit,
  translate,
  driver,
  // headquarters,
  handleChangeSchedule,
}) {
  const classes = styles();

  function listOfDefaultHeadquarters() {
    const headquarter_ids = [];
    driver.headquarters.forEach(({ _id }, index) => { headquarter_ids[index] = _id; });
    headquarter_ids[headquarter_ids.length] = '';
    return headquarter_ids;
  }

  const {
    handleSubmit, errors, register, control,
  } = useForm({
    validationSchema: schema,
    mode: 'onChange',
    defaultValues: {
      headquarter_ids:
        driver && driver.headquarters ? listOfDefaultHeadquarters() : [''],
    },
  });

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            name="name"
            error={!!errors.name}
            label={translate('fullName')}
            className={classes.field}
            defaultValue={driver && driver.name}
            helperText={errors.name && translate(errors.name.message)}
            variant="outlined"
            inputRef={register}
            required
            inputProps={{ maxLength: '70' }}
            fullWidth
          />
          <TextField
            name="email"
            error={!!errors.email}
            label={translate('email')}
            defaultValue={driver && driver.email}
            helperText={errors.email && translate(errors.email.message)}
            variant="outlined"
            inputRef={register}
            className={classes.field}
            required
            fullWidth
          />
          <Grid container spacing={1}>
            <Grid item md={3} sm={12} xs={12} className="phone-input text-left">
              <Controller
                control={control}
                name="phone_country_code"
                defaultValue={driver ? driver.phone_country_code : '57'}
                as={(
                  <PhoneInput
                    country="co"
                    defaultCountry="co"
                    onlyCountries={COUNTRIES}
                    placeholder={translate('code')}
                    inputProps={{ disabled: true }}
                  />
                )}
              />
            </Grid>
            <Grid item md={9} sm={12} xs={12}>
              <TextField
                name="phone_number"
                error={!!errors.phone_number}
                label={translate('phone')}
                inputProps={{ maxLength: '11' }}
                defaultValue={driver && driver.phone_number}
                helperText={
                  errors.phone_number && translate(errors.phone_number.message)
                }
                variant="outlined"
                inputRef={register}
                className={classes.field}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12} className="text-left">
            <AddonFormControlVehicles
              control={control}
              vehicleSelected={
                driver && driver.vehicle_profile && driver.vehicle_profile._id
              }
            />
          </Grid>
        </Grid>
        {/* {headquarters.data && (
          <AddonFormControlHeadquarters
            control={control}
            driver={driver}
            classes={classes}
            headquarters={headquarters.data}
          />
        )}
        */}
        <Grid item md={12} sm={12} xs={12} className="text-left">
          <DriverSchedule
            driver={driver}
            handleChangeSchedule={handleChangeSchedule}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} className="text-center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            className={classes.btn}
          >
            {driver
              ? translate('driver.editDriver')
              : translate('driver.addDriver')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default Form;
