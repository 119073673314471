import React, {
  useEffect, useContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Snackbar,
  Grid,
  createStyles,
  withStyles,
  Box,
  Button,
} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  getOptimizationTasks,
  getTasksPoints,
  getUnassignedTasks,
  cancelOptimizationTasks,
} from 'context/routes/actions';
import { messageConfirmation } from 'utils';
import Loading from 'components/Loading';
import PolylineMap from 'components/Maps/PolylineMap';
import ListRoutes from 'components/Routes/ListRoutes';
import OptimizedRouteModal from 'components/Modals/OptimizedRoute';

const styles = createStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  mapItem: {
    width: '100%',
    overflow: 'hidden',
  },
  mapContainer: {
    [theme.breakpoints.up('lg')]: {
      width: 'inherit',
      maxWidth: '33%',
      position: 'fixed',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '36%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ListRoutesOptimization = ({ history, match, classes }) => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [selectedTask, setSelectedTask] = useState();
  const [openParams, setOpenParams] = useState(false);
  const [{ optimizedRoutes }, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);
  const {
    params: { id },
  } = match;

  const params = useMemo(() => {
    if (optimizedRoutes
      && optimizedRoutes.optimizedRoutes
      && optimizedRoutes.optimizedRoutes.jobs
      && optimizedRoutes.optimizedRoutes.jobs.length) {
      return optimizedRoutes.optimizedRoutes.jobs[0].optimization_tasks_file;
    }
    return null;
  }, [optimizedRoutes]);

  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const listOptimizationTasks = async (page) => {
    setLoading(true);
    const callback = await getOptimizationTasks(dispatch, id, {
      page,
      per_page: 10,
    });
    callback ? setLoading(false) : handleMessage(callback.message);
  };

  const listUnassignedTasks = async () => {
    const callback = await getUnassignedTasks(dispatch, id, {});
    callback.message && handleMessage(callback.message);
  };

  const getPoints = async () => {
    await getTasksPoints(dispatch, id);
  };

  useEffect(() => {
    if (id) {
      listOptimizationTasks(currentPage);
      listUnassignedTasks();
      getPoints();
    }
  }, [id]); // eslint-disable-line

  const handleChangePage = (type) => {
    if (type === 'inc') {
      listOptimizationTasks(currentPage + 1);
      setCurrentPage(currentPage + 1);
    } else {
      listOptimizationTasks(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleChangeSelectedTask = (e, task, job) => {
    e.stopPropagation();
    setSelectedTask({
      id: task._id,
      route: job._id,
      lat: task.lat,
      lon: task.lon,
      external_id: task.external_id,
    });
  };

  const onCancelTask = async () => {
    messageConfirmation({
      translate,
      title: 'routes.cancelTask.title',
      text: 'routes.cancelTask.description',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setLoading(true);
        const callback = await cancelOptimizationTasks(dispatch, id);
        if (callback.status) {
          setLoading(false);
          history.push('/app/routes-file-list');
        } else {
          handleMessage(callback.message);
        }
      }
    });
  };

  return (
    <>
      {loading && <Loading />}
      {optimizedRoutes && optimizedRoutes.optimizedRoutes && (
        <>
          <Typography variant="h4" gutterBottom className={classes.header}>
            {translate('routes.optimizedRoutes')}
            <Button
              color="primary"
              startIcon={<TuneIcon />}
              size="large"
              onClick={() => setOpenParams(true)}
            >
              {translate('routes.params.see')}
            </Button>
          </Typography>
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            className={classes.container}
          >
            <Grid item lg={5} xs={12} className={classes.mapItem}>
              <Box className={classes.mapContainer}>
                {optimizedRoutes.optimizedRoutes.jobs.length > 0 && (
                  <PolylineMap
                    routes={optimizedRoutes.optimizedRoutes.jobs}
                    selectedRoutes={selectedRoutes}
                    selectedTask={selectedTask}
                    handleChangeSelectedTask={handleChangeSelectedTask}
                    points={
                      optimizedRoutes.pointsMap
                        ? optimizedRoutes.pointsMap.jobs
                        : []
                    }
                  />
                )}
              </Box>
            </Grid>
            <Grid item lg={7} xs={12}>
              <ListRoutes
                routes={optimizedRoutes.optimizedRoutes}
                unassigned={optimizedRoutes.unassignedRoutes}
                history={history}
                handleChangePage={handleChangePage}
                page={currentPage}
                perPage={10}
                loadingPage={loading}
                handleChangeSelectedRoutes={setSelectedRoutes}
                selectedTask={selectedTask}
                handleChangeSelectedTask={handleChangeSelectedTask}
                onCancelTask={onCancelTask}
              />
            </Grid>
          </Grid>
          <OptimizedRouteModal
            translate={translate}
            open={openParams}
            handleClose={() => setOpenParams(false)}
            params={params}
          />
        </>
      )}
      <Snackbar
        open={message && true}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
      />
    </>
  );
};

ListRoutesOptimization.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListRoutesOptimization);
