import React from 'react';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@material-ui/core';
import {
  ExpandLess, ExpandMore, SupervisorAccount,
} from '@material-ui/icons';

import DriversItem from './Drivers';
import OperatorsItem from './Operators';

function UsersMenu({
  translate, classes, handleCollapse, openCollapse,
}) {
  return (
    <>
      <ListItem
        button
        onClick={() => handleCollapse('users')}
        className={
          openCollapse.users ? classes.activeCollapse : classes.itemMenu
        }
      >
        <ListItemIcon>
          <SupervisorAccount color="primary" />
        </ListItemIcon>
        <ListItemText primary={translate('menu.users')} />
        {openCollapse.users ? <ExpandLess color="primary" /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse.users} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <DriversItem translate={translate} classes={classes} />
          <OperatorsItem translate={translate} classes={classes} />
        </List>
      </Collapse>
    </>
  );
}

export default UsersMenu;
