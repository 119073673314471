import React, { useContext } from 'react';
import { Typography, Button, Paper, Slide, Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { useForm } from 'react-hook-form';
import { modalMessage } from 'utils';
import { useStateValue } from 'context/store';
import { dismissError } from 'context/common/actions';
import { submitContactForm } from 'context/landing/actions';
import schema from './schema';
import ContactField from '../ContactField/ContactField';
import styles from './Style';

const Contact = ({ classes }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors, reset } = useForm({
    validationSchema: schema,
  });
  const [
    {
      common: { loading, error },
    },
    dispatch,
  ] = useStateValue();

  const showModalSuccess = () => {
    modalMessage(
      translate('landing.contact.success.title'),
      translate('landing.contact.success.message'),
      'success',
      translate('continue')
    ).then(() => {
      reset();
    });
  };

  const onSubmit = (values) => {
    submitContactForm(dispatch, values, showModalSuccess);
  };

  return (
    <>
      <Slide
        style={{ border: '1px solid #dddddd' }}
        direction="down"
        in
        mountOnEnter
        unmountOnExit
        timeout={1000}
      >
        <Paper className={classes.container}>
          <Typography variant="h5" gutterBottom className="text-center">
            <b>{translate('landing.contact.title.1')}</b>&nbsp;
            <b style={{ color: '#8029CF' }}>
              {translate('landing.contact.title.2')}
            </b>
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <ContactField
              fullWidth
              label={translate('landing.contact.name')}
              variant="outlined"
              required
              size="small"
              error={!!errors.name}
              helperText={errors.name && translate(errors.name.message)}
              inputRef={register}
              inputProps={{ maxLength: 70 }}
              name="name"
            />
            <ContactField
              fullWidth
              label={translate('landing.contact.company')}
              variant="outlined"
              required
              size="small"
              name="company"
              error={!!errors.company}
              helperText={errors.company && translate(errors.company.message)}
              inputRef={register}
              inputProps={{ maxLength: 70 }}
            />
            <ContactField
              fullWidth
              name="email"
              label={translate('landing.contact.email')}
              variant="outlined"
              required
              size="small"
              error={!!errors.email}
              helperText={errors.email && translate(errors.email.message)}
              inputRef={register}
            />
            <ContactField
              fullWidth
              name="phone"
              label={translate('landing.contact.phone')}
              variant="outlined"
              required
              size="small"
              error={!!errors.phone}
              inputProps={{ maxLength: 12 }}
              helperText={errors.phone && translate(errors.phone.message)}
              inputRef={register}
            />
            <ContactField
              fullWidth
              name="message"
              label={translate('landing.contact.tell.us.more')}
              variant="outlined"
              multiline
              rows={4}
              size="small"
              inputRef={register}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
              size="large"
              disabled={loading}
              style={{ fontSize: '18px', padding: '6px 0px' }}
            >
              {translate('landing.contact.send')}
            </Button>
          </form>
        </Paper>
      </Slide>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => dismissError(dispatch)}
        message={translate(error)}
      />
    </>
  );
};

export default withStyles(styles)(Contact);
