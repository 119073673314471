import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
  Tooltip,
  Box,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { I18nContext } from 'translations';
import { InputFilter } from 'components';
import styles from 'components/Tables/style';

function ListOperators({
  data,
  recordsPerPage,
  currentPage,
  totalCount,
  handleChangePage,
  onSearch,
}) {
  const classes = styles();
  const { translate } = useContext(I18nContext);

  if (!data || (data && data.length === 0)) {
    return <p>{translate('noData')}</p>;
  }

  return (
    <Box mb={2}>
      <InputFilter onSearch={(event) => onSearch(event)} />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('name')}</TableCell>
              <TableCell>{translate('email')}</TableCell>
              <TableCell>{translate('phone')}</TableCell>
              <TableCell align="center">{translate('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index.toString()}>
                <TableCell className="bold">
                  <p>{item.name}</p>
                </TableCell>
                <TableCell>
                  <p>{item.email}</p>
                </TableCell>
                <TableCell>
                  <p>{`${item.phone_country_code} - ${item.phone_number}`}</p>
                </TableCell>
                <TableCell align="center">
                  <Fragment>
                    <Tooltip title={translate('edit')}>
                      <Button
                        style={{ minWidth: 35 }}
                        href={`/app/operators/${item._id}`}
                        size="small"
                      >
                        <CreateIcon />
                      </Button>
                    </Tooltip>
                  </Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => {
          handleChangePage(newPage + 1);
        }}
      />
    </Box>
  );
}

ListOperators.propTypes = {
  data: PropTypes.array,
  recordsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

ListOperators.defaultProps = {
  data: {},
};

export default ListOperators;
