import SwalAlert from 'sweetalert2';
// Class color for status_cd
export const statusClass = (status) => (status === 5
  ? 'status-ok'
  : status >= 6
    ? 'status-failed'
    : 'status-progress');

// Hex color for status_cd
export const statusColor = (status) => (status === 5 ? '#93ca00' : status >= 6 ? '#ff0000' : '#f8b133');

// Class color for status_cd
export const bulkStatusClass = (status) => {
  switch (status) {
    case 1:
      return 'status-ok';
    case 2:
      return 'status-failed';
    case 3:
      return 'status-cancelled';
    default:
      return 'status-progress';
  }
};
// Class color for on duty
export const onDutyClass = (onDuty) => (onDuty ? 'status-ok' : '');

export const textOverflow = (text, count) => text.slice(0, count) + (text.length > count ? '...' : '');

// Message Modal
export const modalMessage = (
  title,
  text,
  type,
  nameButton = 'Ok',
  isCancelButton = false,
  nameButtonCancel,
) => SwalAlert.fire({
  title,
  text,
  showCancelButton: isCancelButton,
  confirmButtonText: nameButton,
  cancelButtonText: nameButtonCancel,
  confirmButtonColor: '#6f2f82',
  cancelButtonColor: '#424242',
  allowEscapeKey: false,
  allowOutsideClick: false,
});

// Message confirmation
export const messageConfirmation = async ({
  translate,
  title,
  text,
  isAllowEnterKey = true,
  confirmButtonText = 'confirm',
  cancelButtonText = 'cancel',
}) => {
  const confirm = await SwalAlert.fire({
    title: translate(title),
    text: translate(text),
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#6f2f82',
    cancelButtonColor: 'rgb(179, 177, 177)',
    confirmButtonText: translate(confirmButtonText),
    cancelButtonText: translate(cancelButtonText),
    allowEnterKey: isAllowEnterKey,
  });
  return confirm;
};

const arrayText = (text) => {
  let resultado = '';
  for (const i in text) {
    resultado += text[i];
  }
  return resultado;
};

// Message Modal HTML
export const modalMessageHtml = (
  title,
  text,
  type,
  nameButton = 'Ok',
  isCancelButton = false,
  nameButtonCancel,
) => SwalAlert.fire({
  title,
  icon: 'info',
  html: text.length > 0 ? arrayText(text) : text,
  showCancelButton: isCancelButton,
  cancelButtonText: nameButtonCancel,
  confirmButtonColor: '#6f2f82',
  cancelButtonColor: '#424242',
  confirmButtonText: nameButton,
  allowEscapeKey: false,
  allowOutsideClick: false,
  customClass: {
    content: 'swal-content-scroll',
  },
});

export const getFileName = (path) => path && decodeURI(path).split('\\').pop().split('/')
  .pop();

export const textPreview = (text, characters) => {
  if (!text) return '';
  if (text.length <= characters) return text;
  return `${text.substring(0, characters)}…`;
};
