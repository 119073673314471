import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Paper, Container } from '@material-ui/core';
import { I18nContext } from 'translations';
import { modalMessage } from 'utils';
import { useStateValue } from 'context/store';
import { listTracking, createRating } from 'context/tracking/actions';
import TrackingMap from 'components/Maps/TrackingMap';
import DriverCard from 'components/Cards/DriverCard';
import { listCheckin } from 'context/checkin/actions';
import Header from './Header';
import ViewText from './ViewText';
import styles from './style';

function Tracking({ match, history }) {
  const [message, setMessage] = useState(null);
  const { langCode, translate } = useContext(I18nContext);
  const [{ tracking, checkin }, dispatch] = useStateValue();
  const [rating, setRating] = useState(0);
  const classes = styles();
  const handleClose = () => setMessage(null);
  const handleMessage = (mssg) => setMessage(mssg);
  const {
    params: { id },
  } = match;

  const setDefaultLanguage = () => {
    if (tracking && tracking.tracking && tracking.tracking.lang) {
      dispatch({ type: 'SET_LANGUAGE', payload: tracking.tracking.lang });
      localStorage.setItem('lang', tracking.tracking.lang);
    }
  };

  const getTrack = async () => {
    const callback = await listTracking(dispatch, id, langCode);
    !callback.status && handleMessage(callback.message);
    setDefaultLanguage();
  };
  const getCheckin = async () => {
    await listCheckin(dispatch);
  };

  useEffect(() => {
    getTrack();
    getCheckin();
  }, []); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      getTrack();
    }, 5000);
    return () => clearInterval(interval);
  }, [tracking]); // eslint-disable-line

  const onChangeRating = (event, newValue) => setRating(newValue);
  const messageOk = () => {
    modalMessage(
      translate('rating.thanksForRatingOurService'),
      '',
      'success',
      translate('continue')
    ).then((result) => {
      result.value && history.go(0);
    });
  };

  const handleRating = async () => {
    const callback = await createRating(
      dispatch,
      { rating_to_driver: rating },
      id,
      langCode
    );
    callback.status ? messageOk() : handleMessage(callback.message);
  };

  const failed = () => (
    <ViewText
      title={translate('rating.orderCouldNotBeFinalized')}
      subtitle={translate('rating.weAreWorkingToImproveOurServices')}
    />
  );

  const successful = () => {
    if (tracking.tracking.rating_to_driver) {
      return <ViewText title={translate('rating.thanksForUsingOurServices')} />;
    }
    return (
      <ViewText
        title={translate('rating.thanksForRatingOurService')}
        text={tracking.tracking.driver.name}
        rating={rating}
        onChangeRating={onChangeRating}
        handleRating={handleRating}
        translate={translate}
      />
    );
  };

  const progress = () => (
    <Paper elevation={4} variant="outlined">
      <div className={classes.containerMap}>
        {tracking.tracking && <TrackingMap track={tracking.tracking} id={id} />}
      </div>
      <Container maxWidth="lg">
        <div className={classes.containerDriver}>
          {tracking.tracking && (
            <DriverCard
              tracking={tracking.tracking}
              id={id}
              langCode={langCode}
            />
          )}
        </div>
      </Container>
    </Paper>
  );

  const typeView = (status) => {
    if (checkin.checkin) return progress();
    switch (status) {
      case 6:
      case 7:
      case 8:
        return failed();
      case 3:
        return progress();
      case 5:
        return successful();
      default:
        return progress();
    }
  };

  return (
    <>
      <Header />
      {tracking && tracking.tracking && typeView(tracking.tracking.status_cd)}

      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </>
  );
}

Tracking.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Tracking;
