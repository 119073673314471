import { CHECKIN } from './constants';

const checkinReducer = (state, action) => {
  switch (action.type) {
    case CHECKIN:
      return {
        ...state,
        checkin: action.checkin,
        organization: action.checkin.profile.organization,
        addons: {
          ...action.addons,
        },
      };
    default:
      return state;
  }
};

export default checkinReducer;
