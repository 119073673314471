const { LIST_VEHICLES, SELECTED_VEHICLE } = require('./constants');

const vehiclesReducer = (state, action) => {
  switch (action.type) {
    case LIST_VEHICLES:
      return {
        ...state,
        data: action.vehicles,
        selectedVehicle: null,
      };
    case SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicle: action.vehicle,
      };
    default:
      return state;
  }
};

export default vehiclesReducer;
