import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { I18nContext } from 'translations';

const Banner = ({ title1, title2 }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item md={8} sm={12}>
        <Box textAlign="left" mb={4}>
          <Typography gutterBottom variant="h3">
            <b>{translate(title1)}</b>
            <br />
            <b style={{ color: '#8029CF' }}>{translate(title2)}</b>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Banner;
