import React, { useState, useContext, Fragment } from 'react';
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import addon from 'utils/addon';
import { ADDONS } from 'utils/constants';
import { AddCircleOutline, DeleteOutline } from '@material-ui/icons';
import { Controller, useFieldArray } from 'react-hook-form';
import { I18nContext } from 'translations';

function AddonFormControlHeadquarters({
  control,
  entity,
  register,
  headquarters,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headquarter_ids',
  });

  const { translate } = useContext(I18nContext);

  const initialValuesList = (_) => {
    let headquartersEntity = entity ? entity.headquarters.slice() : [];
    headquartersEntity.forEach((item) => {
      item.disabled = true;
    });

    headquarters.forEach((element) => {
      const searchItem = headquartersEntity.filter(
        (hd) => hd._id === element._id
      ).length;
      !searchItem && headquartersEntity.push(element);
    });

    return headquartersEntity;
  };

  const [itemsSelected, setItemsSelected] = useState(initialValuesList);

  const initialValuesItemsSelectedUpdate = (_) => {
    let listHeadquarters = [];
    entity &&
      entity.headquarters.forEach((item) => listHeadquarters.push(item._id));
    return listHeadquarters;
  };

  const [entityHeadquarters, setEntityHeadquarters] = useState(
    initialValuesItemsSelectedUpdate
  );

  function updateItemSelected(selectedItemId, previous) {
    let listSelectedHeadquarters = itemsSelected.slice();
    listSelectedHeadquarters.forEach((item) => {
      if (item._id === previous) item.disabled = false;
      if (item._id === selectedItemId) item.disabled = true;
    });

    let indexItem = entityHeadquarters.indexOf(previous);
    if (indexItem >= 0) {
      entityHeadquarters[indexItem] = selectedItemId;
    } else {
      entityHeadquarters.push(selectedItemId);
    }
    setItemsSelected(listSelectedHeadquarters);
    setEntityHeadquarters(entityHeadquarters);
  }

  function onClickItemList(item, index) {
    updateItemSelected(item._id, entityHeadquarters[index]);
    fields.length - 1 === index && append('');
  }

  function removeItemSelected(indexItemRemove) {
    remove(indexItemRemove);
    var clonedListHeadquarters = entityHeadquarters.slice();
    var clonedItemsSelected = itemsSelected.slice();
    var idHeadquarterSelected = clonedListHeadquarters[indexItemRemove];

    clonedItemsSelected.forEach((item) => {
      if (item._id === idHeadquarterSelected) item.disabled = false;
    });

    setItemsSelected(clonedItemsSelected);
    clonedListHeadquarters.splice(indexItemRemove, 1);

    setEntityHeadquarters(clonedListHeadquarters);
  }

  const selectedField = () => {
    return fields.map(({ id }, index) => (
      <Fragment key={`list-${id}`}>
        <Grid item md={9} sm={9} xs={9} className="text-left">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="headquarter">
              {translate('headquarters.assign')}
            </InputLabel>
            <Controller
              inputRef={register}
              control={control}
              name={`headquarter_ids[${index}]`}
              label={translate('headquarters.assing')}
              defaultValue=""
              variant="outlined"
              as={
                <Select>
                  {!itemsSelected ||
                    (!itemsSelected.length && (
                      <MenuItem disabled>
                        {translate('headquarters.empty')}
                      </MenuItem>
                    ))}
                  {itemsSelected &&
                    itemsSelected.map((option) => (
                      <MenuItem
                        key={option._id}
                        value={option._id}
                        onClick={() => onClickItemList(option, index)}
                        disabled={option.disabled}
                      >
                        {translate(option.name)}
                      </MenuItem>
                    ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        {fields.length - 1 !== index ? (
          <Grid item md={3} sm={3} xs={3} className="text-left">
            <Tooltip title={translate('remove')} placement="top" arrow>
              <span>
                <IconButton
                  color="primary"
                  aria-label="remove"
                  onClick={() => removeItemSelected(index)}
                  disabled={fields.length === 1}
                >
                  <DeleteOutline fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>{' '}
          </Grid>
        ) : (
          <Grid
            container
            item
            md={3}
            sm={3}
            xs={3}
            justify="center"
            alignContent="center"
          >
            <Fragment>
              <Button
                type="button"
                color="primary"
                size="large"
                href="/app/headquarters/new-headquarter"
                startIcon={<AddCircleOutline />}
              >
                {translate('headquarters.add')}
              </Button>
            </Fragment>
          </Grid>
        )}
      </Fragment>
    ));
  };

  return (
    <>
      <Grid item md={12} sm={12} xs={12} className="text-left">
        <br />
        <Typography variant="subtitle1" gutterBottom>
          {translate('headquarters')}
        </Typography>
      </Grid>
      {selectedField()}
    </>
  );
}
const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: false,
};

export default addon(AddonFormControlHeadquarters, addonOptions);
