import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  wrapper: {
  	padding: '50px',
  	alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '50px 20px'
    }
  },
  logo: {
  	width: '330px',
  	marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '200px'
    }
  }
}));

export default styles;
