import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { I18nContext } from 'translations';

function FiltersListDriver({ getDrivers, getDriversOnDuty, onDuty, onSearch }) {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12} className="text-left">
        <Button
          color={onDuty ? 'default' : 'primary'}
          size="large"
          onClick={() => getDrivers(1)}
        >
          {' '}
          {translate('all')}{' '}
        </Button>
        <Button
          color={onDuty ? 'primary' : 'default'}
          size="large"
          onClick={() => getDriversOnDuty(1)}
        >
          {' '}
          {translate('onDuty')}{' '}
        </Button>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="search"
          placeholder={translate('search')}
          fullWidth
          onKeyPress={(e) => onSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

FiltersListDriver.propTypes = {
  getDrivers: PropTypes.func.isRequired,
  getDriversOnDuty: PropTypes.func.isRequired,
  onDuty: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default FiltersListDriver;
