import React, { useState, useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { operatorsBulkLoad } from 'context/operators/actions';
import { uploadFile } from 'utils/aws';
import { modalMessage } from 'utils';
import BulkLoad from 'components/BulkLoad';

const OperatorsBulkLoad = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [invalidRows, setInvalidRows] = useState(null);
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();

  const columns = useMemo(
    () => [
      {
        name: 'name',
        value: (row) => row.name,
        error: (row) => row.errors.name,
      },
      {
        name: 'countryCode',
        value: (row) => row.phone_country_code,
        error: (row) => row.errors.phone_country_code,
      },
      {
        name: 'customerPhone',
        value: (row) => row.phone_number,
        error: (row) => row.errors.phone_number,
      },
      {
        name: 'email',
        value: (row) => row.email,
        error: (row) => row.errors.email,
      },
      {
        name: 'headquarters',
        value: (row) => row.headquarters_name,
        error: (row) => row.errors.headquarters_name,
      },
      {
        name: 'vehicle',
        value: (row) => row.vehicle_name,
        error: (row) => row.errors.vehicle_name,
      },
    ],
    [],
  );

  const alertMessage = (msg) => {
    setLoading(false);
    setMessage(msg);
  };

  const modalMsg = (invalidOperators) => {
    setLoading(false);

    if (invalidOperators.length) {
      setInvalidRows(invalidOperators);
      return;
    }

    modalMessage(
      translate('bulkLoad.fileUploadedSuccessfully'),
      translate('operator.successfully.bulkload'),
      'success',
      translate('continue'),
    ).then(() => history.push('/app/operators'));
  };

  const handleUpload = async (file) => {
    setLoading(true);
    const date = Date.parse(new Date());
    const response = await uploadFile(
      file[0],
      `uploads/operators/bulk_import/${date}`,
    );
    if (response) {
      const callback = await operatorsBulkLoad(dispatch, {
        file_url: response.Location,
      });
      callback.status
        ? modalMsg(callback.invalidOperators)
        : alertMessage(translate(callback.message));
    } else {
      alertMessage(translate('error.OppsAnErrorHasOccurredTryAgainLater'));
    }
  };

  return (
    <BulkLoad
      title="operator.title.bulkload"
      format="/files/formatoOperatorsBulkLoad.xlsx"
      handleUpload={handleUpload}
      loading={loading}
      invalidRows={invalidRows}
      handleCloseInvalidRows={() => setInvalidRows(false)}
      columns={columns}
      rowId="email"
      message={message}
      handleCloseMessage={() => setMessage(null)}
    />
  );
};

export default withRouter(OperatorsBulkLoad);
