import axios from 'axios';

/** Petición GET estandar con axios */
export const get = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = { ...params };
  const response = await axios({
    headers: {
      'X-PIBOX-E-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'GET',
    params: sendParams,
  });
  return response;
};

/** Petición POST estandar con axios */
export const post = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PIBOX-E-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'POST',
    data: dataRequest,
  });
  return response;
};

/** Petición PATCH estandar con axios */
export const patch = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PIBOX-E-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'PATCH',
    data: dataRequest,
  });
  return response;
};

/** Petición PUT estandar con axios */
export const put = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PIBOX-E-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'PUT',
    data: dataRequest,
  });
  return response;
};

/** Petición DELETE estandar con axios */
export const remove = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PIBOX-E-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'DELETE',
    data: dataRequest,
  });
  return response;
};

/** Gets and forces download of a xls file from server */
export const getExcelFile = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = { ...params };
  const response = await axios({
    url: urlRequest,
    method: 'GET',
    params: sendParams,
    headers: {
      Accept: 'application/xls',
      'X-PIBOX-E-TOKEN': default_token || '',
      lang,
    },
  }).then((httpResponse) => {
    const url = window.URL.createObjectURL(
      new Blob([httpResponse.data], { type: 'application/xls' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Reporte.xls');
    document.body.appendChild(link);
    link.click();
  });
  return response;
};
