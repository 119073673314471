import React, { useEffect, useState, useContext, useRef } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography, Snackbar } from '@material-ui/core';
import { PaperBlock, Loading } from 'components';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { listVehicles } from 'context/vehicles/actions';
import { ADDONS } from 'utils/constants';
import addon from 'utils/addon';
import VehiclesTable from './components/ListVehicle';

function VehiculeType() {
  const [loading, setLoading] = useState(false);
  const { langCode, translate } = useContext(I18nContext);
  const [per_page] = useState(10);
  const [message, setMessage] = useState(null);
  const unmounted = useRef(false);
  const [{ vehicles }, dispatch] = useStateValue();
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const loadingAction = (loading, action) => {
    setLoading(loading);
    unmounted.current = false;
  };

  const getVehicles = async (page) => {
    loadingAction(true, false);
    const callback = await listVehicles(dispatch, { page, per_page }, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    getVehicles(1);
    return () => {
      unmounted.current = true;
    };
    // eslint-disable-next-line
  }, [langCode]);

  return (
    <PaperBlock>
      {loading && <Loading />}
      <Grid container style={{ marginBottom: '15px' }}>
        <Grid item md={6} xs={12} sm={12}>
          <Typography variant="h4" className="title text-left text-center-sm">
            {translate('vehicles.vehicleList')}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} sm={12} className="text-right text-center-sm">
          <Button
            type="button"
            color="primary"
            variant="contained"
            size="large"
            component={Link}
            startIcon={<AddCircleOutlineIcon />}
            to="/app/vehicles/new-vehicle"
          >
            {translate('vehicles.add')}
          </Button>
        </Grid>
        {vehicles.data && (
          <VehiclesTable data={vehicles.data} recordsPerPage={per_page} />
        )}
      </Grid>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: true,
};

export default addon(VehiculeType, addonOptions);
