import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ContactField = withStyles((theme) => ({
  root: {
    margin: '8px 0',
    '& .MuiOutlinedInput-root': {
      border: '0.5px solid #D8D8D8',
      heigth: 30,
      backgroundColor: '#fff',
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: '0.5px solid',
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      color: '#C8C8C8',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      paddingTop: 4,
      borderRadius: 8,
    },
  },
}))(TextField);

export default ContactField;
