import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Snackbar, Typography, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useStateValue } from 'context/store';
import { listCheckin } from 'context/checkin/actions';
import { closeSession } from 'context/users/actions';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import Language from '../Language';
import MenuLateral from './Menu';
import MenuUser from './MenuUser';
import styles from './style';

function Layout({ children }) {
  const classes = styles();
  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const unmounted = useRef(false);
  const isLoggedIn = localStorage.getItem('session_token');
  const [{ checkin }, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const screen = media => media.matches ? setOpen(false) : setOpen(true);
  const handleClose = () => setMessage(null);
  const handleOpenMenu = event => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  useEffect(() => {
    let media = window.matchMedia('(max-width: 960px)');
    screen(media);
    media.addListener(screen);

    const getCheckin = async () => {
      setLoading(true);
      const callback = await listCheckin(dispatch);
      callback.status ? !unmounted.current && setLoading(false) : !unmounted.current && handleMessage(callback.message);
    }
    getCheckin();
    return () => { unmounted.current = true }
    // eslint-disable-next-line
  }, [dispatch]);

  const logout = async () => await closeSession(dispatch);

  if (!isLoggedIn) { return <Redirect exact to="/login" /> }

  return (
    <div className={classes.root}>
      {loading && <Loading />}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.closeSession}>
            {checkin.checkin ? (
              <MenuUser
                closeSession={logout}
                handleOpenMenu={handleOpenMenu}
                checkin={checkin.checkin}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                handleCloseMenu={handleCloseMenu}
                translate={translate}
              />
            ) : (
              <Button onClick={closeSession}>
                <Typography variant="h6" noWrap>
                  {translate("close_session")}
                </Typography>            
              </Button>
            )}
            <Language />
          </div>
        </Toolbar>
      </AppBar>
      
      <MenuLateral
        open={open}
        handleDrawerClose={handleDrawerClose}
        translate={translate}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        {children}
      </main>
      <Snackbar
        open={message && true}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
        />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;