import { get, post, put } from 'api/requests';
import { urlInviteUser, urlOperator, urlOperatorsBulkLoad } from 'api';
import handleErrorMessage from 'context/common/actions';
import { LIST_OPERATORS, SELECTED_OPERATOR } from './constants';

export const listOperators = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await get(urlOperator, params, lang)
    .then((json) => json.data)
    .then((data) => {
      dispatch({ type: LIST_OPERATORS, operators: data });
      status = true;
    })
    .catch((error) => {
      message = handleErrorMessage(error, dispatch);
    });
  return { status, message };
};

export const getByIdOperator = async (dispatch, id, lang) => {
  let status = false;
  let message = null;
  await get(`${urlOperator}/${id}`, {}, lang)
    .then((json) => json.data)
    .then((data) => {
      dispatch({ type: SELECTED_OPERATOR, operators: data });
      status = true;
    })
    .catch((error) => {
      message = handleErrorMessage(error, dispatch);
    });
  return { status, message };
};

export const createOperator = async (dispatch, values) => {
  let status = false;
  let message = null;
  await post(urlInviteUser, { ...values })
    .then(() => (status = true))
    .catch((error) => {
      message = handleErrorMessage(error, dispatch);
      status = false;
    });
  return { status, message };
};

export const updateOperator = async (dispatch, values, id) => {
  let status = false;
  let message = null;
  await put(`${urlOperator}/${id}`, { ...values })
    .then(() => (status = true))
    .catch((error) => {
      message = handleErrorMessage(error, dispatch);
      status = false;
    });
  return { status, message };
};

export const operatorsBulkLoad = async (dispatch, file) => {
  let status = false;
  let message = null;
  let invalidOperators = null;
  await post(urlOperatorsBulkLoad, { ...file })
    .then(({ data }) => {
      status = true;
      invalidOperators = data.invalid_operators;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message, invalidOperators };
};
