import { urlBulkLoad } from 'api';
import { post } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';

export const bulkLoad = async (dispatch, file) => {
  let status = false;
  let message = null;
  let invalidJobs = null; 
  await post(urlBulkLoad, { ...file })
    .then(({ data }) => {
      status = true;
      invalidJobs = data.invalid_jobs;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message, invalidJobs }
}
