const handleErrorMessage = ({ response }, dispatch) => {
	if (response !== undefined) {
		if (response.status === 422) {
			return response.data.mssg
		} else if (response.status === 403) {
			setTimeout(() => {
				dispatch({
	        type: 'CLOSE_SESSION',
	        session: null
	      })
	    }, 5000);
	    return response.data.mssg;
		}
	}
	return 'error.OppsAnErrorHasOccurredTryAgainLater'
}

export default handleErrorMessage;

