import AWS from 'aws-sdk';

export const configureS3 = () => {
  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:15d7bdb1-03d5-43e5-a0c9-e2f1f4addffb'
    })
  });
}

export const uploadFile = async (file, path) =>  {
  configureS3();
  
  var fileName = file.name;
  var upload = new AWS.S3.ManagedUpload({
    params: {
      ACL: 'public-read',
      ContentType: file.type,
      Bucket: 'piboxenterprise',
      Key: `${path}/${fileName}`,
      Body: file
    }
  });
  return upload.promise();
}