import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  cardsBanner: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
    },
  },
  card: {
    display: 'flex',
    border: '1px dashed #BDBDBD',
    borderRadius: '20px',
    padding: '8px 5px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '10px 20px 10px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },
  subtitle: {
    color: '#4F4F4F',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
  },
}));

export default styles;
