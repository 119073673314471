import React from 'react';
import { Paper, Typography, Container } from '@material-ui/core';
import styles from './style';

function Terms() {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>
          <Typography variant="h5" className="text-center">
            <b>POR FAVOR LEA ESTOS TÉRMINOS Y CONDICIONES DETENIDAMENTE ANTES DEREGISTRARSE EN LA APLICACIÓN MÓVIL Y/O SITIO WEB</b>
          </Typography>
          <br />
          <br />
          <br />
          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              1. Definiciones
            </b>
            <br />

            <p>
              <b>Aplicación Móvil: </b>
              Programa informático denominado “Picap”, destinado a ser ejecutado en teléfonos inteligentes, tabletas u otros dispositivos móviles.
            </p>

            <p>
              <b>B2B: </b>
              Siglas en inglés que significan “Business to Business” y en español traducen “Empresa –Empresa”
            </p>

            <p>
              <b>B2C: </b>
              Siglas en inglés que significan “Business to Consumer” y en español traducen “Empresa –Consumidor”
            </p>

            <p>
              <b>C2C: </b>
              Siglas en inglés que significan “Consumer to Consumer” y en español traducen “Consumidor –Consumidor”
            </p>

            <p>
              <b>Comercio Electrónico: </b>
              Consiste en la realización de actos, negocios u operaciones mercantilesconcertados  a  través  del  intercambio  de  mensajes  de  datos  telemáticamente  cursados  entreproveedores y consumidores para la comercialización de productos.
            </p>

            <p>
              <b>Portal de Contacto: </b>
              Plataforma digital o electrónica que funciona a través de la Aplicación Móvil ySitio   Web   en   la   que   personas   naturales   y   jurídicas   pueden   ofrecer   productos   para   sucomercialización y, a su vez, los consumidores pueden contactarlos por ese mismo mecanismo
            </p>

            <p>
              <b>Producto: </b>
              Todo bien o servicio ofrecido y suministrado directamente por el Usuario Prestador en elPortal de Contacto a través de la Aplicación Móvil y/o Sitio Web.
            </p>

            <p>
              <b>Sitio Web: </b>
              <a href="https://piboxenterprise.picap.app/" target="_blank" rel="noopener noreferrer">https://piboxenterprise.picap.app</a>
            </p>

            <p>
              <b>Usuario/Usuarios: </b>
              En general, todo Usuario Consumidor y Usuario Prestador registrado en elPortal de Contacto
            </p>

            <p>
              <b>Usuario Consumidor/Usuarios Consumidores: </b>
              Toda persona natural o jurídica registrada en elPortal de Contacto que, como destinatario final, adquiera, disfrute o utilice un Producto para lasatisfacción de una necesidad propia, privada, familiar o doméstica y empresarial cuando no estéligada intrínsecamente a su actividad económica
            </p>

            <p>
              <b>Usuario Prestador/Usuarios Prestadores: </b>
              Toda persona natural o jurídica registrada en el Portalde Contacto que, de manera directa o indirectamente, suministre servicios, a cambio de unaremuneración, a un Usuario Consumidor
            </p>

            <p>
              <b>Usuario Proveedor/Usuarios Proveedores: </b>
              Toda persona natural o jurídica registrada en el Portalde   Contacto   que,   de   manera   directa   o   indirectamente,   ofrezca,   suministre,   distribuya   y/ocomercialice bienes, a cambio de una remuneración, a un Usuario Consumidor.
            </p>

            <p>
              <b>Ventas a distancia: </b>
              Son las realizadas sin que el Usuario Consumidor tenga contacto directo con elProducto que adquiere, que se dan vía Comercio Electrónico.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              2. Alcance
            </b>
            <br />

            <p>
              El acceso a la Aplicación Móvil y/o Sitio Web de propiedad de PICAP INC. (en adelante, "LaEmpresa") y la adquisición de los Productos por parte de los Usuarios, se regirán por los siguientesTérminos y Condiciones
            </p>
            <p>
              El registro en la Aplicación Móvil y/o Sitio Web le atribuye la condición de Usuario e implica laaceptación plena y sin reserva de todos los Términos y Condiciones que se encuentren vigentes enel momento mismo en que el Usuario acceda a la Aplicación Móvil y/o Sitio Web.
            </p>
            <p>
              Estos Términos y Condiciones sustituyen expresamente los acuerdos o compromisos celebradospreviamente con cualquier Usuario.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              3. Servicio de La Empresa
            </b>
            <br />

            <p>
              Los Usuarios reconocen y aceptan que La Empresa es un intermediario que les facilita el Portal deContacto a través de la Aplicación Móvil y/o Sitio Web para que puedan interactuar entre sí y llevar acabo operaciones B2B, B2C y C2C, pero en ningún caso La Empresa suministra los Productosofrecidos al Usuario Consumidor.
            </p>
            <p>
              El servicio de La Empresa se limita únicamente a otorgarle a los Usuarios Consumidores el uso dela Aplicación Móvil y/o Sitio Web, con el fin de que puedan conectarse con los Usuarios Prestadorespara adquirir los Productos que deseen por medio de la Aplicación Móvil y/o Sitio Web.
            </p>
            <p>
              La Empresa no tiene ninguna relación laboral con el Usuario Prestador ni de agencia comercial. Deeste modo, cualquier responsabilidad legal (civil, laboral, comercial, penal o administrativa) poractos   o   negocios   realizados,   información   no   veraz   y/o   incumplimientos   normativos,   seráresponsabilidad plena de dicho Usuario Prestador, siendo La Empresa responsable únicamente porel funcionamiento de la Aplicación Móvil y/o Sitio Web de acuerdo con los presentes Términos yCondiciones.
            </p>
            <p>
              A menos que La Empresa suscriba un contrato independiente con usted, la Aplicación Móvil y/o SitioWeb se ponen a disposición solo para su uso personal, no comercial.
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              4. Alcance
            </b>
            <br />

            <p>
              A través de Pibox, el Usuario Consumidor puede contratar el servicio de mensajería, domicilios o transporte de cosas, el cual es suministrado directamente por el Usuario Prestador, quien es proveedor independiente de dicho servicio. Por lo tanto, la prestación final del servicio quedará a discreción del Usuario Prestador con el que el Usuario Consumidor haya contratado
            </p>
          </Typography>
          <br />
          <br />
          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              5. Licencia de Acceso y Uso
            </b>
            <br />

            <p>
              Sujeto al cumplimiento de los presentes Términos y Condiciones, La Empresa le otorga una licencialimitada, no exclusiva, no sublicenciable, revocable, no transferible para: a) el acceso y uso de laAplicación Móvil y/o Sitio Web desde cualquier dispositivo; y (b) el acceso y uso de cualquiercontenido, información y material relacionado que pueda ponerse a disposición a través de laAplicación Móvil y/o Sitio Web, en cada caso solo para su uso personal, no comercial.
            </p>
            <p>
              La Empresa y sus licenciantes se reservan cualquier derecho que no haya sido expresamenteotorgado en los presentes Términos y Condiciones
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              6. Condiciones de Acceso y Uso
            </b>
            <br />

            <p>
              Para poder acceder y usar la Aplicación Móvil y/o Sitio Web, el Usuario deberá aceptar lospresentes Términos y Condiciones. Se entiende que el Usuario ha leído y aceptado todos losTérminos y Condiciones desde el momento en el que se registra en la Aplicación Móvil y/o SitioWeb. Mediante el acceso y uso de la Aplicación Móvil y/o Sitio Web usted está celebrando uncontrato vinculante con La Empresa.
            </p>
            <p>
              El acceso y uso de la Aplicación Móvil y/o Sitio Web está permitido únicamente para personasmayores de dieciocho (18) años, por lo tanto, el Usuario no podrá: a) autorizar a terceros a utilizarsu cuenta ni permitir a personas menores de dieciocho (18) años el acceso o uso de la AplicaciónMóvil y/o Sitio Web a través de su cuenta; b) permitir que personas menores de dieciocho (18) añosadquieran los Productos.
            </p>
            <p>
              El Usuario se obliga a hacer un uso adecuado y lícito de la Aplicación Móvil y/o Sitio Web deconformidad con la legislación aplicable y los presentes Términos y Condiciones. El Usuario deberáabstenerse de: a) hacer un uso no autorizado o fraudulento de la Aplicación Móvil y/o Sitio Web; b)acceder o intentar acceder a recursos restringidos de la Aplicación Móvil y/o Sitio Web; c) utilizar laAplicación Móvil y/o Sitio Web con fines o efectos ilícitos, ilegales, contrarios a lo establecido en lospresentes Términos y Condiciones, lesivos de los derechos e intereses de terceros, o que decualquier forma puedan dañar, inutilizar o sobrecargar o impedir la normal utilización o disfrute de laAplicación Móvil y/o Sitio Web; d) provocar daños en la Aplicación Móvil y/o Sitio Web o en lossistemas de sus proveedores o de terceros; e) introducir o difundir virus informáticos o cualesquieraotros sistemas físicos o lógicos que sean susceptibles de provocar daños en los sistemas de LaEmpresa, de sus proveedores o de terceros, f) intentar acceder, utilizar y/o manipular los datos deLa Empresa, terceros proveedores y otros usuarios; g) reproducir, copiar, distribuir, transformar omodificar la Aplicación Móvil y/o Sitio Web, permitir el acceso a terceros a través de cualquiermodalidad de comunicación pública, a menos que se cuente con la autorización del titular de loscorrespondientes derechos o ello esté legalmente permitido; h) obtener o intentar obtener loscontenidos empleando para ello medios o procedimientos distintos de los que, según los casos, sehayan puesto a su disposición a este efecto.
            </p>
            <p>
              El Usuario únicamente podrá adquirir los Productos a través de la Aplicación Móvil y/o Sitio Web. LaEmpresa no será responsable en caso de que el Usuario no disponga de un dispositivo compatibleo haya descargado una versión de la Aplicación Móvil incompatible con su dispositivo.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              7. Registro en la Aplicación Móvil y/o Sitio Web.
            </b>
            <br />

            <p>
              La adquisición de los Productos a través de la Aplicación Móvil y/o Sitio Web estará sujeta en todocaso al registro previo del Usuario mediante el diligenciamiento del correspondiente formulario, queserá previamente facilitado al Usuario.
            </p>
            <p>
              La Empresa se reserva el derecho de aceptar o rechazar libremente la solicitud de registro por partedel Usuario. El Usuario declara bajo la gravedad de juramento que los datos introducidos en elformulario de registro son completos, veraces y actualizados.
            </p>
            <p>
              El acceso a la cuenta personal del Usuario estará sujeto a un usuario y una contraseña asignadapor el Usuario al diligenciar el formulario de registro, por lo tanto, el ingreso a la cuenta se reputarárealizado por dicho Usuario, quien responderá en todo caso de tal acceso y uso.
            </p>
            <p>
              El Usuario será responsable en todo momento de la custodia de su contraseña, asumiendo, enconsecuencia, los daños y perjuicios que pudieran derivarse de su uso indebido, así como de lacesión o revelación de esta. En caso de olvido de la contraseña o cualquier otra circunstancia quesuponga un riesgo de acceso y/o utilización por parte de terceros no autorizados, el Usuario locomunicará inmediatamente a La Empresa al correo electrónico
              {' '}
              <a href="mailto:reclamaciones@picap.co">reclamaciones@picap.co</a>
              {' '}
              a fin deque esta proceda inmediatamente al bloqueo y sustitución de esta. En todo caso, las operacionesrealizadas  antes  de  dicha   comunicación   se  reputarán   efectuadas  por   el   Usuario,   quien   seráresponsable y pagará los gastos y/o daños que se deriven de cualquier acceso y/o uso noautorizado que se realice con anterioridad a dicha comunicación.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              8. Suspensión y cancelación de cuentas
            </b>
            <br />

            <p>
              La Empresa se reserva el derecho, en todo momento y a su entera discreción, de: a) Suspender la cuenta de un Usuario sin tener que dar explicaciones al respecto; b) Cancelar la cuenta de un Usuario si considera que existen razones fundadas para pensar que la cuenta se utilizó, se está utilizando o se puede utilizar para propósitos ilegales, fraudulentos, deshonestos, o si se ha violado alguna disposición de los presentes Términos y Condiciones.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              9. Obligaciones de La Empresa
            </b>
            <br />

            <p>
              La Empresa se obliga durante la operación de la Aplicación Móvil y/o Sitio Web a: a) administrar ygestionar el soporte lógico, operativo y de programación para el acceso a las cuentas de registro delos usuarios; b) permitir el acceso a las distintas herramientas de La Empresa dependiendo del tipode servicio que haya elegido utilizar cada usuario; c) prestar mantenimiento a la Aplicación Móvil y/oSitio Web cuando esta lo requiera y; d) prestar soporte y asistencia a los usuarios sobre el uso detodas las utilidades dispuestas por La Empresa
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              10. Responsabilidad de La Empresa
            </b>
            <br />

            <p>
              El Usuario reconoce y acepta que el acceso y uso de la Aplicación Móvil y/o Sitio Web es libre,conscientemente   y   bajo   su   exclusiva   responsabilidad.   La   Empresa   no   será   en   ningún   casoresponsable de las operaciones que los Usuarios realicen ni de los daños y perjuicios que pudieranderivarse de estas.
            </p>
            <p>
              Usted reconoce y acepta que: a) La Empresa no suministra los Productos ofrecidos a través de laAplicación Móvil y/o Sitio Web, b) La Empresa es un intermediario que le facilita a los usuarios unPortal de Contacto a través de la Aplicación Móvil y/o Sitio Web para que puedan interactuar entre síy llevar a cabo operaciones B2B, B2C Y C2C y, c) los Productos ofrecidos a través de la AplicaciónMóvil   y/o   Sitio   Web   son   suministrados   por   terceros   proveedores   particulares   independientes(Usuario Prestador), que no tienen una relación laboral con La Empresa.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              11. Deber de información de errores
            </b>
            <br />

            <p>
              Es ilegal servirse y/o aprovecharse de los errores o vulnerabilidades de la Aplicación Móvil y/o SitioWeb. Si el Usuario detecta o se da cuenta de algún error o vulnerabilidad en la Aplicación Móvil y/oSitio Web: a) se abstendrá de aprovecharse de ellos en modo alguno; b) mantendrá estrictaconfidencialidad de ese error o vulnerabilidad y; c) deberá notificar a La Empresa sobre el errorencontrado   mediante   un   mensaje   enviado   al   correo   electrónico
              {' '}
              <a href="mailto:reclamaciones@picap.co">reclamaciones@picap.co</a>
              . Sin perjuicio de otros recursos disponibles en el marco de la ley o del principio de equidad, si el Usuariono respeta esta disposición, La Empresa tendrá derecho a la plena compensación de todos loscostes que puedan deberse al error o vulnerabilidad y de los daños y perjuicios resultantes delincumplimiento de esta disposición por parte del Usuario.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              12. Fallas técnicas externas
            </b>
            <br />

            <p>
              La Empresa no garantiza la disponibilidad y continuidad del funcionamiento de la Aplicación Móvily/o Sitio Web cuando la falla de esta se deba a factores que se salen totalmente del control de LaEmpresa,   tales   como   averías   telefónicas,   desconexiones   del   servicio   de   internet   por   causasatribuibles al proveedor del servicio, retrasos o bloqueos causados por deficiencias o sobrecargasen   las   líneas   telefónicas,   centros   de   datos,   en   el   sistema   de   Internet   o   en   otros   sistemaselectrónicos, producidos en el curso de su funcionamiento, y otros daños que puedan ser causadospor terceros mediante intromisiones no autorizadas ajenas al control de La Empresa.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              13. Condiciones de Pago y Facturación
            </b>
            <br />

            <p>
              El Usuario Consumidor entiende y acepta que la adquisición de los Productos de un UsuarioPrestador genera cargos (“los Cargos”). Después de que haya recibido los Productos mediante eluso de la Aplicación Móvil y/o Sitio Web, La Empresa, como agente de cobro, le facilitará el pago delos Cargos aplicables en nombre del Usuario Prestador. El pago de los Cargos de dicha manera seconsiderará como el pago efectuado directamente por el Usuario Consumidor al Usuario Prestador.
            </p>
            <p>
              Todos los Cargos son pagaderos inmediatamente y el pago se facilitará por la Aplicación Móvil y/oSitio Web utilizando el método de pago preferido indicado en su cuenta de Usuario. Si se determinaque el método de pago de su cuenta ha caducado, es inválido o de otro modo no sirve paracobrarle, usted acepta que La Empresa, como agente de cobro del Usuario Prestador, utilice unmétodo de pago secundario en su cuenta, si estuviera disponible.
            </p>
            <p>
              Los Cargos pagados por el Usuario Consumidor son definitivos y no reembolsables, a menos queLa Empresa determine lo contrario. Los Cargos incluirán los impuestos aplicables cuando serequiera por ley, así mismo, pueden incluir, peajes y/o recargos aplicables, cargos por demoras otarifas de procesamiento por pagos fraccionados, entre otros que sean aplicables.
            </p>
            <p>
              De igual forma, el Usuario Prestador entiende y acepta que el acceso y uso de la Aplicación Móvily/o Sitio Web puede generar cargos. Después de que haya suministrado los Productos mediante eluso de la Aplicación Móvil y/o Sitio Web, La Empresa le enviará al correo electrónico verificado delUsuario Prestador la respectiva factura con el resumen de los cargos aplicables por el terceroproveedor como agente de cobro independiente (SOFTLAB S.A.S.), bajo la periodicidad que LaEmpresa determine. Los cargos incluirán los impuestos aplicables cuando se requiera por ley. Loscargos pagados por el Usuario Prestador son definitivos y no reembolsables, a menos que LaEmpresa determine lo contrario.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              14. Indemnidad
            </b>
            <br />

            <p>
              Con la suscripción de los presentes Términos y Condiciones, los usuarios se obligan a mantenerindemne a La Empresa frente a cualquier reclamación contra esta, su sociedad matriz, directores,socios, empleados, abogados y agentes, derivada de: a) el incumplimiento por parte del Usuario decualquier disposición contenida en los presentes Términos y Condiciones o de cualquier ley oregulación aplicable a los mismos, b) el incumplimiento o violación de los derechos de terceros; c)cualquier daño y/o perjuicio causado por el Usuario y/o sus acompañantes como consecuencia dela adquisición de los Productos a otro usuario y/o tercero y; d) el incumplimiento del uso permitidode la Aplicación Móvil y/o Sitio Web.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              15. Derechos de Propiedad Intelectual e Industrial
            </b>
            <br />

            <p>
              El Usuario reconoce y acepta que todos los derechos de propiedad intelectual e industrial sobre laAplicación Móvil y/o Sitio Web y/o cualesquiera otros elementos insertados en esta (incluyendo, sinlimitación, marcas, logotipos, nombres comerciales, textos, imágenes, gráficos, diseños, sonidos,bases de datos, software, diagramas de flujo, presentación, audio y vídeo), pertenecen única yexclusivamente a La Empresa.
            </p>
            <p>
              La Empresa autoriza al Usuario a utilizar, visualizar, imprimir, descargar y almacenar la AplicaciónMóvil y/o Sitio Web y/o los elementos insertados en esta exclusivamente para su uso personal,privado   y   no   lucrativo,   absteniéndose   de   realizar   sobre   los   mismos   cualquier   acto   dedescompilación, ingeniería inversa, modificación, divulgación o suministro. Cualquier otro uso oexplotación de otros elementos insertados en la Aplicación Móvil y/o Sitio Web distinto de los aquíexpresamente previstos estará sujeto a la autorización previa de La Empresa.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              16. Política de Tratamiento de Datos Personales
            </b>
            <br />

            <p>
              La recopilación y el uso que hacemos de la información personal en la Aplicación Móvil y/o SitioWeb es conforme se dispone en la Política de Tratamiento de Datos Personales de La Empresa y elAviso de Privacidad, disponible en el sitio web.
            </p>
            <p>
              El Usuario autoriza a La Empresa a facilitarle a cualquier tercero afectado, Usuario, apoderado,aseguradora o autoridad judicial todo tipo información (incluidos datos personales) en caso dequejas, reclamos, controversias o litigios en el que el Usuario se encuentre implicado.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              17. Política de Cookies
            </b>
            <br />

            <p>
              Con la aceptación de los presentes Términos y Condiciones y por el propio hecho de utilizar laAplicación Móvil y/o Sitio Web, usted acepta que La Empresa utilice cookies para: a) identificar elidioma preferido del usuario con el fin de poder seleccionarlo automáticamente cuando este regresea la Aplicación Móvil y/o Sitio Web; b) analizar el tráfico de la Aplicación Móvil y/o Sitio Web con elobjetivo que La Empresa pueda realizar las mejoras oportunas.
            </p>
            <p>
              Le solicitamos que tenga en cuenta que no se puede utilizar la Aplicación Móvil y/o Sitio Web sincookies. Si requiere información adicional sobre el uso que hace La Empresa de las cookies, envíeun mensaje al correo electrónico
              {' '}
              <a href="mailto:reclamaciones@picap.co.">reclamaciones@picap.co.</a>
              .
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              18. Cesión
            </b>
            <br />

            <p>
              El Usuario no podrá ceder sus derechos y obligaciones de los presentes Términos y Condicionessin el previo consentimiento escrito de La Empresa.
            </p>
            <p>
              La Empresa podrá ceder, sin necesidad del consentimiento previo del Usuario, los presentesTérminos y Condiciones a cualquier sociedad comprendida dentro de su grupo empresarial, en todoel mundo, así como a cualquier persona natural o jurídica que le suceda en el ejercicio de sunegocio por cualquiera título.
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              19. Modificaciones
            </b>
            <br />

            <p>
              La Empresa se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previoaviso, modificaciones respecto a la información contenida en la Aplicación Móvil y/o Sitio Web, asícomo sus Términos y Condiciones. Las modificaciones que se hagan producirán efecto en formainmediata a su incorporación en el Sitio Web
              {' '}
              <a href="https://piboxenterprise.picap.app" target="_blank" rel="noopener noreferrer">https://piboxenterprise.picap.app</a>
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              20. Notificaciones
            </b>
            <br />

            <p>
              La Empresa podrá realizar las notificaciones oportunas a través de la Aplicación Móvil, del correoelectrónico facilitado por el Usuario en el formulario de registro, mediante SMS, WhatsApp o através de correo postal a la dirección física facilitada por el Usuario en el formulario de registro. ElUsuario,   a   su   vez   podrá   cursar   notificaciones   a   La   Empresa   a   través   del   correoelectrónico
              {' '}
              <a href="mailto:reclamaciones@picap.co">reclamaciones@picap.co</a>
              .
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-justify">
            <b className="text-left bold light-purple text-justify">
              21. Ley aplicable y jurisdicción
            </b>
            <br />

            <p>
              Los presentes Términos y Condiciones, así como la relación entre La Empresa y el Usuario, seregirán e interpretarán de conformidad con las leyes de Colombia. Las partes acuerdan someterse ala jurisdicción ordinaria de Colombia para la interpretación o controversia derivada de los presentesTérminos y Condiciones o la relación entre las mismas
            </p>
          </Typography>
          <br />
          <br />

          <Typography variant="subtitle1" className="text-center">
            <i>¡Gracias por visitarnos, agradecemos su interés en nuestros Servicios y le damos la bienvenida!</i>
          </Typography>

        </Paper>
      </Container>
    </div>
  );
}

export default Terms;
