import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Snackbar,
  withStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import copy from 'copy-to-clipboard';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import { useStateValue } from 'context/store';
import {
  listOrders,
  getEvidence,
  // updateStatus,
  listDriversLocation,
  cancelTask,
  getJobsTask,
} from 'context/orders/actions';
import { I18nContext } from 'translations';
import { messageConfirmation } from 'utils';
import PaperBlock from 'components/PaperBlock';
import ServiceTable from 'components/Tables/ServiceTable';
import Loading from 'components/Loading';
import ServicesMap from 'components/Maps/ServicesMap';
import Evidences from './Evidences';
import styles from './style';
import ListAccordionOrders from './components/ListAccordionOrders/ListAccordionOrders';
import Filters from './Filters';
import FiltersAccordion from './components/ListAccordionOrders/components/FiltersAccordion/FiltersAccordion';

function Orders({ classes }) {
  const [checkList, setCheckList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [openEvidence, setOpenEvidence] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [per_page] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedTask, setSelectedTask] = useState();
  const unmounted = useRef(false);
  const [{ orders }, dispatch] = useStateValue();
  const { langCode, translate } = useContext(I18nContext);
  // const [{ orders, checkin }, dispatch] = useStateValue();

  const handleClose = () => setMessage(null);
  const handleEvidence = (info) => {
    getEvidence(dispatch, info);
    setOpenEvidence(!openEvidence);
  };

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const getOrders = async () => {
    setLoading(true);
    const callback = await listOrders(
      dispatch,
      { page, per_page, ...filterParams },
      langCode,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
    unmounted.current = false;
  };

  const getOrdersAccordion = async () => {
    setLoading(true);
    const callback = await getJobsTask(
      dispatch,
      { page, per_page, ...filterParams },
      langCode,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
    unmounted.current = false;
  };

  const shareLink = (trackingLink) => {
    copy(trackingLink);
    handleMessage(`${translate('copiedClipboard')} ${trackingLink}`);
  };

  // const getUpdateStatus = () => {
  //   checkin &&
  //     checkin.organization &&
  //     updateStatus(dispatch, checkin.organization._id);
  // };

  const getDrivers = async () => {
    const callback = await listDriversLocation(dispatch, langCode);
    !callback.status && handleMessage(callback.message);
    unmounted.current = false;
  };

  const getTasks = useCallback(async () => {
    let callback = null;
    if (checkList) {
      callback = await getJobsTask(
        dispatch,
        { page, per_page, ...filterParams },
        langCode,
      );
      !callback.status && handleMessage(callback.message);
    } else {
      callback = await listOrders(
        dispatch,
        { page, per_page, ...filterParams },
        langCode,
      );
    }
    !callback.status && handleMessage(callback.message);
    unmounted.current = false;
  }, [page, per_page, filterParams, dispatch, langCode, checkList]);

  const handleCancel = async (id) => {
    messageConfirmation({
      translate,
      title: 'task.cancel.title',
      text: 'task.cancel.description',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setLoading(true);
        const callback = await cancelTask(dispatch, id, langCode);
        callback.status
          ? setTimeout(() => {
            setLoading(false);
          }, 3000)
          : handleMessage(callback.message);
      }
    });
  };

  const handleChangeSelectedTask = (e, task) => {
    e.stopPropagation();
    setSelectedTask({
      id: task._id,
      lat: task.lat,
      lon: task.lon,
      external_id: task.external_id,
      route: task.route_id,
    });
  };

  const toggleFilters = () => {
    if (openFilters) {
      setFilterParams({});
    }
    setOpenFilters(!openFilters);
  };

  const onFilter = (params) => {
    setFilterParams(params);
  };

  useEffect(() => {
    checkList ? getOrdersAccordion() : getOrders();
    getDrivers();
    getTasks();
    return () => {
      unmounted.current = true;
    };
  }, [langCode, dispatch]); // eslint-disable-line

  // useEffect(() => {
  //   const unsubscribe = getUpdateStatus();
  //   return unsubscribe && unsubscribe();
  // }, [checkin]); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      getDrivers();
    }, 5500);
    return () => clearInterval(interval);
  }, [orders.drivers]); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      getTasks();
    }, 5000);
    return () => clearInterval(interval);
  }, [orders.list, getTasks]);

  // Filter effect
  useEffect(() => {
    if (page !== 1) setPage(1);
    else {
      checkList ? getOrdersAccordion() : getOrders();
    }
  }, [filterParams]); //eslint-disable-line

  // Change page effect
  useEffect(() => {
    checkList ? getOrdersAccordion() : getOrders();
  }, [page]); //eslint-disable-line

  useEffect(() => {
    checkList ? getOrdersAccordion() : getOrders();
  }, [checkList]); //eslint-disable-line

  const listViewChange = () => {
    setCheckList(!checkList);
    setFilterParams({});
    setPage(1);
  };

  const handleChangePage = (type) => {
    setPage(type === 'inc' ? page + 1 : page - 1);
  };

  return (
    <PaperBlock border="0" padding="0">
      {loading && <Loading />}

      <div className={classes.containerMap}>
        {orders.list && orders.drivers ? (
          <ServicesMap
            drivers={orders.drivers || []}
            tasks={orders.list.data || []}
            cancelTask={orders.cancelTask || null}
            cancelDriver={
              orders.cancelDriver.length > 0
                ? orders.cancelDriver[0].driver._id
                : null
            }
            handleChangeSelectedTask={handleChangeSelectedTask}
            selectedTask={selectedTask}
          />
        ) : (
          <Typography variant="h5" className="title text-center">
            {translate('loading')}
          </Typography>
        )}
      </div>

      <div className={classes.containerBody}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={6} sm={6}>
            <Grid item container md={12} xs={12} sm={12}>
              <Typography variant="h4" className={`${classes.title} text-left`}>
                {translate('task.listTasks')}
              </Typography>
            </Grid>
            <Grid item container md={12} xs={12} sm={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={checkList}
                    onChange={() => listViewChange()}
                    name="checkedA"
                  />
                )}
                label={translate('task.groupTasks')}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={6}
            xs={6}
            sm={6}
            justify="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                type="button"
                color="primary"
                size="large"
                startIcon={openFilters ? <CloseIcon /> : <FilterListIcon />}
                onClick={toggleFilters}
              >
                {openFilters ? translate('clear') : translate('filter')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                color="primary"
                variant="contained"
                component={Link}
                size="large"
                startIcon={<AddCircleOutlineIcon />}
                to="/app/orders/new-order"
              >
                {translate('task.addTask')}
              </Button>
            </Grid>
          </Grid>
          {openFilters && (
            <Grid item xs={12} className="text-right text-center-sm">
              {!checkList ? (
                <Filters onFilter={onFilter} />
              ) : (
                <FiltersAccordion onFilter={onFilter} />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {!checkList
              ? orders.list && (
              <ServiceTable
                data={orders.list}
                page={page}
                recordsPerPage={per_page}
                shareLink={shareLink}
                handleEvidence={handleEvidence}
                handleCancel={handleCancel}
                onChangePage={setPage}
                handleChangeSelectedTask={handleChangeSelectedTask}
                selectedTask={selectedTask}
              />
              )
              : orders.groupedList && (
              <ListAccordionOrders
                data={orders.groupedList}
                page={page}
                recordsPerPage={per_page}
                shareLink={shareLink}
                selectedTask={selectedTask}
                handleEvidence={handleEvidence}
                handleCancel={handleCancel}
                handleChangePage={handleChangePage}
                handleChangeSelectedTask={handleChangeSelectedTask}
              />
              )}
            {openEvidence && (
              <Evidences
                open={openEvidence}
                handleClose={handleEvidence}
                evidence={orders.evidence}
                translate={translate}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

export default withStyles(styles)(Orders);
