import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  withStyles,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import TimerIcon from '@material-ui/icons/Timer';
import PropTypes from 'prop-types';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { modalMessage } from 'utils';
import { createJobsOptimizedRoutes } from 'context/routes/actions';
import OptimizedRoutesTable from 'components/Tables/Routes/OptimizedRoutes';
import Loading from 'components/Loading';
import Drivers from 'components/Modals/Drivers';

import Pagination from 'components/Paginations';
import styles from './listRouteStyle';

function ListRoutes({
  routes,
  unassigned,
  history,
  handleChangePage,
  page,
  perPage,
  loadingPage,
  handleChangeSelectedRoutes,
  selectedTask,
  handleChangeSelectedTask,
  classes,
  onCancelTask,
}) {
  const [, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);
  const [routeList, setRouteList] = useState([]);
  const [jobs, setJobs] = useState([...routes.jobs] || []);
  const [selectAll, setSelectAll] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [expandedRoute, setExpandedRoute] = useState(null);
  const handleClose = () => setMessage(null);

  const handleDriver = (e) => {
    e.stopPropagation();
    routeList.length > 0
      ? setOpenDriver(!openDriver)
      : setMessage('routes.selectAroute');
  };

  const assignDriver = (selectDriver) => {
    if (page > 0) {
      routeList.map((t) => {
        jobs[t.index] = {
          driver_id: selectDriver._id,
          name: selectDriver.name,
          job_id: routes.jobs[t.index]._id,
        };
        return setJobs(jobs);
      });
    } else {
      routeList.map((t) => {
        jobs[t.index] = {
          driver_id: selectDriver._id,
          name: selectDriver.name,
          ...routes.jobs[t.index],
        };
        return setJobs(jobs);
      });
    }
    setOpenDriver(false);
  };

  const handleCheck = (e, index) => {
    e.stopPropagation();
    const id = e.target.value;
    if (e.target.checked) {
      setRouteList(routeList.concat({ id, index }));
    } else {
      setRouteList(routeList.filter((item) => item.id !== id));
      setSelectAll(false);
    }
  };

  const handleSelectAll = (e, index) => {
    setSelectAll(!selectAll);
    e.target.checked
      ? setRouteList(jobs.map((job) => ({ id: job._id, index })))
      : setRouteList([]);
  };

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    if (page > 0 && page < parseInt(routes.pages, 10)) {
      modalMessage(
        translate('congratulations'),
        translate('routes.messageAssignDriverOk'),
        'success',
        translate('continue')
      );
    } else {
      modalMessage(
        translate('congratulations'),
        translate('task.taskSuccessfullyCreated'),
        'success',
        translate('continue')
      ).then(() => {
        history.push('/app');
      });
    }
  };

  const createService = async () => {
    setLoading(false);
    const callback = await createJobsOptimizedRoutes(
      dispatch,
      page > 0 ? { data: jobs } : { jobs },
      page > 0 ? 'file' : ''
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  const driverName = (route, index) =>
    route.driver ? route.driver.name : jobs[index].name ? jobs[index].name : '';

  useEffect(() => {
    setJobs([...routes.jobs] || []);
  }, [routes]);

  const scrollToTask = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (selectAll) {
      setRouteList(jobs.map((job, index) => ({ id: job._id, index })));
    }
  }, [jobs, selectAll]);

  useEffect(() => {
    handleChangeSelectedRoutes && handleChangeSelectedRoutes(routeList);
  }, [routeList, handleChangeSelectedRoutes]);

  useEffect(() => {
    if (routes.jobs.length) {
      if (!selectedTask) {
        setExpandedRoute(routes.jobs[0]._id);
      } else {
        let index = routes.jobs.findIndex(
          (route) => route._id === selectedTask.route
        );
        if (index < 0) index = 0;
        const id = routes.jobs[index]._id;
        setExpandedRoute(id);
        setRouteList([{ id, index }]);
        setSelectAll(false);
        scrollToTask(selectedTask.id);
      }
    }
  }, [selectedTask, routes.jobs]);

  const handleAccordion = (routeId) => (event, isExpanded) => {
    setExpandedRoute(isExpanded ? routeId : null);
  };

  if (loading || loadingPage) return <Loading />;

  return (
    <div>
      {!routes.jobs.length && (
        <Box mb={4}>
          <Typography variant="body2">
            {translate('routes.optimizedRoutesNotFound')}
          </Typography>
        </Box>
      )}
      {routes.jobs.length > 0 && (
        <Box ml={1} display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            }
            label={translate('task.selectAll')}
          />
          <Box display="flex">
            <Box mr={2}>
              <Button
                color="secondary"
                size="large"
                onClick={() => onCancelTask()}
              >
                {translate('task.cancelTask')}
              </Button>
            </Box>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={(e) => handleDriver(e)}
              className={classes.selectDriverButton}
              disabled={!routeList.length}
            >
              {translate('task.selectDriver')}
            </Button>
          </Box>
        </Box>
      )}

      {routes.jobs.length > 0 &&
        routes.jobs.map((route, index) => (
          <Accordion
            key={index.toString()}
            className={classes.containerAccordion}
            expanded={route._id === expandedRoute}
            onChange={handleAccordion(route._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.titleAccordion}
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.routeCheckbox}
                control={
                  <Checkbox
                    checked={routeList.some((item) => item.id === route._id)}
                    onChange={(e) => handleCheck(e, index)}
                    disabled={route.driver && true}
                    value={route._id}
                  />
                }
                label={
                  <Grid container spacing={1}>
                    <Grid item lg={5} xs={12}>
                      <b>
                        {`${translate('routes.optimizedRoute')} # ${
                          page === 0
                            ? index + 1
                            : (page - 1) * perPage + index + 1
                        }`}
                      </b>
                    </Grid>
                    <Grid item lg={7} xs={12}>
                      <Box display="flex" ml={-0.3}>
                        {route.estimated_transport_time && (
                          <Tooltip
                            title={translate('routes.estimatedTransportTime')}
                            placement="top"
                            arrow
                            enterTouchDelay={1}
                          >
                            <span className={classes.routeTime}>
                              <TimerIcon />
                              &nbsp;
                              {route.estimated_transport_time}
                            </span>
                          </Tooltip>
                        )}
                        &nbsp;&nbsp;
                        {route.estimated_distance && (
                          <Tooltip
                            title={translate('routes.estimatedDistance')}
                            placement="top"
                            arrow
                            enterTouchDelay={1}
                          >
                            <span className={classes.routeTime}>
                              <SettingsEthernetIcon />
                              &nbsp;
                              {route.estimated_distance}
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {driverName(route, index) ? (
                        <span className={classes.driverName}>
                          Conductor asignado:&nbsp;
                          {driverName(route, index)}
                        </span>
                      ) : (
                        <span className={classes.noDriver}>
                          {translate('routes.withoutDriver')}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <OptimizedRoutesTable
                route={route}
                tasks={route.tasks || []}
                selectedTask={selectedTask}
                handleChangeSelectedTask={handleChangeSelectedTask}
              />
            </AccordionDetails>
          </Accordion>
        ))}

      {routes.jobs.length > 0 && (
        <>
          {page > 0 && (
            <div className="text-right">
              <Pagination
                handleChangePage={handleChangePage}
                page={page}
                perPage={perPage}
                totalPages={parseInt(routes.pages, 10)}
              />
            </div>
          )}
          <div className="text-center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              onClick={createService}
            >
              {translate('routes.createServices')}
            </Button>
          </div>
        </>
      )}

      {openDriver && (
        <Drivers
          open={openDriver}
          handleClose={handleDriver}
          translate={translate}
          assignDriver={assignDriver}
        />
      )}

      {unassigned && unassigned.length > 0 && (
        <>
          <Typography variant="h6" className="title text-left status-failed">
            {translate('routes.nonOptimizedRoutes')}
          </Typography>
          <OptimizedRoutesTable
            tasks={unassigned || []}
            selectedTask={selectedTask}
            handleChangeSelectedTask={handleChangeSelectedTask}
          />
        </>
      )}
      <Snackbar
        open={message && true}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
      />
    </div>
  );
}

ListRoutes.propTypes = {
  history: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func,
  perPage: PropTypes.number,
};

export default withStyles(styles)(ListRoutes);
