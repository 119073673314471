import { CREATE_SESSION, ASSIGN_PASSWORD, CLOSE_SESSION } from './constants';

const sessionReducer = (state, action) => {
  switch (action.type) {
    case CREATE_SESSION:
      localStorage.setItem('session_token', action.session);
      return {
        ...state,
        session: action.session
      }
    case CLOSE_SESSION:
      localStorage.clear();
      return {
        ...state,
        session: action.session,
      };
    case ASSIGN_PASSWORD: 
      return {
        ...state,
        session: action.session
     }
    default:
      return state
  }
}

export default sessionReducer;
