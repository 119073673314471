export const TYPE_CD = [
  { _id: 0, name: 'typeCd.pickup', select: true },
  { _id: 1, name: 'typeCd.delivery', select: true },
  { _id: 2, name: 'typeCd.return', select: false },
];

export const TASK_STATUSES = {
  0: 'taskStatus.unassigned', // unassigned
  1: 'taskStatus.assigned', // assigned
  2: 'taskStatus.accepted', // accepted
  3: 'taskStatus.started', // started
  4: 'taskStatus.inProgress', // in_progress
  5: 'taskStatus.successful', // successful
  6: 'taskStatus.failed', // failed
  7: 'taskStatus.declined', // declined
  8: 'taskStatus.cancelled', // cancelled
  9: 'taskStatus.paused', // paused
};

export const ACCOUNT_STATUSES = {
  0: 'accountStatuses.invited',
  1: 'accountStatuses.accepted',
};

export const BULK_LOAD_STATUSES = {
  0: 'bulkLoad.status.processing',
  1: 'bulkLoad.status.succeed',
  2: 'bulkLoad.status.failed',
  3: 'bulkLoad.status.cancelled',
};

export const OPTIMIZATION_VEHICLE_TYPES = [
  { _id: 0, name: 'vehicleTypes.car' },
  { _id: 1, name: 'vehicleTypes.bike' },
  { _id: 2, name: 'vehicleTypes.foot' },
  { _id: 3, name: 'vehicleTypes.hike' },
  { _id: 4, name: 'vehicleTypes.mtb' },
  { _id: 5, name: 'vehicleTypes.racingBike' },
  { _id: 6, name: 'vehicleTypes.scooter' },
  { _id: 7, name: 'vehicleTypes.truck' },
  { _id: 8, name: 'vehicleTypes.smallTruck' },
];

export const OPTIMIZATION_TYPES = [
  { _id: 0, name: 'optimizationTypes.distance' },
  { _id: 1, name: 'optimizationTypes.time' },
];

export const INDUSTRY_TYPES = [
  { _id: 1, name: 'industryTypes.eCommerce' },
  { _id: 2, name: 'industryTypes.flowers' },
  { _id: 3, name: 'industryTypes.food' },
  { _id: 4, name: 'industryTypes.grocery' },
  { _id: 5, name: 'industryTypes.alcohol' },
  { _id: 6, name: 'industryTypes.documents' },
  { _id: 7, name: 'industryTypes.electronics' },
  { _id: 8, name: 'industryTypes.clothing' },
  { _id: 9, name: 'industryTypes.pharmacy' },
  { _id: 10, name: 'industryTypes.gifts' },
  { _id: 11, name: 'industryTypes.courier' },
  { _id: 12, name: 'industryTypes.services' },
  { _id: 13, name: 'industryTypes.other' },
];

export const COUNTRIES = ['mx', 'co', 'br', 'pe', 'ec', 'gt', 'cl', 'pa', 'us'];

export const ADDONS = {
  routeOptimization: 'route_optimization',
};

export const ROLES = {
  admin: 0,
  operator: 1,
  driver: 2,
};

export const MAP_COLORS = [
  '#CD5D67',
  '#006C67',
  '#310A31',
  '#007CBE',
  '#F0803C',
  '#BB9F06',
  '#14591D',
  '#60463B',
  '#E3655B',
  '#334139',
];

export const AUTOMATIC_ASSIGN_TYPES = [
  { _id: 0, name: 'routes.automaticAssign.none' },
  { _id: 1, name: 'routes.automaticAssign.all' },
  { _id: 2, name: 'routes.automaticAssign.active' },
  { _id: 3, name: 'routes.automaticAssign.schedule' },
];

export const WEEKDAYS_CD = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
