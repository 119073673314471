import React, { useState, useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { bulkLoad } from 'context/bulkLoad/actions';
import { uploadFile } from 'utils/aws';
import { modalMessage } from 'utils';
import BulkLoad from 'components/BulkLoad';

const TasksBulkLoad = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [invalidRows, setInvalidRows] = useState(null);
  const { translate } = useContext(I18nContext);
  const [{ checkin }, dispatch] = useStateValue();

  const columns = useMemo(() => [
    {
      name: 'id',
      value: (row) => row.external_id,
      error: (row) => row.errors && row.errors.external_id,
    },
    {
      name: 'address',
      value: (row) => row.address,
      error: (row) => row.errors && row.errors.address,
    },
    {
      name: 'description',
      value: (row) => row.description,
      error: (row) => row.errors && row.errors.description,
    },
    {
      name: 'customer',
      value: (row) => row.customer.name,
      error: (row) => row.errors && row.errors.customer_name,
    },
    {
      name: 'countryCode',
      value: (row) => row.customer.phone_country_code,
      error: (row) => row.errors && row.errors.customer_phone_country_code,
    },
    {
      name: 'customerPhone',
      value: (row) => row.customer.phone_number,
      error: (row) => row.errors && row.errors.customer_phone_number,
    },
    {
      name: 'customerEmail',
      value: (row) => row.customer.email,
      error: (row) => row.errors && row.errors.customer_email,
    },
    {
      name: 'driverEmail',
      value: (row) => row.customer.driver_email,
      error: (row) => row.errors && row.errors.driver_email,
    },
    {
      name: 'type',
      value: (row) => row.type_cd,
      error: (row) => row.errors && row.errors.type_cd,
    },
  ], []);

  const alertMessage = (msg) => {
    setLoading(false);
    setMessage(msg);
  };

  const modalMsg = (invalidJobs) => {
    setLoading(false);

    if (invalidJobs.length) {
      setInvalidRows(invalidJobs);
      return;
    }

    modalMessage(
      translate('bulkLoad.fileUploadedSuccessfully'),
      translate('bulkLoad.tasksCreatedSuccessfully'),
      'success',
      translate('continue'),
    ).then(() => history.push('/app'));
  };

  const handleUpload = async (file) => {
    setLoading(true);
    const date = Date.parse(new Date());
    const response = await uploadFile(file[0], `uploads/task/bulk_import/${date}`);
    if (response) {
      const callback = await bulkLoad(dispatch, { tasks_file_url: response.Location });
      callback.status ? modalMsg(callback.invalidJobs) : alertMessage(translate(callback.message));
    } else {
      alertMessage(translate('error.OppsAnErrorHasOccurredTryAgainLater'));
    }
  };

  return (
    <BulkLoad
      title="task.addTasks"
      format="/files/formatoTasksBulkLoad.xlsx"
      handleUpload={handleUpload}
      loading={loading}
      invalidRows={invalidRows}
      handleCloseInvalidRows={() => setInvalidRows(false)}
      columns={columns}
      rowId="external_id"
      message={message}
      handleCloseMessage={() => setMessage(null)}
      lastFormatUpdate={checkin.checkin && checkin.checkin.bulk_tasks_file_updated_at}
      formatKey="bulk_tasks_file_readed"
    />
  );
};

export default withRouter(TasksBulkLoad);
