import platform from 'platform';

const device_metadata = {
  os: platform.os.family || 'Unknown',
  lang: 'es',
  brand: platform.name,
  model: platform.version,
  os_version: platform.os.version || '1.0',
  app_version: '2.0'
};

export default device_metadata;
