import { createStyles } from '@material-ui/core/styles';

const styles = createStyles(() => ({
  containerMap: {
    width: '100%',
    height: '320px',
    overflow: 'hidden',
  },
  containerBody: {
    padding: 15,
  },
  filterContainer: {
    margin: '12px 0',
    padding: 12,
    paddingTop: 24,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: '#f9fafb',
  },
  filterIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  title: {
    fontWeight: 'bold',
    color: '#4a4a4a',
    marginBottom: 10,
  },
}));

export default styles;
