import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
    borderCollapse: 'initial',
    borderSpacing: '0 7px',
    '& thead': {
      backgroundColor: '#f9fafb',
      '& tr th': {
        fontWeight: 'bold',
        lineHeight: 1.1,
      },
    },
    '& tbody tr': {
      '& td': {
        padding: '8px',
        fontSize: '0.82em',
        color: '#000',
        [theme.breakpoints.down('sm')]: {
          padding: '5px 0',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& thead': {
        display: 'none',
      },
      '& tbody tr': {
        '& td': {
          width: '100% !important',
          textAlign: 'center',
          borderBottom: 0,
          padding: '5px',
          display: 'inline-block',
        },
        '& td:first-child': {
          paddingTop: '15px',
        },
        '& td:last-child': {
          paddingBottom: '15px',
        },
      },
    },
  },
  tableContainer: {
    padding: '10px 0',
    overflow: 'auto',
  },
  tableResponsive: {
    [theme.breakpoints.up('md')]: {
      marginRight: '-330px',
      overflow: 'auto',
      width: '100%',
    },
  },
  textOverflow: {
    width: '100px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    margin: 'auto',
  },
  address: {
    wordBreak: 'break-word',
    margin: 'auto',
  },
}));

export default styles;
