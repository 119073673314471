import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { Language } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './Styles';
import MenuMobile from './MenuMobile';

const Header = ({ classes, menuActive = 0 }) => {
  const { translate } = useContext(I18nContext);
  const [optionsSubMenu, setOptionsSubMenu] = useState(null);
  const sections = [
    { id: 'home-start', label: 'landing.menu.option.1', src: '/', options: [] },
    {
      id: 'order-features',
      label: 'landing.menu.option.2',
      options: [
        { label: 'landing.menu.option.4.submenu.1', src: '/routing' },
        { label: 'landing.menu.option.4.submenu.2', src: '/client' },
        { label: 'landing.menu.option.4.submenu.3', src: '/optimization' },
      ],
    },
    {
      id: 'boost-fleet',
      label: 'landing.menu.option.4',
      src: '/videos',
      options: [],
    },
    {
      id: 'fleet-features',
      label: 'landing.menu.option.3',
      src: null,
      options: [],
    },
  ];

  const subMenu = (options) => (
    <Menu
      anchorEl={optionsSubMenu}
      keepMounted
      open={Boolean(optionsSubMenu)}
      onClose={() => setOptionsSubMenu(null)}
    >
      {options.map((menu, index) => (
        <MenuItem key={`submenu-${index}`} to="/routing" value={index}>
          <Link to={`${menu.src}`} style={{ textDecoration: 'none' }}>
            {translate(menu.label)}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );

  const buttonMenu = (indexMenu, item) => {
    const buttonSubmenu = item.options.length > 0;
    const props = buttonSubmenu
      ? {
          'aria-haspopup': 'true',
          onClick: (event) => setOptionsSubMenu(event.currentTarget),
        }
      : item.src === null
      ? {
          onClick: () =>
            (window.location.href = 'https://www.enviosylogistica.com'),
        }
      : {};

    const identifyButton = menuActive === indexMenu;
    return (
      <Fragment key={indexMenu}>
        {buttonSubmenu ? (
          <>
            <Button
              {...props}
              color={identifyButton ? 'primary' : 'default'}
              className={identifyButton ? classes.optionActive : null}
            >
              {translate(item.label)}
              <ExpandMoreIcon />
            </Button>
            {subMenu(item.options)}
          </>
        ) : item.src === null ? (
          <Button
            {...props}
            color={identifyButton ? 'primary' : 'default'}
            className={identifyButton ? classes.optionActive : null}
          >
            {translate(item.label)}
          </Button>
        ) : (
          <Link to={item.src} className={classes.link}>
            <Button
              color={identifyButton ? 'primary' : 'default'}
              className={identifyButton ? classes.optionActive : null}
            >
              {translate(item.label)}
            </Button>
          </Link>
        )}
      </Fragment>
    );
  };

  return (
    <AppBar position="sticky" color="default">
      <Toolbar variant="dense">
        <Link to="/" className={classes.containerLogo}>
          <img src="/logo.png" className={classes.logo} alt="logo" />
        </Link>
        <div className={classes.containerOptions}>
          {sections.map((section, index) => buttonMenu(index, section))}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonSession}
          disableElevation
        >
          <Link to="/login" className={classes.link}>
            {translate('logIn')}
          </Link>
        </Button>
        <Language />
        <MenuMobile sections={sections} translate={translate} />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
