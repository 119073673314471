import React, {
  useRef, useEffect, useState, useContext, useCallback,
} from 'react';
import mapboxgl from 'mapbox-gl';
import { I18nContext } from 'translations';
import { TASK_STATUSES } from 'utils/constants';
import { statusColor } from 'utils';
import polyline from '@mapbox/polyline';

mapboxgl.accessToken = process.env.REACT_APP_API_URL_BASE_MAPS_API_TOKEN;

function TrackingMap({ track }) {
  const mapContainerRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const { translate } = useContext(I18nContext);

  const fitBounds = useCallback((map) => {
    const bounds = [];

    if (track.lat && track.lon) {
      bounds.push([track.lon, track.lat]);
    }

    if (track.driver && track.driver.location) {
      bounds.push([track.driver.location.lon, track.driver.location.lat]);
    }

    if (track.events) {
      track.events.forEach((e) => {
        bounds.push(e.coordinates);
      });
    }

    const mapBounds = bounds.reduce(
      (bnd, coord) => bnd.extend(coord),
      new mapboxgl.LngLatBounds(bounds[0], bounds[0]),
    );

    if (mapBounds.length > 1) {
      map.fitBounds(mapBounds, {
        padding: 60,
        maxZoom: 15,
        duration: 2000,
      });
    }
  }, [track.lat, track.lon, track.driver, track.events]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 15,
      center: [track.lon, track.lat],
    });

    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', () => {
      map.loadImage('/images/destination_marker.png', (error, image) => {
        if (error) throw error;
        map.addImage('destination', image);

        map.addSource('point', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        });

        map.getSource('point').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [track.lon, track.lat],
              },
            },
          ],
        });

        map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'point',
          layout: {
            'icon-image': 'destination',
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          },
        });
      });

      map.loadImage('/images/pibox-delivery.png', (error, image) => {
        if (error) throw error;
        map.addImage('driver', image);

        map.addSource('drivers', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        });

        if (track.driver.location) {
          map.getSource('drivers').setData({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [
                    track.driver.location.lon,
                    track.driver.location.lat,
                  ],
                },
              },
            ],
          });
        }

        map.addLayer({
          id: 'driver',
          type: 'symbol',
          source: 'drivers',
          layout: {
            'icon-image': 'driver',
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          },
        });
      });

      fitBounds(map);

      setMapInstance(map);
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (mapInstance) {
      if (track.driver.location && mapInstance.getSource('drivers')) {
        mapInstance.getSource('drivers').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  track.driver.location.lon,
                  track.driver.location.lat,
                ],
              },
            },
          ],
        });
      }
    }
  }, [track, mapInstance]);

  // Show events effect
  useEffect(() => {
    if (mapInstance && track.events && track.events.length) {
      track.events.map((event) => {
        const el = document.createElement('div');
        el.innerHTML = `
        <svg height="20" width="20">
          <circle cx="10" cy="10" r="10" stroke="black" stroke-width="0" fill="${statusColor(
    event.to_state_cd,
  )}" opacity="0.9" />
        </svg>`;
        el.id = 'marker';

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          offset: 20,
        }).setText(translate(TASK_STATUSES[event.to_state_cd]));

        return new mapboxgl.Marker(el)
          .setLngLat(event.coordinates)
          .setPopup(popup)
          .addTo(mapInstance);
      });
    }
  }, [JSON.stringify(track.events), mapInstance]); // eslint-disable-line

  // Show route trackers and polyline effect
  useEffect(() => {
    if (mapInstance && track.polyline && Array.isArray(track.polyline)) {
      track.polyline.forEach((tracker, index) => {
        if (!tracker) return;

        const source = `track${track._id}-${index}`;
        const layer = `track${track._id}-${index}`;

        if (mapInstance.getLayer(layer)) mapInstance.removeLayer(layer);
        if (mapInstance.getSource(source)) mapInstance.removeSource(source);

        const polylineCoordinates = polyline
          .decode(tracker)
          .map((coord) => coord.reverse());

        mapInstance.addSource(source, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: polylineCoordinates,
            },
          },
        });

        mapInstance.addLayer({
          id: layer,
          type: 'line',
          source,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': statusColor(track.status_cd),
            'line-width': 2,
          },
        });
      });
    }
  }, [JSON.stringify(track.polyline), mapInstance]); //eslint-disable-line

  return (
    <div
      className="map-container"
      ref={mapContainerRef}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    />
  );
}

export default TrackingMap;
