import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, Paper, Grid,
  Typography, Checkbox, RadioGroup, TextField,
  Radio, Box, FormControlLabel, DialogContentText,
} from '@material-ui/core';
import { OPTIMIZATION_TYPES, AUTOMATIC_ASSIGN_TYPES } from 'utils/constants';
import moment from 'moment';
import styles from './style';

export default function OptimizedRouteModal({
  translate, open, handleClose, params,
}) {
  const classes = styles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="optimize-params-dialog-title"
      aria-describedby="optimize-params-dialog-description"
      maxWidth="lg"
    >
      <DialogContent>
        {!params && <DialogContentText>{translate('routes.params.empty')}</DialogContentText>}
        {params && (
          <>
            <Paper elevation={0} square className={classes.section}>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12} className="text-left">
                  <Typography variant="h6" className={classes.sectionTitle}>{translate('routes.params.basic')}</Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12} className="text-left">
                  <TextField
                    value={params.origin_address}
                    variant="outlined"
                    label={translate('routes.originAddress')}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className="text-left">
                  <TextField
                    value={params.init_date && moment(params.init_date).format('DD/MM/YYYY hh:mm:ss A')}
                    variant="outlined"
                    label={translate('routes.initDate')}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    label={translate('routes.numberOfVehicles')}
                    value={params.number_of_vehicles}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className="text-left">
                  <TextField
                    value={params.max_tasks_per_driver}
                    variant="outlined"
                    label={translate('routes.maximumNumberOfTasksPerDriver')}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} className="text-left">
                  <Checkbox
                    checked={!!params.minimize_number_of_vehicles_used}
                    disabled
                  />
                  <label>{translate('routes.minimizeNumberVehicles')}</label>
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    md={12}
                    sm={12}
                    xs={12}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Typography>{translate('routes.includeWeight')}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    sm={12}
                    xs={12}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Box mb={2}>
                      <Checkbox
                        checked={!!params.weight_optimization}
                        disabled
                      />
                      <label>
                        {`${translate(
                          'vehicles.weight',
                        )}`}
                      </label>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12} className="text-left">
                  <label>{translate('routes.typeOptimization')}</label>
                  <RadioGroup
                    className={classes.radioGroup}
                    value={params.optimization_type_cd ? params.optimization_type_cd.toString() : '0'}
                  >
                    {OPTIMIZATION_TYPES.map((ot) => (
                      <FormControlLabel
                        key={ot._id.toString()}
                        value={ot._id.toString()}
                        control={<Radio disabled />}
                        label={translate(ot.name)}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={0} square className={classes.section}>
              <Grid container spacing={3} justify="flex-start">
                <Grid item xs={12} className="text-left">
                  <Typography variant="h6" className={classes.sectionTitle}>{translate('routes.params.drivers')}</Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12} className="text-left">
                  <label>{translate('routes.automaticAssign')}</label>
                  <RadioGroup
                    className={classes.radioGroup}
                    value={params.automatic_assign_cd ? params.automatic_assign_cd.toString() : '0'}
                  >
                    {AUTOMATIC_ASSIGN_TYPES.map((t) => (
                      <FormControlLabel
                        key={t._id.toString()}
                        value={t._id.toString()}
                        control={<Radio disabled />}
                        label={translate(t.name)}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            </Paper>
      {params.automatic_assign_cd == 0 && ( //eslint-disable-line
        <Paper elevation={0} square className={classes.section}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} className="text-left">
              <Typography variant="h6" className={classes.sectionTitle}>{translate('routes.params.vehicles')}</Typography>
            </Grid>
            <Grid item xs={12} className="text-left">
              <TextField
                variant="outlined"
                label={translate('routes.vehicleType')}
                value={params.vehicle_profile ? `${params.vehicle_profile.name} - ${params.vehicle_profile.weight}kg.` : ''}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Paper>
      )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" size="large">
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
