import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import styles from './Styles';

const Features = ({ classes, title, items }) => {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container>
      <Grid container item justify="flex-start" alignItems="center">
        <Grid item sm={12} md={7}>
          <Box mt={5}>
            <Typography
              gutterBottom
              align="left"
              className={classes.subtitle}
              variant="h5"
            >
              <b>{translate(title)}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item justify="flex-start" alignItems="center">
        <Grid item sm={12} md={7}>
          {items.map((item, index) => (
            <Box key={`box-${index}`} mt={5} mb={5} display="flex">
              <img
                width="30"
                height="30"
                alt="routing-box"
                src={`images/landing/${item.img}.png`}
                style={{ marginRight: '10px' }}
              />
              <Typography
                gutterBottom
                align="left"
                className={classes.subtitle}
                variant="body1"
              >
                <b style={{ color: '#8029CF' }}>{translate(item.title)}</b>
                &nbsp;
                {translate(item.label)}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Features);
