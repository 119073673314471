import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  containerAccordion: {
    boxShadow: 'none',
    background: '#fbfbfb',
    marginBottom: '10px',
  },
  titleAccordion: {
    padding: '5px 15px',
  },
  driverName: {
    display: 'block',
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
  },
  noDriver: {
    display: 'block',
    fontSize: '0.95rem',
    color: '#f8b133',
  },
  routeTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontSize: '0.95rem',
    textAlign: 'center',
  },
}));

export default styles;
