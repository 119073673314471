import React, { useContext } from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';

const styles = createStyles((theme) => ({
  featureImgWeb1: {
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '280px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
  },
  featureImgWeb2: {
    height: '260px',
    [theme.breakpoints.down('sm')]: {
      height: '180px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '130px',
    },
  },
  featureMobile: {
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '400px',
    },
  },
  buttonRoute: {
    padding: '5px 20px',
    borderRadius: '20px',
    marginTop: '40px',
  },
}));

const Features = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  const cardFeature = (title, subtitle, image, route, classImg) => (
    <Grid item xs={12} sm={12} md={7}>
      <Box color="#333333">
        <Typography gutterBottom align="left" variant="h4">
          <b>{translate(title)}</b>
        </Typography>
        <Typography align="left" className="text-center">
          {translate(subtitle)}
        </Typography>
      </Box>
      <Box
        mt={2}
        mb={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          src={`images/landing/${image}`}
          alt="features-gif"
          className={classImg}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonRoute}
          href={route}
          disableElevation
        >
          {translate('landing.features.button')}
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item sm={12} md={12}>
        <Box mb={6} color="#8029CF">
          <Typography
            gutterBottom
            align="left"
            variant="h4"
            className="text-center"
          >
            <b>{translate('landing.features.title.1')}</b>
            <br />
            <b>{translate('landing.features.title.2')}</b>
          </Typography>
        </Box>
      </Grid>
      {cardFeature(
        'landing.features.subtitle.1',
        'landing.features.description.1',
        'feature-routes.png',
        '/routing',
        classes.featureImgWeb1
      )}
      {cardFeature(
        'landing.features.subtitle.2',
        'landing.features.description.2',
        'feature-phone.gif',
        '/client',
        classes.featureMobile
      )}
      {cardFeature(
        'landing.features.subtitle.3',
        'landing.features.description.3',
        'feature-dashboard.png',
        '/optimization',
        classes.featureImgWeb2
      )}
    </Grid>
  );
};

export default withStyles(styles)(Features);
