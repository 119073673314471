import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  container: {
    width: 380,
    padding: '40px 30px',
    borderRadius: 22,
    backgroundColor: 'white',
    position: 'fixed',
    top: '15%',
    right: '8%',
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      top: '12%',
      padding: '30px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 280,
      position: 'inherit',
      margin: '0 auto',
      marginBottom: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      width: 240,
      position: 'inherit',
      margin: '0 auto',
      marginBottom: '50px',
    },
  },
  button: {
    margin: '20px 0',
    borderRadius: 42,
    fontSize: '1.75rem',
    fontWeight: 'bold',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default styles;
