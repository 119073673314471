import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@material-ui/core';
import schema from './schema';

const styles = makeStyles(theme => ({
  marginBottomField: {
    marginBottom: '25px'
  },
  btn: {
    minWidth: '150px',
    padding: 10
  }
}));

function Form({ onSubmit, translate }) {
  const classes = styles();
  const { handleSubmit, register, errors } = useForm({
    validationSchema: schema
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        name="password"
        error={!!errors.password}
        label={translate('password')}
        helperText={errors.password && translate(errors.password.message)}
        type="password"
        variant="outlined"
        inputRef={register}
        autoComplete="no-password"
        fullWidth
        className={classes.marginBottomField}
      />
      <TextField
        name="password_confirmation"
        error={!!errors.password_confirmation}
        label={translate('confirmPassword')}
        helperText={errors.password_confirmation && translate(errors.password_confirmation.message)}
        type="password"
        variant="outlined"
        inputRef={register}
        autoComplete="no-password"
        fullWidth
        className={classes.marginBottomField}
      />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.btn}
      > 
        {translate('accept')}
      </Button>
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default Form;
