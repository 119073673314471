import React, {
  useState, useContext, useRef, useEffect, useCallback,
} from 'react';
import {
  List,
  Typography, Toolbar, IconButton, TextField, InputAdornment, CircularProgress,
  Box, Tooltip,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  queryMessages, newMessage, updateChat, deleteChat,
} from 'firebase/utils';
import moment from 'moment/moment';
import { I18nContext } from 'translations';

import Message from './Message';
import styles from './styles';
import EndChat from './EndChat';

function Conversation({ conversation, handleBack }) {
  const classes = styles();
  const { translate } = useContext(I18nContext);

  const [message, setMessage] = useState('');
  const [end, setEnd] = useState(false);
  const [ending, setEnding] = useState(false);
  const [messageList, loading] = useCollectionData(queryMessages(conversation.taskId));

  const list = useRef();
  const bottom = useRef();

  const scrollToBottom = useCallback(() => {
    if (list && list.current) {
      list.current.scrollTo({
        top: list.current.scrollHeight,
        left: 0,
        behavior: 'auto',
      });
    }
  }, [list]);

  // Scroll effect
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, messageList, end]);

  // Mark messages as read effect
  useEffect(() => {
    (async () => {
      await updateChat(conversation.taskId, {
        unreadOperator: 0,
      });
    })();
  }, [conversation.taskId]);

  const handleSend = async () => {
    if (!message.trim()) return;

    const text = message.slice();
    setMessage('');

    await newMessage(conversation.taskId, {
      sentByOperator: true,
      text,
    });

    await updateChat(conversation.taskId, {
      lastMessage: text,
      unreadDriver: conversation.unreadDriver + 1,
      unreadOperator: 0,
    });
  };

  const handleType = (event) => {
    if (event.key && event.key === 'Enter' && !event.shiftKey) {
      handleSend();
    } else if (event.target.value.trim()) {
      setMessage(event.target.value);
    } else {
      setMessage('');
    }
  };

  const handleFinish = async () => {
    setEnding(true);
    await deleteChat(conversation.taskId);
    handleBack();
  };

  return (
    <>
      <Toolbar className={classes.bar}>
        <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        &nbsp;&nbsp;
        <Typography>
          {`${translate('task')} ${conversation.taskExternalId}`}
          <br />
          <small>{conversation.driverName}</small>
        </Typography>
        <Box position="absolute" right={4} top={6}>
          <Tooltip title={translate('chat.finish')} placement="top" arrow>
            <IconButton onClick={() => setEnd(true)}>
              <DeleteForeverIcon className={classes.delete} />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <List className={classes.content} ref={list}>
        {loading && (
        <Box textAlign="center">
          <CircularProgress size={24} />
        </Box>
        )}

        {messageList && messageList.map((msg) => (
          <Message
            key={msg.createdAt.seconds}
            sent={msg.sentByOperator}
            hour={moment.unix(msg.createdAt.seconds).format('h:mm A')}
            text={msg.text}
          />
        ))}

        {end && <EndChat handleCancel={() => setEnd(false)} handleFinish={handleFinish} ending={ending} />}
        <span ref={bottom} />
      </List>
      <TextField
        id="chat-message-textfield"
        label=""
        multiline
        rowsMax={3}
        fullWidth
        value={message}
        onKeyDown={handleType}
        onChange={handleType}
        placeholder="Escribe algo..."
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="send-message"
                onClick={handleSend}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={classes.textField}
        disabled={end}
      />
    </>
  );
}

export default Conversation;
