import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Drawer, IconButton, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './style';
import PiboxMenu from './Pibox';
import RoutesMenu from './Routes';
import SettingsMenu from './Settings';
import UsersMenu from './Users';

function MenuLateral({ classes, open, handleDrawerClose, translate }) {
  const [openCollapse, setOpenCollapse] = useState({
    settings: false,
    routes: false,
    users: false,
  });
  const theme = useTheme();
  const handleCollapse = (type) => {
    switch (type) {
      case 'settings':
        setOpenCollapse({ settings: !openCollapse.settings });
        break;
      case 'routes':
        setOpenCollapse({ routes: !openCollapse.routes });
        break;
      case 'users':
        setOpenCollapse({ users: !openCollapse.users });
        break;
      default:
        break;
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img src="/logo.png" className={classes.logo} alt="logo" />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <MenuIcon color="primary" />
          ) : (
            <MenuIcon />
          )}
        </IconButton>
      </div>

      <PiboxMenu translate={translate} classes={classes} />
      <RoutesMenu
        translate={translate}
        classes={classes}
        handleCollapse={handleCollapse}
        openCollapse={openCollapse}
      />
      <UsersMenu
        translate={translate}
        classes={classes}
        handleCollapse={handleCollapse}
        openCollapse={openCollapse}
      />
      <SettingsMenu
        translate={translate}
        classes={classes}
        handleCollapse={handleCollapse}
        openCollapse={openCollapse}
      />
    </Drawer>
  );
}

MenuLateral.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(MenuLateral);
