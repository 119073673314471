import React from 'react';
import { Container, Box } from '@material-ui/core';
import {
  Content,
  Header,
  Banner,
  Social,
  Contact,
  NumbersProduct,
  Multimedia,
  Features,
  Footer,
} from '../Components';

const featuresItems = [
  {
    img: 'optimization-clock',
    title: 'landing.optimization.features.icon.1.title',
    label: 'landing.optimization.features.icon.1.label',
  },
  {
    img: 'optimization-truck',
    title: 'landing.optimization.features.icon.2.title',
    label: 'landing.optimization.features.icon.2.label',
  },
  {
    img: 'optimization-dolly',
    title: 'landing.optimization.features.icon.3.title',
    label: 'landing.optimization.features.icon.3.label',
  },
  {
    img: 'optimization-clipboard',
    title: 'landing.optimization.features.icon.4.title',
    label: 'landing.optimization.features.icon.4.label',
  },
  {
    img: 'optimization-search',
    title: 'landing.optimization.features.icon.1.title',
    label: 'tareas por ruta con base a características comunes.',
  },
  {
    img: 'optimization-users',
    title: 'landing.optimization.features.icon.5.title',
    label: 'landing.optimization.features.icon.5.label',
  },
];

const images = [
  { id: 1, src: 'images/landing/optimiza-1.png' },
  { id: 2, src: 'images/landing/optimiza-2.png' },
  { id: 3, src: 'images/landing/optimiza-3.png' },
  { id: 4, src: 'images/landing/optimiza-4.png' },
  { id: 4, src: 'images/landing/optimiza-5.png' },
  { id: 4, src: 'images/landing/optimiza-6.png' },
];

const videos = [
  {
    video: 'optimization-video-1.mp4',
    title: 'landing.optimization.videos.1.title',
    description: [
      { text: 'Pibox Enterprise', bold: true },
      { text: 'landing.optimization.videos.1.description', bold: false },
      { text: 'https://bit.ly/3sL0I66', link: true },
    ],
  },
  {
    video: 'optimization-video-2.mp4',
    title: 'landing.optimization.videos.2.title',
    description: [
      { text: 'landing.optimization.videos.2.description.1', bold: false },
      { text: 'Pibox Enterprise', bold: true },
      { text: 'landing.optimization.videos.2.description.2', bold: false },
      { text: 'https://bit.ly/30fds8C', link: true },
    ],
  },
];

const Routing = () => (
  <Content>
    <Header menuActive={1} />
    <Container maxWidth="md">
      <Box mt={6} mb={6}>
        <Banner
          title1="landing.optimization.banner.title.1"
          title2="landing.optimization.banner.title.2"
          images={images}
        />
        <Features
          title="landing.optimization.features.title"
          items={featuresItems}
        />
        {videos.map((item, index) => (
          <Multimedia
            key={`video-${index}`}
            src={item.video}
            title={item.title}
            description={item.description}
          />
        ))}
        <NumbersProduct />
      </Box>
    </Container>
    <Contact />
    <Social />
    <Footer />
  </Content>
);

export default Routing;
