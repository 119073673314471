import sessionReducer from './users/reducer';
import orderReducer from './orders/reducer';
import trackingReducer from './tracking/reducer';
import driverReducer from './drivers/reducer';
import reportReducer from './reports/reducer';
import ticketReducer from './support/tickets/reducer';
import checkinReducer from './checkin/reducer';
import optimizedRoutesReducer from './routes/reducer';
import commonReducer from './common/reducer';
import vehiclesReducer from './vehicles/reducer';
import headquartersReducer from './headquarters/reducer';
import operatorReducer from './operators/reducer';
import chatReducer from './chat/reducer';

export const indexReducer = (
  {
    session,
    orders,
    tracking,
    drivers,
    operators,
    reports,
    tickets,
    checkin,
    optimizedRoutes,
    common,
    vehicles,
    headquarters,
    chat,
  },
  action,
) => ({
  session: sessionReducer(session, action),
  orders: orderReducer(orders, action),
  tracking: trackingReducer(tracking, action),
  drivers: driverReducer(drivers, action),
  operators: operatorReducer(operators, action),
  reports: reportReducer(reports, action),
  tickets: ticketReducer(tickets, action),
  checkin: checkinReducer(checkin, action),
  optimizedRoutes: optimizedRoutesReducer(optimizedRoutes, action),
  common: commonReducer(common, action),
  vehicles: vehiclesReducer(vehicles, action),
  headquarters: headquartersReducer(headquarters, action),
  chat: chatReducer(chat, action),
});
