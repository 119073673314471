import React, {
  useState, useContext, useMemo, useEffect,
  useCallback,
} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import { I18nContext } from 'translations';
import './schedule.css';
import {
  Box, IconButton, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ADDONS, WEEKDAYS_CD } from 'utils/constants';
import addon from 'utils/addon';

/* Event structure
{
  id: [unique id]
  start: [js date],
  end: [js date],
  resourceId: [day of the week - from 0 to 6] starting on sunday
}
*/

const localizer = momentLocalizer(moment);

function Event({ event, handleDelete }) {
  return (
    <IconButton
      onClick={() => handleDelete(event)}
      className="rbc-delete-btn"
      style={{
        fontSize: '1rem',
        padding: 0,
      }}
    >
      <CloseIcon style={{ fontSize: '1rem' }} />
    </IconButton>
  );
}

function DriverSchedule({ driver, handleChangeSchedule }) {
  const { translate } = useContext(I18nContext);

  const [editSchedule, setEditSchedule] = useState(false);
  const [events, setEvents] = useState([]);

  const days = useMemo(() => ([
    { resourceId: 'monday', resourceTitle: translate('days.monday') },
    { resourceId: 'tuesday', resourceTitle: translate('days.tuesday') },
    { resourceId: 'wednesday', resourceTitle: translate('days.wednesday') },
    { resourceId: 'thursday', resourceTitle: translate('days.thursday') },
    { resourceId: 'friday', resourceTitle: translate('days.friday') },
    { resourceId: 'saturday', resourceTitle: translate('days.saturday') },
    { resourceId: 'sunday', resourceTitle: translate('days.sunday') },
  ]), [translate]);

  const handleSelectSlot = ({
    start, end, resourceId,
  }) => {
    const newEvent = {
      id: `${resourceId}-${events.length}`, start, end, resourceId,
    };
    const eventsWithoutOverlap = events.filter((e) => {
      if (e.resourceId !== newEvent.resourceId) return true;
      return !moment(e.start).isBetween(moment(newEvent.start), moment(newEvent.end))
        && !moment(e.end).isBetween(moment(newEvent.start), moment(newEvent.end));
    });

    setEvents([...eventsWithoutOverlap, newEvent]);
  };

  const handleDeleteSlot = (event) => {
    setEvents(events.filter((e) => e.id !== event.id));
  };

  const eventsToSchedule = useCallback(() => {
    const schedule = events.map((event) => ({
      from_time: moment(event.start).format('HH:mm'),
      to_time: moment(event.end).format('HH:mm'),
      weekday_cd: WEEKDAYS_CD[event.resourceId],
    }));

    handleChangeSchedule(schedule);
  }, [events, handleChangeSchedule]);

  const scheduleToEvents = useCallback(() => {
    if (driver && driver.active_weekdays) {
      const slots = driver.active_weekdays.map((slot, index) => ({
        id: index,
        start: moment(slot.from_time, 'HH:mm').toDate(),
        end: moment(slot.to_time, 'HH:mm').toDate(),
        resourceId: days.find((d) => slot.weekday_cd === WEEKDAYS_CD[d.resourceId]).resourceId,
      }));

      setEvents(slots);
    }
  }, [driver, days]);

  useEffect(() => {
    eventsToSchedule();
  }, [eventsToSchedule]);

  useEffect(() => {
    scheduleToEvents();
  }, [scheduleToEvents]);

  return (
    <>
      <br />
      <Button
        color="secondary"
        endIcon={editSchedule ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        onClick={() => setEditSchedule(!editSchedule)}
      >
        {translate('driver.schedule.edit')}
      </Button>
      {editSchedule && (
      <Box height={600} mt={3}>
        <Calendar
          events={events}
          step={60}
          localizer={localizer}
          defaultView="day"
          views={['day']}
          resources={days}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          toolbar={false}
          selectable="ignoreEvents"
          onSelectSlot={handleSelectSlot}
          components={{
            event: (props) => <Event {...props} handleDelete={handleDeleteSlot} />,
          }}
        />
      </Box>
      )}
    </>
  );
}

const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: false,
};

export default addon(DriverSchedule, addonOptions);
