import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  containerAccordion: {
    boxShadow: 'none',
    background: '#fbfbfb',
    marginBottom: '10px',
  },
  titleAccordion: {
    paddingTop: 10,
    '& > div': {
      margin: '5px 0 !important',
    },
  },
  selectDriverButton: {
    marginBottom: 10,
  },
  driverName: {
    display: 'block',
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
  },
  noDriver: {
    display: 'block',
    fontSize: '0.95rem',
    color: '#f8b133',
  },
  routeTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontSize: '0.95rem',
    textAlign: 'center',
  },
  routeCheckbox: {
    alignItems: 'flex-start',
    width: '100%',
    '& > .MuiCheckbox-root': {
      padding: '0 9px',
    },
    '& > .MuiTypography-root': {
      width: '100%',
    },
  },
}));

export default styles;
