import React, { useState, useContext, Fragment } from 'react';
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import addon from 'utils/addon';
import { ADDONS } from 'utils/constants';
import { AddCircleOutline, DeleteOutline } from '@material-ui/icons';
import { Controller, useFieldArray } from 'react-hook-form';
import { I18nContext } from 'translations';

function AddonFormControlHeadquarters({
  control,
  driver,
  classes,
  register,
  headquarters,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headquarter_ids',
  });

  const { translate } = useContext(I18nContext);

  const initialValuesList = (_) => {
    const headquartersDriver = driver ? driver.headquarters.slice() : [];
    headquartersDriver.forEach((item) => {
      item.disabled = true;
    });

    headquarters.forEach((element) => {
      const searchItem = headquartersDriver.filter(
        (hd) => hd._id === element._id,
      ).length;
      !searchItem && headquartersDriver.push(element);
    });

    return headquartersDriver;
  };

  const [itemsSelected, setItemsSelected] = useState(initialValuesList);

  const initialValuesItemsSelectedUpdate = (_) => {
    const listHeadquarters = [];
    driver
      && driver.headquarters.forEach((item) => listHeadquarters.push(item._id));
    return listHeadquarters;
  };
  const [driverHeadquarters, setDriverHeadquarters] = useState(
    initialValuesItemsSelectedUpdate,
  );

  function updateItemSelected(selectedItemId, previous) {
    const listSelectedHeadquarters = itemsSelected.slice();
    listSelectedHeadquarters.forEach((item) => {
      if (item._id === previous) item.disabled = false;
      if (item._id === selectedItemId) item.disabled = true;
    });

    const indexItem = driverHeadquarters.indexOf(previous);
    if (indexItem >= 0) {
      driverHeadquarters[indexItem] = selectedItemId;
    } else {
      driverHeadquarters.push(selectedItemId);
    }
    setItemsSelected(listSelectedHeadquarters);
    setDriverHeadquarters(driverHeadquarters);
  }

  function onClickItemList(item, index) {
    updateItemSelected(item._id, driverHeadquarters[index]);
    fields.length - 1 === index && append('');
  }

  function removeItemSelected(indexItemRemove) {
    remove(indexItemRemove);
    const clonedListHeadquarters = driverHeadquarters.slice();
    const clonedItemsSelected = itemsSelected.slice();
    const idHeadquarterSelected = clonedListHeadquarters[indexItemRemove];

    clonedItemsSelected.forEach((item) => {
      if (item._id === idHeadquarterSelected) item.disabled = false;
    });

    setItemsSelected(clonedItemsSelected);
    clonedListHeadquarters.splice(indexItemRemove, 1);

    setDriverHeadquarters(clonedListHeadquarters);
  }

  const selectedField = () => fields.map(({ id }, index) => (
    <Fragment key={`list-${id}`}>
      <Grid item md={9} sm={9} xs={9} className="text-left">
        <FormControl variant="outlined" fullWidth className={classes.field}>
          <InputLabel htmlFor="headquarter">
            {translate('headquarters')}
          </InputLabel>
          <Controller
            inputRef={register}
            control={control}
            name={`headquarter_ids[${index}]`}
            label={translate('headquarters')}
            defaultValue=""
            variant="outlined"
            as={(
              <Select>
                {!itemsSelected
                    || (!itemsSelected.length && (
                      <MenuItem disabled>
                        {translate('headquarters.empty')}
                      </MenuItem>
                    ))}
                {itemsSelected
                    && itemsSelected.map((option) => (
                      <MenuItem
                        key={option._id}
                        value={option._id}
                        onClick={() => onClickItemList(option, index)}
                        disabled={option.disabled}
                      >
                        {translate(option.name)}
                      </MenuItem>
                    ))}
              </Select>
              )}
          />
        </FormControl>
      </Grid>
      {fields.length - 1 !== index ? (
        <Grid item md={3} sm={3} xs={3} className="text-left">
          <Tooltip title={translate('remove')} placement="top" arrow>
            <span>
              <IconButton
                color="primary"
                aria-label="remove"
                onClick={() => removeItemSelected(index)}
                disabled={fields.length === 1}
              >
                <DeleteOutline fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
          {' '}
        </Grid>
      ) : (
        <Grid
          container
          item
          md={3}
          sm={3}
          xs={3}
          justify="center"
          alignContent="center"
        >
          <>
            <Button
              type="button"
              color="primary"
              size="large"
              href="/app/headquarters/new-headquarter"
              startIcon={<AddCircleOutline />}
              className={classes.field}
            >
              {translate('headquarters.add')}
            </Button>
          </>
        </Grid>
      )}
    </Fragment>
  ));

  return (
    <>
      <Grid item md={12} sm={12} xs={12} className="text-left">
        <br />
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.subtitle}
        >
          {translate('headquarters.assign')}
        </Typography>
      </Grid>
      {selectedField()}
    </>
  );
}
const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: false,
};

export default addon(AddonFormControlHeadquarters, addonOptions);
