import React, { useContext } from 'react';
import { I18nContext } from 'translations';
import { Grid, CardMedia, Box, Typography } from '@material-ui/core';

const Multimedia = ({ src, title, description, autoPlay }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={7}>
        <Box color="#333333" mb={7} display="flex" flexDirection="column">
          <Box mb={2}>
            <Typography gutterBottom align="left" variant="h5">
              <b>{translate(title)}</b>
            </Typography>
          </Box>
          <CardMedia
            component="video"
            image={`images/landing/videos/${src}`}
            autoPlay={autoPlay}
            muted={autoPlay}
            controls
          />
          {description && (
            <Box mb={2} mt={2}>
              <Typography
                gutterBottom
                align="left"
                variant="body1"
                style={{ color: '#333333' }}
              >
                {description.map((item, index) =>
                  item.bold ? (
                    <b key={`bold-${index}`}>{` ${translate(item.text)} `}</b>
                  ) : item.link ? (
                    <a
                      key={`link-${index}`}
                      style={{ color: '#8029CF' }}
                      href={item.text}
                    >
                      &nbsp;{translate(item.text)}
                    </a>
                  ) : (
                    translate(item.text)
                  )
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Multimedia;
