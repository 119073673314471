import React, { useContext, useState } from 'react';
import { Grid, Typography, Button, TextField, Snackbar } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { createTicket } from 'context/support/tickets/actions';
import { modalMessage } from 'utils';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';
import schema from './schema';

function TicketSystem() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useStateValue();
  const { langCode, translate } = useContext(I18nContext);
  const handleClose = () => setMessage(null);

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(translate('support.ticketSendSuccesfully'), translate('support.ticketSendSuccesfullyMssg'), 'success', translate('accept'));
    reset();
  }

  const { handleSubmit, errors, register, reset } = useForm({
    validationSchema: schema
  });

  const submit = async values => {
    setLoading(true);
    const callback = await createTicket(dispatch, values, langCode);
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  }

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <Grid container spacing={2} justify="space-around">
          <Grid item xs={12}>
            <Typography variant="h4" className="title text-center">
              {translate('support.reportProblem')}
            </Typography>  
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-around">
          <Grid item xs={12} md={8} sm={12} style={{ margin: '30px 0 '}}>      
            <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                  <TextField
                    name="subject"
                    error={!!errors.subject}
                    label={translate('subject')}
                    helperText={errors.subject && translate(errors.subject.message)}
                    variant="outlined"
                    inputRef={register}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    error={!!errors.message}
                    label={translate('message')}
                    helperText={errors.message && translate(errors.message.message)}
                    variant="outlined"
                    inputRef={register}
                    multiline
                    rows={4}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className="text-center">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                  > 
                    {translate('sendMessage')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>   
  );
}

export default TicketSystem;
