import React, { useState, Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Create, StarOutlined } from '@material-ui/icons';
import { I18nContext } from 'translations';
import { onDutyClass } from 'utils';
import { ACCOUNT_STATUSES } from 'utils/constants';
import { AddonColumnProfileVehicles, FiltersListDriver } from './components';
import styles from './style';

function ListDrivers({
  data,
  recordsPerPage,
  height,
  assignDriver,
  onDuty,
  onSearch,
  getDrivers,
  getDriversOnDuty,
}) {
  const [currentPage, setCurrentPage] = useState(data.page);
  const [totalCount, setTotalCount] = useState(data.pages * recordsPerPage);
  const { translate } = useContext(I18nContext);
  const classes = styles();

  useEffect(() => {
    setTotalCount(data.pages * recordsPerPage);
    setCurrentPage(data.page);
  }, [data, recordsPerPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getDrivers(newPage);
    onDuty ? getDriversOnDuty(newPage) : getDrivers(newPage);
  };

  if (!data || (data && data.data.length <= 0)) {
    return <p>{translate('noData')}</p>;
  }

  return (
    <div>
      <FiltersListDriver
        getDrivers={getDrivers}
        getDriversOnDuty={getDriversOnDuty}
        onDuty={onDuty}
        onSearch={onSearch}
      />
      <TableContainer
        className={classes.tableContainer}
        style={{ height: height }}
      >
        <Table className={classes.table}>
          <TableBody>
            {data.data.map((driver, index) => (
              <TableRow key={index.toString()}>
                <TableCell className="bold">
                  <p className={classes.name}>{driver.name}</p>
                </TableCell>
                <TableCell>
                  <p className={classes.name}>{driver.email}</p>
                </TableCell>
                <TableCell>
                  {driver.rating_as_driver ? (
                    <Fragment>
                      <StarOutlined className="yellow vertical-middle" />
                      <span className="vertical-middle">{` ${driver.rating_as_driver}`}</span>
                    </Fragment>
                  ) : (
                    translate('unrated')
                  )}
                </TableCell>
                <TableCell>{`${driver.phone_country_code} - ${driver.phone_number}`}</TableCell>
                <TableCell>
                  {translate(ACCOUNT_STATUSES[driver.account_status_cd])}
                </TableCell>
                <AddonColumnProfileVehicles
                  vehicleProfileName={
                    driver.vehicle_profile ? driver.vehicle_profile.name : ''
                  }
                />
                <TableCell>
                  <Button className={onDutyClass(driver.on_duty)}>
                    {driver.on_duty
                      ? translate('connected')
                      : translate('disconnected')}
                  </Button>
                </TableCell>
                <TableCell>
                  {assignDriver ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => assignDriver(driver)}
                    >
                      {translate('choose')}
                    </Button>
                  ) : (
                    <Fragment>
                      <Tooltip title={translate('edit')}>
                        <Button
                          style={{ minWidth: 35 }}
                          href={`/app/drivers/${driver._id}`}
                          size="small"
                        >
                          <Create />
                        </Button>
                      </Tooltip>
                      {/*<Tooltip title={translate('remove')}>
                        <Button
                          style={{ minWidth: 35 }}
                          href={`/app/drivers/${driver._id}`}
                          size="small"
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </Tooltip>*/}
                    </Fragment>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => {
          handleChangePage(event, newPage + 1);
        }}
      />
    </div>
  );
}

ListDrivers.propTypes = {
  data: PropTypes.object,
  recordsPerPage: PropTypes.number.isRequired,
  assignDriver: PropTypes.any,
};

ListDrivers.defaultProps = {
  data: {},
  assignDriver: null,
};

export default ListDrivers;
