import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  subtitle: {
    color: '#4F4F4F',
  },
  carousel: {
    maxWidth: 500,
    margin: '20px auto 0px auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '70vw',
    },
  },
  free: {
    position: 'absolute',
    top: '-35px',
    right: '-15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default styles;
