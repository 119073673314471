import {
  urlJobsBatch,
  urlBatchOptimize,
  urlJobsAssignDriver,
  urlTasksPoints,
  urlRouteFileList,
} from 'api';
import { post, get, patch } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';
import {
  OPTIMIZE_ROUTES,
  UNASSIGNED_ROUTES,
  INIT_VIEW,
  POINTS_MAP,
  ROUTE_FILE_LIST,
  ROUTE_FILE_STATUS_LIST,
  PENDING_ROUTE_FILES,
  UPDATING_FILE_STATUS,
} from './constants';

export const optimizeRoutes = async (dispatch, values, lang, type) => {
  let status = false;
  let message = null;
  let id = null;
  let invalidTasks = null;
  await post(urlBatchOptimize, { ...values }, lang)
    .then(({ data }) => {
      dispatch({
        type: OPTIMIZE_ROUTES,
        optimizedRoutes: type !== 'file' ? data : null,
      });
      dispatch({
        type: UNASSIGNED_ROUTES,
        unassignedRoutes: type !== 'file' ? data.unassigned_tasks : null,
      });
      status = true;
      if (type === 'file') {
        id = data.optimization_tasks_file_id;
        invalidTasks = data.invalid_tasks;
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return {
    status, message, id, invalidTasks,
  };
};

export const createJobsOptimizedRoutes = async (dispatch, values, type) => {
  let status = false;
  let message = null;
  await post(type === 'file' ? urlJobsAssignDriver : urlJobsBatch, {
    ...values,
  })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const initView = (dispatch) => {
  dispatch({
    type: INIT_VIEW,
    optimizedRoutes: null,
  });
};

export const getOptimizationTasks = async (dispatch, id, params) => {
  let status = false;
  let message = null;
  await get(`${urlRouteFileList}/${id}/jobs`, params)
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: OPTIMIZE_ROUTES,
        optimizedRoutes: {
          jobs: [...data.data],
          page: data.page,
          pages: data.pages,
        },
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const getUnassignedTasks = async (dispatch, id, params) => {
  let status = false;
  let message = null;

  await get(`${urlRouteFileList}/${id}/unassigned_tasks`, params)
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: UNASSIGNED_ROUTES,
        unassignedRoutes: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const getTasksPoints = async (dispatch, id) => {
  let status = false;
  let message = null;
  await get(`${urlTasksPoints}/${id}/tasks_points`)
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: POINTS_MAP,
        pointsMap: { jobs: [...data] },
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

const getPendingFiles = (fileStatusList) => {
  const files = Object.keys(fileStatusList)
    .filter((key) => fileStatusList[key] === 0)
    .map((key) => key);
  return files || [];
};

export const getRoutefileList = async (dispatch, params) => {
  let status = false;
  let message = null;
  await get(urlRouteFileList, params)
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: ROUTE_FILE_LIST,
        routeFileList: data,
      });
      const fileStatusList = data && data.data
        ? data.data.reduce(
          (list, route) => ({ ...list, [route._id]: route.status_cd }),
          {},
        )
        : [];
      dispatch({
        type: ROUTE_FILE_STATUS_LIST,
        fileStatusList,
      });
      dispatch({
        type: PENDING_ROUTE_FILES,
        pendingFiles: getPendingFiles(fileStatusList),
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const getRouteFileStatus = (dispatch, id, fileStatusList) => get(`${urlRouteFileList}/${id}`)
  .then(({ data }) => {
    if (data.status_cd) {
      const newFileStatusList = { ...fileStatusList, [id]: data.status_cd };
      dispatch({
        type: ROUTE_FILE_STATUS_LIST,
        fileStatusList: newFileStatusList,
      });
      dispatch({
        type: PENDING_ROUTE_FILES,
        pendingFiles: getPendingFiles(newFileStatusList),
      });
    }
  })
  .catch(() => {});

export const updateRouteFileStatus = async (
  dispatch,
  pendingFiles,
  fileStatusList,
) => {
  dispatch({
    type: UPDATING_FILE_STATUS,
    updatingFileStatus: true,
  });
  const promises = pendingFiles.map((fileId) => getRouteFileStatus(dispatch, fileId, fileStatusList));
  await Promise.all(promises);
  dispatch({
    type: UPDATING_FILE_STATUS,
    updatingFileStatus: false,
  });
};

export const cancelOptimizationTasks = async (dispatch, idOptimize) => {
  let status = false;
  let message = null;
  await patch(`${urlTasksPoints}/${idOptimize}/cancel`)
    .then((json) => json.data)
    .then(() => { status = true; })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};
