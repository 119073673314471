import React from 'react';
import { Multimedia } from '../../../Components';

const videos = [
  {
    title: 'landing.home.video.title',
    description: [{ text: 'landind.home.video.description', bold: false }],
    video: 'home-video.mp4',
    autoPlay: true,
  },
  {
    title: 'landing.features.video.title',
    description: [
      { text: 'Pibox Enterprise', bold: true },
      { text: 'landing.features.video.description', bold: false },
    ],
    video: 'routing-video.mp4',
    autoPlay: false,
  },
  {
    title: 'landing.client.video.title',
    description: [
      { text: 'landing.client.video.description.1', bold: false },
      { text: 'Pibox Enterprise', bold: true },
      { text: 'landing.client.video.description.2', bold: false },
    ],
    video: 'client-video.mp4',
    autoPlay: false,
  },
  {
    title: 'landing.optimization.videos.1.title',
    description: [
      { text: 'Pibox Enterprise', bold: true },
      { text: 'landing.optimization.videos.1.description', bold: false },
      { text: 'https://bit.ly/3sL0I66', link: true },
    ],
    video: 'optimization-video-1.mp4',
    autoPlay: false,
  },
  {
    title: 'landing.optimization.videos.2.title',
    description: [
      { text: 'landing.optimization.videos.2.description.1', bold: false },
      { text: 'Pibox Enterprise', bold: true },
      { text: 'landing.optimization.videos.2.description.2', bold: false },
      { text: 'https://bit.ly/30fds8C', link: true },
    ],
    video: 'optimization-video-2.mp4',
    autoPlay: false,
  },
];

const Contents = () => {
  return videos.map((item, index) => (
    <Multimedia
      key={`video-${index}`}
      src={item.video}
      title={item.title}
      description={item.description}
      autoPlay={item.autoPlay}
    />
  ));
};

export default Contents;
