import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { statusColor } from 'utils';

mapboxgl.accessToken = process.env.REACT_APP_API_URL_BASE_MAPS_API_TOKEN;

const getDriverMarker = (driver, map) => {
  const el = document.createElement('div');
  el.style.backgroundImage = "url('/images/pibox-delivery-sm.png')";
  el.style.width = '160px';
  el.style.height = '160px';
  el.id = 'marker';
  const popup = new mapboxgl.Popup({
    closeButton: false,
  }).setText(driver.driver.name);

  return new mapboxgl.Marker(el).setLngLat(driver).setPopup(popup).addTo(map);
};

const getTaskMarker = (task, map, onClick) => {
  const el = document.createElement('div');
  el.innerHTML = `
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fill-rule="evenodd">
      <path fill="#6F2F82" fill-rule="nonzero" d="M0 0H3V28H0z" transform="translate(6 2)"/>
      <path fill="#6F2F82" fill-rule="nonzero" d="M22 18L0 18 0 3 22 3 17.188 10.5z" transform="translate(6 2)"/>
      <path fill="${statusColor(
        task.status_cd
      )}" d="M2.75 15.273L17.05 15.273 13.887 10.5 17.05 5.727 2.75 5.727z" transform="translate(6 2)"/>
    </g>
  </svg>`;
  el.id = 'marker';

  el.addEventListener('click', (e) => {
    onClick(e, task);
  });

  return new mapboxgl.Marker(el).setLngLat(task).addTo(map);
};

function ServicesMap({
  drivers,
  tasks,
  handleChangeSelectedTask,
  selectedTask,
}) {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [driverMarkers, setDriverMarkers] = useState([]);
  const [taskPopup, setTaskPopup] = useState();
  const mapContainerRef = useRef(null);

  const geojson = (coordDrivers, coordTasks) => {
    const coord = coordDrivers.map((dr) => [dr.lon, dr.lat]);
    const coord2 = coordTasks.map((task) => [task.lon, task.lat]) || [];
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            properties: {},
            coordinates: coord.concat(coord2),
          },
        },
      ],
    };
  };

  const fitBounds = (map) => {
    if (drivers.length > 0 || tasks.length > 0) {
      const { coordinates } = geojson(drivers, tasks).features[0].geometry;
      const bounds = coordinates.reduce(
        (bounds, coord) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
      );

      map.fitBounds(bounds, {
        padding: 30,
        maxZoom: 14,
        duration: 2000,
      });
    }
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: [-74.109309, 4.6534],
    });

    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', () => {
      fitBounds(map);

      setDriverMarkers(drivers.map((driver) => getDriverMarker(driver, map)));

      setMarkers(
        tasks.map((task) => getTaskMarker(task, map, handleChangeSelectedTask))
      );

      setMap(map);
    });
  }, [getTaskMarker]); // eslint-disable-line

  useEffect(() => {
    if (map) {
      driverMarkers.map((marker) => marker.remove());
      setDriverMarkers(drivers.map((driver) => getDriverMarker(driver, map)));
    }
  }, [drivers]); // eslint-disable-line

  useEffect(() => {
    if (map) {
      markers.map((marker) => marker.remove());
      setMarkers(
        tasks.map((task) => getTaskMarker(task, map, handleChangeSelectedTask))
      );
      fitBounds(map);
    }
  }, [JSON.stringify(tasks)]); // eslint-disable-line

  useEffect(() => {
    if (map && selectedTask) {
      if (taskPopup) taskPopup.remove();
      let zoom = map.getZoom();
      zoom = zoom < 12 ? 12 : zoom;
      map.flyTo({
        center: [selectedTask.lon, selectedTask.lat],
        zoom,
      });

      setTaskPopup(
        new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          offset: 20,
        })
          .setLngLat([selectedTask.lon, selectedTask.lat])
          .setText(selectedTask.external_id)
          .addTo(map)
      );
    }
  }, [selectedTask, map]); // eslint-disable-line

  return (
    <div style={{ height: '320px', width: '100%' }} ref={mapContainerRef} />
  );
}

export default ServicesMap;
