import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const MenuUser = ({ closeSession, handleOpenMenu, handleCloseMenu, checkin, anchorEl, translate }) => (
  <Fragment>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenu}>
      <Typography variant="h6" noWrap>
        {checkin.profile.name}
        <ArrowDropDownIcon className="vertical-middle" />
      </Typography>            
    </Button>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={closeSession}>
        {translate("close_session")}
      </MenuItem>
    </Menu>
  </Fragment>
)

MenuUser.propTypes = {
  closeSession: PropTypes.func.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  checkin: PropTypes.any.isRequired,
  anchorEl: PropTypes.any,
  translate: PropTypes.func.isRequired
}

MenuUser.defaultProps = {
  anchorEl: null
}

export default MenuUser;