import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography, Snackbar } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  listHeadquarters,
  deleteHeadquarter,
} from 'context/headquarters/actions';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';
import ListHeadquarters from 'pages/Headquarters/components/ListHeadquarters';
import { messageConfirmation } from 'utils';
import { ADDONS } from 'utils/constants';
import addon from 'utils/addon';

function Headquarters() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const { langCode, translate } = useContext(I18nContext);
  const [{ headquarters }, dispatch] = useStateValue();
  const unmounted = useRef(false);
  const per_page = 10;

  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const loadingAction = (isLoading) => {
    setLoading(isLoading);
    unmounted.current = false;
  };

  const getHeadquarters = async (page) => {
    loadingAction(true);
    const callback = await listHeadquarters(
      dispatch,
      { page, per_page },
      langCode,
      false
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    getHeadquarters(1);
    return () => {
      unmounted.current = true;
    };
  }, [langCode]); // eslint-disable-line

  const onDeleteHeadquarter = async (id) => {
    messageConfirmation({
      translate: translate,
      title: 'headquarters.successfull.remove.title',
      text: 'headquarters.successfull.remove.description',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        loadingAction(true);
        const callback = await deleteHeadquarter(dispatch, {}, id, langCode);
        callback.status ? setLoading(false) : handleMessage(callback.message);
        getHeadquarters(1);
      }
    });
  };

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <Grid container>
        <Grid item md={6} xs={12} sm={12}>
          <Typography variant="h4" className="title text-left text-center-sm">
            {translate('headquarters.list')}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} sm={12} className="text-right text-center-sm">
          <Button
            type="button"
            color="primary"
            variant="contained"
            size="large"
            component={Link}
            startIcon={<AddCircleOutlineIcon />}
            to="/app/headquarters/new-headquarter"
          >
            {translate('headquarters.add')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ListHeadquarters
            height="auto"
            data={headquarters.data}
            handleDelete={onDeleteHeadquarter}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: true,
};

export default addon(Headquarters, addonOptions);
