import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { I18nContext } from 'translations';
import InvalidCell from 'components/BulkLoad/InvalidCell';

function InvalidRows({
  handleClose, rows, columns, rowId,
}) {
  const { translate } = useContext(I18nContext);

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      aria-labelledby="invalid-tasks-dialog"
    >
      <DialogTitle id="invalid-tasks-dialog-title">
        <Box display="flex" alignItems="center">
          <WarningIcon color="primary" />
          &nbsp;
          {translate('bulkLoad.invalid.title')}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${translate('bulkLoad.invalid.description')}:`}
        </DialogContentText>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.name}>
                  {translate(col.name)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row[rowId]}>
                {columns.map((col) => (
                  <InvalidCell
                    key={col.name}
                    error={col.error(row)}
                    content={col.value(row)}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

InvalidRows.propTypes = {
  handleClose: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      errors: PropTypes.any,
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      error: PropTypes.func.isRequired,
    }),
  ).isRequired,
  rowId: PropTypes.string.isRequired,
};

export default InvalidRows;
