import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Tooltip,
  TableHead,
} from '@material-ui/core';
import { Create, Delete } from '@material-ui/icons';
import { I18nContext } from 'translations';
import styles from '../../../components/Tables/style';
import { textOverflow } from 'utils';

function HeadquartersTable({ data, height, handleDelete }) {
  const { translate } = useContext(I18nContext);
  const classes = styles();

  if (!data || !data.length) {
    return <p>{translate('noData')}</p>;
  }
  return (
    <div>
      <TableContainer className={classes.tableContainer} style={{ height }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('name')}</TableCell>
              <TableCell>{translate('address')}</TableCell>
              <TableCell>{translate('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((headquarter, index) => (
                <TableRow key={index.toString()}>
                  <TableCell className="bold">
                    <p className={classes.name}>{headquarter.name}</p>
                  </TableCell>
                  <TableCell>
                    <p className={classes.name}>
                      {headquarter.address}
                      <br />
                      {headquarter.secondary_address && (
                        <span>
                          {textOverflow(headquarter.secondary_address, 105)}{' '}
                        </span>
                      )}
                    </p>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={translate('edit')}>
                      <Button
                        style={{ minWidth: 35 }}
                        href={`/app/headquarters/${headquarter._id}`}
                        size="small"
                      >
                        <Create />
                      </Button>
                    </Tooltip>
                    <Tooltip title={translate('remove')}>
                      <Button
                        style={{ minWidth: 35 }}
                        size="small"
                        onClick={() => handleDelete(headquarter._id)}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

HeadquartersTable.propTypes = {
  data: PropTypes.array,
};

HeadquartersTable.defaultProps = {
  data: [],
};

export default HeadquartersTable;
