import { GET_TRACKING } from './constants';

const trackingReducer = (state, action) => {
  switch (action.type) {
    case GET_TRACKING: 
      return {
        ...state,
        tracking: action.tracking
      }
    default:
      return state;
  }
}

export default trackingReducer;