import React from 'react';
import {
  ListItem, ListItemText, Zoom,
} from '@material-ui/core';
import styles from './styles';

function Message({ sent, hour, text }) {
  const classes = styles();
  return (
    <Zoom in>
      <ListItem className={sent ? classes.sent : classes.received}>
        <ListItemText
          primary={text}
          secondary={hour}
          primaryTypographyProps={{
            className: classes.text,
          }}
          secondaryTypographyProps={{
            variant: 'caption',
          }}
        />
      </ListItem>
    </Zoom>
  );
}

export default Message;
