import React, { useEffect, useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { queryChats, updateChat } from 'firebase/utils';
import { useStateValue } from 'context/store';

import { chatLaunched } from 'context/chat/actions';
import ChatButton from './components/ChatButton';
import ChatContainer from './components/ChatContainer';

function Chat() {
  const [{ chat, checkin: { checkin } }, dispatch] = useStateValue();
  const [open, setOpen] = useState(false);
  const [conversation, setConversation] = useState();
  const [chats] = useCollectionData(queryChats(checkin ? checkin.profile._id : ''));

  const badge = useMemo(() => {
    if (!chats) return 0;
    return chats.reduce((total, c) => total + c.unreadOperator, 0);
  }, [chats]);

  // Launch chat from task list effect
  useEffect(() => {
    (async () => {
      if (!chat.task) return;
      if (!chats) return;

      let taskConversation = chats.find((c) => c.taskId === chat.task._id);
      if (taskConversation) {
        setConversation(taskConversation);
      } else {
        taskConversation = {
          taskId: chat.task._id,
          taskExternalId: chat.task.external_id,
          driverId: chat.task.driver._id,
          driverName: chat.task.driver.name,
          operatorId: checkin.profile._id,
          operatorName: checkin.profile.name,
          lastMessage: '',
          unreadDriver: 0,
          unreadOperator: 0,
        };
        await updateChat(chat.task._id, taskConversation);
        setConversation(taskConversation);
      }

      setOpen(true);
      chatLaunched(dispatch);
    })();
  }, [chat.task, chats, checkin, dispatch]);

  // Update conversation effect
  useEffect(() => {
    if (conversation && chats) {
      const updatedConversation = chats.find((c) => c.taskId === conversation.taskId);
      if (updatedConversation) setConversation(updatedConversation);
    }
  }, [chats, conversation]);

  return (
    <>
      <ChatButton open={open} handleOpen={setOpen} badge={badge} />
      <ChatContainer
        open={open}
        chats={chats}
        conversation={conversation}
        handleChangeConversation={setConversation}
      />
    </>
  );
}

export default Chat;
