import React from 'react';
import {
  Box,
  Zoom,
  Paper,
} from '@material-ui/core';
import Conversation from './Conversation';
import ChatList from './ChatList';
import styles from './styles';

function ChatContainer({
  open, chats, conversation, handleChangeConversation,
}) {
  const classes = styles();

  return (
    <Zoom in={open}>
      <Box position="fixed" bottom={70} right={6} zIndex="modal">
        <Paper className={classes.container}>
          {conversation && (
          <Conversation
            conversation={conversation}
            handleBack={() => handleChangeConversation(null)}
          />
          )}

          {!conversation && (
          <ChatList
            chats={chats}
            handleClickItem={handleChangeConversation}
          />
          )}
        </Paper>
      </Box>
    </Zoom>
  );
}

export default ChatContainer;
