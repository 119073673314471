import { LIST_DRIVERS, SELECTED_DRIVER } from './constants';

const driverReducer = (state, action) => {
  switch (action.type) {
    case LIST_DRIVERS:
      return {
        ...state,
        list: action.drivers,
      };
    case SELECTED_DRIVER:
      return {
        ...state,
        driver: action.driver,
      };
    default:
      return state;
  }
};

export default driverReducer;
