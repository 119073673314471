import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8029CF',
      light: '#885196',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff0068',
      light: '#ffddea',
    },
    action: {
      hover: '#EDE7F6',
      hoverOpacity: 0.05,
    },
  },
  typography: {
    fontFamily: [
      'montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
    ].join(','),
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
    },
    title: {
      fontWeight: 'bold',
    },
    body2: {
      fontWeight: 500,
    },
    fontWeightMedium: 500,
  },
  rounded: {
    small: '8px',
    medium: '12px',
    big: '20px',
  },
});
