import {
  urlDrivers,
  urlInviteUser,
  urlDriversOnDuty,
  urlSearchDrivers,
  urlDriver,
  urlDriversBulkLoad,
} from 'api';
import { get, post, put } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';
import { LIST_DRIVERS, SELECTED_DRIVER } from './constants';

export const listDrivers = async (dispatch, params, lang, onDuty) => {
  let status = false;
  let message = null;
  await get(
    onDuty ? urlDriversOnDuty : params.name ? urlSearchDrivers : urlDrivers,
    params,
    lang,
  )
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: LIST_DRIVERS,
        drivers: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const createDriver = async (dispatch, values, lang) => {
  let status = false;
  let message = null;
  await post(urlInviteUser, { ...values }, lang)
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const listDriver = async (dispatch, id, lang) => {
  let status = false;
  let message = null;
  await get(`${urlDriver}/${id}`, {}, lang)
    .then((json) => json.data)
    .then((data) => {
      dispatch({
        type: SELECTED_DRIVER,
        driver: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const updateDriver = async (dispatch, values, id, lang) => {
  let status = false;
  let message = null;
  await put(`${urlDriver}/${id}`, { ...values }, lang)
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const driversBulkLoad = async (dispatch, file) => {
  let status = false;
  let message = null;
  let invalidDrivers = null;
  await post(urlDriversBulkLoad, { ...file })
    .then(({ data }) => {
      status = true;
      invalidDrivers = data.invalid_drivers;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message, invalidDrivers };
};
