import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ListRoutesOptimization from 'pages/Routes/ListRoutesOptimization';
import RouteFileList from 'pages/Routes/RouteFileList';
import Reports from 'pages/Reports';
import TicketSystem from 'pages/Support/TicketSystem';
import BulkLoad from 'pages/BulkLoad';
import Chat from 'pages/Chat';
import Terms from 'pages/Terms';
import { Home, Routing, Client, Optimization, Videos } from './pages/Landing';
import Login from './pages/Users/Login';
import AssignPassword from './pages/Users/AssignPassword';
import DownloadApp from './pages/Users/AssignPassword/DownloadApp';
import RecoverPassword from './pages/Users/RecoverPassword';
import UnlockSignin from './pages/Users/UnlockSignin';
import Signup from './pages/Users/Signup';
import Privacy from './pages/Privacy';
import Layout from './components/Layout';
import NotFound from './components/Errors/NotFound';
import Orders from './pages/Orders';
import NewOrder from './pages/Orders/NewOrder';
import EditOrder from './pages/Orders/EditOrder';
import Tracking from './pages/Tracking';
import OptimizeRoutes from './pages/Routes';
import {
  Vehicles,
  AddVehicles,
  EditVehicles,
  Headquarters,
  NewHeadquarter,
  EditHeadquarter,
  Drivers,
  NewDriver,
  EditDriver,
  DriversBulkLoad,
  Operators,
  NewOperators,
  EditOperator,
  OperatorsBulkLoad,
} from './pages';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/routing" component={Routing} />
      <Route exact path="/client" component={Client} />
      <Route exact path="/optimization" component={Optimization} />
      <Route exact path="/videos" component={Videos} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/assign-password" component={AssignPassword} />
      <Route exact path="/tracking/:id" component={Tracking} />
      <Route exact path="/download-app" component={DownloadApp} />
      <Route exact path="/recover-password" component={RecoverPassword} />
      <Route exact path="/change-password" component={AssignPassword} />
      <Route exact path="/unlock-signin" component={UnlockSignin} />
      <Route exact path="/signup" component={Signup} />
      <Layout>
        <Chat />
        <Switch>
          <Route exact path="/app" component={Orders} />
          <Route exact path="/app/orders/new-order" component={NewOrder} />
          <Route exact path="/app/orders/:id" component={EditOrder} />
          <Route exact path="/app/drivers" component={Drivers} />
          <Route exact path="/app/drivers/new-driver" component={NewDriver} />
          <Route
            exact
            path="/app/drivers/bulk-load"
            component={DriversBulkLoad}
          />
          <Route exact path="/app/drivers/:id" component={EditDriver} />
          <Route exact path="/app/optimize-routes" component={OptimizeRoutes} />
          <Route exact path="/app/vehicles" component={Vehicles} />
          <Route
            exact
            path="/app/vehicles/new-vehicle"
            component={AddVehicles}
          />
          <Route exact path="/app/vehicles/:id" component={EditVehicles} />
          <Route exact path="/app/headquarters" component={Headquarters} />
          <Route
            exact
            path="/app/headquarters/new-headquarter"
            component={NewHeadquarter}
          />
          <Route
            exact
            path="/app/headquarters/:id"
            component={EditHeadquarter}
          />

          <Route exact path="/app/operators" component={Operators} />
          <Route
            exact
            path="/app/operators/new-operator"
            component={NewOperators}
          />
          <Route
            exact
            path="/app/operators/bulk-load"
            component={OperatorsBulkLoad}
          />
          <Route exact path="/app/operators/:id" component={EditOperator} />
          <Route
            exact
            path="/app/optimize-routes/:id"
            component={ListRoutesOptimization}
          />
          <Route exact path="/app/routes-file-list" component={RouteFileList} />
          <Route exact path="/app/reports" component={Reports} />
          <Route exact path="/app/support/ticket" component={TicketSystem} />
          <Route exact path="/app/bulk-load" component={BulkLoad} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Switch>
  </Router>
);

export default Routes;
