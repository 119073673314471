import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Box } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { listHeadquarters } from 'context/headquarters/actions';
import { modalMessage } from 'utils';
import { getByIdOperator, updateOperator } from 'context/operators/actions';
import PaperBlock from 'components/PaperBlock';
import BackButtonTitle from 'components/Layout/BackButtonTitle';
import Loading from 'components/Loading';
import FormOperators from './form';

function EditOperator({ history, match }) {
  const [
    {
      headquarters,
      checkin,
      operators: { selectedOperator },
    },
    dispatch,
  ] = useStateValue();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { langCode, translate } = useContext(I18nContext);
  const {
    params: { id },
  } = match;

  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('operator.successfull.edited.title'),
      translate('operator.successfull.edited.description'),
      'success',
      translate('continue'),
    ).then((result) => {
      result.value && history.push('/app/operators');
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const callback = await updateOperator(
      dispatch,
      {
        user: {
          ...values,
        },
        user_type: 'operator',
      },
      id,
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  const getHeadquarters = async (page) => {
    setLoading(true);
    const callback = await listHeadquarters(dispatch, {}, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  const getOperator = async (idOperator) => {
    setLoading(true);
    const callback = await getByIdOperator(dispatch, idOperator, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    const { addons } = checkin;
    if (addons) {
      addons.route_optimization && getHeadquarters(1);
    }
  }, [checkin]); // eslint-disable-line

  useEffect(() => {
    id && getOperator(id);
  }, [id]); // eslint-disable-line

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('operator.edit')}
          link="/app/operators"
          buttonName={translate('back')}
        />
        <Grid container justify="center">
          <Grid item md={8}>
            <Box mt={5}>
              {selectedOperator && (
                <FormOperators
                  submit={onSubmit}
                  translate={translate}
                  langCode={langCode}
                  operator={selectedOperator}
                  headquarters={headquarters}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

EditOperator.propTypes = {
  history: PropTypes.object.isRequired,
};

export default EditOperator;
