import React from 'react';
import {
  Fab, Box, Zoom, Badge,
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';

function Chat({ open, handleOpen, badge }) {
  return (
    <Box position="fixed" bottom={12} right={6} zIndex="modal">
      {open && (
        <Zoom in>
          <Fab color="primary" aria-label="chat" size="medium" onClick={() => handleOpen(false)}>
            <CloseIcon />
          </Fab>
        </Zoom>
      )}

      {!open && (
        <Zoom in>
          <Fab color="primary" aria-label="chat" size="medium" onClick={() => handleOpen(true)}>
            <Badge badgeContent={badge || 0} color="error">
              <ChatIcon />
            </Badge>
          </Fab>
        </Zoom>
      )}
    </Box>
  );
}

export default Chat;
