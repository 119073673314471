import React, { useState, useContext } from 'react';
import { Grid, Typography, Button, Snackbar } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import { useStateValue } from 'context/store';
import { getReport } from 'context/reports/actions';
import { I18nContext } from 'translations';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';
import 'moment/locale/es';

function Reports() {
  const [initDate, setInitDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const { langCode, translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();

  const handleClose = () => setMessage(null);
  const handleDateChange = (value, type) => (type === 0 ? setInitDate(value._d) : setEndDate(value._d));

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  const handleReport = async e => {
    e.preventDefault();
    setLoading(true);
    const formatInitDate = moment(initDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');
    const callback = await getReport(dispatch, { ini_date: formatInitDate, end_date: formatEndDate }, langCode);
    callback.status ? handleMessage(translate('fileDownloadedSuccessfully')) : handleMessage(callback.message);
  }

  return (
    <PaperBlock border={0}>
      {loading && (<Loading />)}
      <div className="white-page">
        <Grid container spacing={8} justify="space-around">
          <Grid item xs={12}>
            <Typography variant="h4" className="title text-center">
              {translate('reports')}
            </Typography>
            <Typography variant="h6">
              {translate('report.selectDatesYouWantToSee')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sm={12}>
            <form onSubmit={handleReport} noValidate>
              <Grid container spacing={5} style={{ marginBottom: 25 }}>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      label={translate('since')}
                      format="DD/MM/YYYY"
                      cancelLabel={translate('cancel')}
                      okLabel={translate('accept')}
                      maxDate={new Date()}
                      value={initDate}
                      onChange={e => handleDateChange(e, 0)}
                      invalidDateMessage={translate('invalidFormat')}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      label={translate('until')}
                      format="DD/MM/YYYY"
                      cancelLabel={translate('cancel')}
                      okLabel={translate('accept')}
                      minDate={initDate}
                      maxDate={new Date()}
                      value={endDate}
                      onChange={e => handleDateChange(e, 1)}
                      invalidDateMessage={translate('invalidFormat')}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    color="primary"
                  >
                    {translate('consult')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

export default Reports;
