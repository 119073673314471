import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  container: {
    width: '100%',
    backgroundImage: 'url("/images/landing/background-footer.png")',
    backgroundPosition: 'bottom',
    backgroundColor: 'white',
    backgroundSize: '100%',
    backgroundRepeat: ' no-repeat',
  },
}));

export default styles;
