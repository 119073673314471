import React, { useEffect, useState, useContext } from 'react';
import {
  Button, Grid, Typography, Snackbar,
} from '@material-ui/core';
import { AddCircleOutline, CloudUpload } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { Loading, PaperBlock } from 'components';
import { ROLES } from 'utils/constants';
import role from 'utils/role';

import { listOperators } from 'context/operators/actions';
import ListOperators from './components/ListOperators';

function Operators() {
  const { langCode, translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [per_page] = useState(10);
  const handleClose = () => setMessage(null);
  const [{ operators }, dispatch] = useStateValue();
  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const getAllOperators = async (page) => {
    setLoading(true);
    const callback = await listOperators(
      dispatch,
      { page, per_page },
      langCode,
    );
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    getAllOperators(newPage);
  };

  const onSearch = async (event) => {
    if (event.charCode === 13) {
      setLoading(true);
      const callback = await listOperators(
        dispatch,
        { name: event.target.value, page: 1, per_page },
        langCode,
      );
      callback.status ? setLoading(false) : handleMessage(callback.message);
    }
  };

  useEffect(() => {
    getAllOperators(1);
    // eslint-disable-next-line
  }, [langCode]);

  useEffect(() => {
    if (operators.list) {
      setTotalCount(operators.list.pages * per_page);
      setCurrentPage(parseInt(operators.list.page, 10));
    }
  }, [operators, per_page]);

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <Grid container>
        <Grid item md={6} xs={12} sm={12}>
          <Typography variant="h4" className="title text-left text-center-sm">
            {translate('operator.list')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          md={6}
          xs={12}
          sm={12}
          justify="flex-end"
        >
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              startIcon={<AddCircleOutline />}
              to="/app/operators/new-operator"
            >
              {translate('operator.create')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              startIcon={<CloudUpload />}
              to="/app/operators/bulk-load"
            >
              {translate('uploadFile')}
            </Button>
          </Grid>
        </Grid>
        {operators.list && (
          <Grid item xs={12}>
            <ListOperators
              data={operators.list.data}
              recordsPerPage={per_page}
              currentPage={currentPage}
              totalCount={totalCount}
              handleChangePage={handleChangePage}
              onSearch={onSearch}
            />
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

const roleOptions = {
  roles: [ROLES.admin],
  showError: true,
};

export default role(Operators, roleOptions);
