import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  iconMenu: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginLeft: '10px',
    },
  },
  listItem: {
    padding: '8px 20px',
    color: '#8029CF',
  },
  backgroundSelectedMenu: {
    backgroundColor: '#EDE7F6',
  },
  link: {
    textDecoration: 'none',
    color: '#8029CF',
    width: '100%',
  },
}));

export default styles;
