import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  btn: {
    margin: '20px 0',
    minWidth: '200px',
  },
  dropzoneContainer: {
    backgroundColor: '#fff',
    '&:focus': {
      outline: 0,
    },
    '& .MuiGrid-root.MuiGrid-container': {
      margin: 0,
      width: '100%',
    },
    '& .MuiGrid-grid-xs-4': {
      flexBasis: '100%',
      maxWidth: '100%',
      margin: '0 20px',
    },
    '& .MuiTypography-h5': {
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
  },
  field: {
    marginBottom: '20px',
  },
  subtitle: {
    marginBottom: 10,
  },
}));

export default styles;
