import { createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const styles = createStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    marginBottom: '25px',
  },
  logo: {
    width: '160px',
    padding: '5px',
  },
  nested: {
    paddingLeft: theme.spacing(9),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '& span': {
      fontSize: '0.9em',
      lineHeight: '1.2',
    },
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: '#EDE7F6',
    '& span': {
      fontWeight: 'bold',
    },
    '&:hover': {
      backgroundColor: '#EDE7F6',
      color: theme.palette.primary.main,
    },
  },
  activeCollapse: {
    color: theme.palette.primary.main,
    '& span': {
      fontWeight: 'bold',
    },
  },
}));

export default styles;
