import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Typography } from '@material-ui/core';
import { useStateValue } from 'context/store';
import { createOrder } from 'context/orders/actions';
import { modalMessage } from 'utils';
import { I18nContext } from 'translations';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';
import BackButtonTitle from 'components/Layout/BackButtonTitle';
import Form from 'components/Forms/Orders';
import moment from 'moment/moment';

function NewOrder({ history }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([]);
  const { langCode, translate } = useContext(I18nContext);
  const [dispatch] = useStateValue();
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('congratulations'),
      translate('task.taskSuccessfullyCreated'),
      'success',
      translate('continue')
    ).then((result) => {
      result.value && history.push('/app');
    });
  };

  const handleAddress = (suggestion, index) => {
    if (suggestion) {
      address[index] = suggestion;
      setAddress(address);
    } else {
      const updateAddress = address.filter((item, i) => i !== index);
      setAddress(updateAddress);
    }
  };

  const onSubmit = async (values) => {
    const data = values.tasks.filter((x) => x);
    const tasks = data.map((val, i) => {
      const info = {
        ...val,
        due_date: val.due_date
          ? moment(val.due_date, 'DD/MM/YYYY hh:mm:ss A')
          : null,
      };
      return {
        ...info,
        address: address[i].name,
        lat: address[i].lat,
        lon: address[i].lon,
      };
    });
    const { driver_id } = values;
    if (driver_id) {
      setLoading(true);
      const callback = await createOrder(
        dispatch,
        { tasks, driver_id },
        langCode
      );
      callback.status ? handleMessageOk() : handleMessage(callback.message);
    } else {
      handleMessage(translate('task.selectDriverFromList'));
    }
  };

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="light-gray-page">
        <BackButtonTitle
          title={translate('task.createNewTask')}
          link="/app"
          buttonName={translate('back')}
        />
        <Typography variant="h6">{translate('fieldsAreRequired')}</Typography>

        <Grid container spacing={5} style={{ marginTop: 10 }} justify="center">
          <Grid item md={10} className="padding-0-xs">
            <Form
              handleAddress={handleAddress}
              submit={onSubmit}
              translate={translate}
              langCode={langCode}
              isValidOriginAddress
            />
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

NewOrder.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewOrder;
