const webUrlBase = process.env.REACT_APP_API_URL_BASE;
// const longRequestsWebUrlBase = process.env.REACT_APP_API_URL_BASE_LONG_REQUESTS; // URL for long duration requests such as uploads or file downloads

export const api = `${webUrlBase}/api/web`;

export const urlSession = `${api}/sessions`;
export const urlCheckin = `${api}/sessions/checkin`;
export const urlSuggestionAddresses = `${api}/suggestion_addresses`;
export const urlJobs = `${api}/jobs`;
export const urlDrivers = `${api}/organizations/drivers`;
export const urlDriversOnDuty = `${api}/organizations/drivers/on_duty`;
export const urlSearchDrivers = `${api}/organizations/search_drivers`;
export const urlTasks = `${api}/tasks`;
export const urlInviteUser = `${api}/users/invite`;
export const urlAcceptInvitation = `${api}/users/accept_invitation`;
export const urlDriver = `${api}/drivers`;
export const urlTickets = `${api}/tickets`;
export const urlDriversLocation = `${api}/organizations/drivers_location/on_duty`;
export const urlActiveTasks = `${api}/tasks/active`;
export const urlJobsBatch = `${api}/jobs/bulk_create`;
export const urlRecoverPassword = `${api}/users/request_password_reset`;
export const urlUpdatePassword = `${api}/users/update_password`;
export const urlUnlockSignin = `${api}/users/unlock_signin`;
export const urlSignup = `${api}/users`;
export const urlBulkOptimize = `${api}/tasks/bulk_optimize`;
export const urlBatchOptimize = `${api}/tasks/batch_optimize`;
export const urlJobsAssignDriver = `${api}/jobs/assign_drivers`;
export const urlTasksPoints = `${api}/optimization_tasks_files`;
export const urlRouteFileList = `${api}/optimization_tasks_files`;
export const urlHeadquarters = `${api}/organizations/headquarters`;
export const urlJobsTask = `${api}/jobs/tasks`;
export const urlOperator = `${api}/operators`;
export const urlDriversBulkLoad = `${api}/drivers/bulk_load`;
export const urlOperatorsBulkLoad = `${api}/operators/bulk_load`;
export const urlReadElement = `${api}/users/readed_element`;

export const urlVehicles = `${webUrlBase}/api/mobile/vehicle_profiles`;
export const urlReports = `${webUrlBase}/web/tasks/csv_report`;
export const urlBulkLoad = `${webUrlBase}/web/tasks/bulk_load`;
export const urlTrackTasks = `${webUrlBase}/web/tasks`;
export const urlContactForm = `${webUrlBase}/web/public/send_contact_form_email`;
