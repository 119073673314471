import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { unlockSignin } from 'context/users/actions';
import Loading from 'components/Loading';
import styles from './style';

function UnlockSignin({ location, history }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();
  const classes = styles();
  const handleClose = () => setMessage(null);

  const handleMessage = mssg => {
    setMessage(mssg);
    setLoading(false);
  }

  useEffect(() => {
    const unlock = async () => {
      setLoading(true);
      const b_link = location.search.split('=')[1];
      const callback = await unlockSignin(dispatch, { b_link });
      callback.status ? history.push('/login') : handleMessage(callback.message);
    }
    unlock();
  }, [location, history, dispatch]);

  return (
    <div className={classes.root}>
      <Paper elevation={4} variant="outlined" className={classes.wrapper}>
        {loading && <Loading />}
        <img src="/logo.png" alt="logo" className={classes.logo} />
        <Snackbar
          open={message && true}
          autoHideDuration={6000}
          onClose={handleClose}
          message={translate(message)}
        />
      </Paper>
    </div>
  );
}

UnlockSignin.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default UnlockSignin;
