import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { Carousel } from 'components';
import styles from './Styles';

const Banner = ({ classes, title1, title2, images }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item md={8} sm={12}>
        <Box textAlign="left">
          <Typography gutterBottom variant="h3" className={classes.title}>
            <b>{translate(title1)}</b>
            <b style={{ color: '#8029CF' }}>&nbsp;{translate(title2)}</b>
          </Typography>
          <Box textAlign="right" position="relative">
            <img
              src={`images/landing/free-trial.png`}
              className={classes.imgFreeProduct}
              alt=""
              width="140"
            />
          </Box>
          <div className={classes.carousel}>
            <Carousel images={images} />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Banner);
