import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import {
  Grid,
  Typography,
  Snackbar,
  withStyles,
  createStyles,
} from '@material-ui/core';
import { useStateValue } from 'context/store';
import { optimizeRoutes, initView } from 'context/routes/actions';
import { I18nContext } from 'translations';
import { uploadFile } from 'utils/aws';
import PaperBlock from 'components/PaperBlock';
import Loading from 'components/Loading';
import UploadFile from 'components/Routes/UploadFile';
import addon from 'utils/addon';
import { ADDONS } from 'utils/constants';
import { listVehicles } from 'context/vehicles/actions';
import InvalidRows from 'components/BulkLoad/InvalidRows';

const styles = createStyles(() => ({
  container: {
    marginTop: 10,
  },
}));

function OptimizeRoutes({ history, classes }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originAddress, setOriginAddress] = useState(null);
  const [isValidOriginAddress, setIsValidOriginAddress] = useState(false);
  const [files, setFiles] = useState([]);
  const [invalidRows, setInvalidRows] = useState(null);
  const [{ checkin, vehicles }, dispatch] = useStateValue();
  const { langCode, translate } = useContext(I18nContext);
  const per_page = useState(10);

  const columns = useMemo(() => [
    {
      name: 'id',
      value: (row) => row.id,
      error: (row) => row.errors && row.errors.id,
    },
    {
      name: 'address',
      value: (row) => row.address,
      error: (row) => row.errors && (row.errors.lat || row.errors.lon || row.errors.address),
    },
    {
      name: 'description',
      value: (row) => row.description,
      error: (row) => row.errors && row.errors.description,
    },
    {
      name: 'customer',
      value: (row) => row.customer_name,
      error: (row) => row.errors && row.errors.customer_name,
    },
    {
      name: 'countryCode',
      value: (row) => row.customer_phone_country_code,
      error: (row) => row.errors && row.errors.customer_phone_country_code,
    },
    {
      name: 'customerPhone',
      value: (row) => row.customer_phone_number,
      error: (row) => row.errors && row.errors.customer_phone_number,
    },
    {
      name: 'customerEmail',
      value: (row) => row.customer_email,
      error: (row) => row.errors && row.errors.customer_email,
    },
    {
      name: 'type',
      value: (row) => row.type_cd,
      error: (row) => row.errors && row.errors.type_cd,
    },
  ], []);

  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleSuggestOrigin = (info) => {
    if (info.lat) {
      setOriginAddress(info);
      setIsValidOriginAddress(true);
    } else {
      setIsValidOriginAddress(false);
    }
  };

  const handleFile = async (file) => setFiles(file);

  const fileSubmitted = (invalidTasks) => {
    if (invalidTasks.length) {
      setLoading(false);
      setInvalidRows(invalidTasks);
      return;
    }

    history.push('/app/routes-file-list');
  };

  const submitFile = async (values) => {
    setLoading(true);
    const date = Date.parse(new Date());
    const response = await uploadFile(
      files[0],
      `uploads/task/bulk_optimize/${date}`,
    );
    if (response) {
      const callback = await optimizeRoutes(
        dispatch,
        {
          ...values,
          tasks_file_url: response.Location,
          origin_address: originAddress.name,
          origin_lat: originAddress.lat,
          origin_lon: originAddress.lon,
        },
        langCode,
        'file',
      );
      callback.status
        ? fileSubmitted(callback.invalidTasks)
        : handleMessage(callback.message);
    } else {
      setMessage('error.OppsAnErrorHasOccurredTryAgainLater');
    }
  };

  const getVehicles = async (page) => {
    setLoading(true);
    const callback = await listVehicles(dispatch, { page, per_page }, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    initView(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getVehicles(1);
  }, [langCode]); // eslint-disable-line

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <Typography variant="h4">{translate('routes.optimizeRoute')}</Typography>
      <Typography variant="h6">{translate('fieldsAreRequired')}</Typography>
      <Grid
        container
        spacing={2}
        className={classes.container}
        justify="center"
      >
        <Grid item lg={12} md={12} sm={12}>
          <UploadFile
            translate={translate}
            handleFile={handleFile}
            handleSuggestOrigin={handleSuggestOrigin}
            isValidOriginAddress={isValidOriginAddress}
            files={files}
            submit={submitFile}
            langCode={langCode}
            lastFormatUpdate={
              checkin.checkin
              && checkin.checkin.optimization_tasks_file_updated_at
            }
            vehicles={vehicles.data ? vehicles.data : []}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={!!message}
        autoHideDuration={8000}
        onClose={handleClose}
        message={translate(message)}
      />
      {invalidRows && (
        <InvalidRows
          handleClose={() => setInvalidRows(null)}
          rows={invalidRows}
          columns={columns}
          rowId="id"
        />
      )}
    </PaperBlock>
  );
}

const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: true,
};
export default withStyles(styles)(addon(OptimizeRoutes, addonOptions));
