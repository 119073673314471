import {
  urlSession, urlAcceptInvitation, urlRecoverPassword, urlUpdatePassword, urlUnlockSignin, urlSignup, urlReadElement,
} from 'api';
import { post, patch } from 'api/requests';
import device_metadata from 'utils/device';
import handleErrorMessage from 'utils/handleErrorMessage';
import { CREATE_SESSION, ASSIGN_PASSWORD, CLOSE_SESSION } from './constants';

export const createSession = async (dispatch, values) => {
  let status = false;
  let message = null;
  await post(urlSession, { device_metadata, session: { ...values } })
    .then(({ data }) => {
      dispatch({
        type: CREATE_SESSION,
        session: data.token,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const assignPassword = async (dispatch, values) => {
  let status = false;
  let message = null;
  await post(urlAcceptInvitation, { ...values })
    .then(({ data }) => {
      dispatch({
        type: ASSIGN_PASSWORD,
        session: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const closeSession = (dispatch) => {
  localStorage.clear();
  dispatch({
    type: CLOSE_SESSION,
    session: null,
  });
};

export const recoverPassword = async (dispatch, values) => {
  let status = false;
  let message = null;
  await post(urlRecoverPassword, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const changePassword = async (dispatch, values) => {
  let status = false;
  let message = null;
  await patch(urlUpdatePassword, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const unlockSignin = async (dispatch, values) => {
  let status = false;
  let message = null;
  await post(urlUnlockSignin, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const createAccount = async (dispatch, values) => {
  let status = false;
  let message = null;
  await post(urlSignup, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const readElement = async (dispatch, values) => {
  let status = false;
  let message = null;
  await patch(urlReadElement, { ...values })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};
