import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Settings } from '@material-ui/icons';

function SettingsMenu({ translate, classes, handleCollapse, openCollapse }) {
  return (
    <>
      <ListItem
        button
        onClick={() => handleCollapse('settings')}
        className={
          openCollapse.settings ? classes.activeCollapse : classes.itemMenu
        }
      >
        <ListItemIcon>
          <Settings color="primary" />
        </ListItemIcon>
        <ListItemText primary={translate('menu.support')} />
        {openCollapse.settings ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore />
        )}
      </ListItem>
      <Collapse in={openCollapse.settings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to="/app/support/ticket"
            activeClassName={classes.active}
          >
            <ListItemText primary={translate('menu.reportProblem')} />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}

export default SettingsMenu;
