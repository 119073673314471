import { GET_TRACKING } from './constants';
import { urlTrackTasks } from 'api';
import { get, patch } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';

export const listTracking = async (dispatch, id) => {
  let status = false;
  let message = null;
  await get(`${urlTrackTasks}/${id}`, {})
    .then(json => {
      return json.data;
    })
    .then(data => {
      dispatch({
        type: GET_TRACKING,
        tracking: data
      });
      status = true;
    })
    .catch(error => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
}

export const createRating = async (dispatch, values, id, lang) => {
  let status = false;
  let message = null;
  await patch(`${urlTrackTasks}/${id}/rate_driver`, { ...values }, lang)
    .then(({ data }) => {
      status = true
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
}