import React, { useContext } from 'react';
import {
  Grid,
  Button,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Search, AlternateEmail, PhoneAndroid } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import { I18nContext } from 'translations';
import { TASK_STATUSES } from 'utils/constants';
import styles from './style';

const formatDate = (date) => date && date.format('YYYY-MM-DD');

function FiltersAccordion({ classes, onFilter }) {
  const { translate } = useContext(I18nContext);
  const { register, handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: { filters: {} },
  });

  const onSubmit = (values) => {
    const params = {
      ...values,
      ini_created_at: formatDate(values.ini_created_at),
      end_created_at: formatDate(values.end_created_at),
    };

    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });

    onFilter(params);
  };

  return (
    <Paper elevation={0} square className={classes.filterContainer}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item md={4} xs={12}>
            <TextField
              type="text"
              name="driver_email"
              variant="outlined"
              label={translate('driverEmail')}
              fullWidth
              inputRef={register}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmail className={classes.filterIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              type="text"
              name="customer_email"
              variant="outlined"
              label={translate('customerEmail')}
              fullWidth
              inputRef={register}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmail className={classes.filterIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              type="text"
              name="customer_phone_number"
              variant="outlined"
              label={translate('customerPhone')}
              fullWidth
              inputRef={register}
              autoComplete="phone"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneAndroid className={classes.filterIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="task_status">
                {translate('status')}
              </InputLabel>
              <Controller
                control={control}
                name="status_cd"
                label={translate('status')}
                variant="outlined"
                defaultValue=""
                as={
                  <Select className="text-left">
                    <MenuItem value="">{translate('select')}</MenuItem>
                    {Object.keys(TASK_STATUSES).map((option) => (
                      <MenuItem key={option} value={option}>
                        {translate(TASK_STATUSES[option])}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12} className="text-center">
            <Typography>{`${translate('routes.creationDate')}:`}</Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Controller
                as={
                  <KeyboardDatePicker
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    label={translate('since')}
                    fullWidth
                    variant="dialog"
                    autoOk
                    value=""
                    clearable
                    clearLabel={translate('clear')}
                    cancelLabel={translate('cancel')}
                    okLabel={translate('accept')}
                    invalidDateMessage={translate('invalidFormat')}
                    placeholder="DD/MM/YYYY"
                  />
                }
                name="ini_created_at"
                control={control}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={2} xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Controller
                as={
                  <KeyboardDatePicker
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    label={translate('until')}
                    fullWidth
                    variant="dialog"
                    autoOk
                    value=""
                    clearable
                    clearLabel={translate('clear')}
                    cancelLabel={translate('cancel')}
                    okLabel={translate('accept')}
                    invalidDateMessage={translate('invalidFormat')}
                    placeholder="DD/MM/YYYY"
                  />
                }
                name="end_created_at"
                control={control}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={2} xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              startIcon={<Search />}
              fullWidth
            >
              {translate('apply')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default withStyles(styles)(FiltersAccordion);
