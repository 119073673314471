import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { createHeadquarter } from 'context/headquarters/actions';
import { modalMessage } from 'utils';
import PaperBlock from 'components/PaperBlock';
import BackButtonTitle from 'components/Layout/BackButtonTitle';
import Loading from 'components/Loading';
import HeadquarterForm from 'pages/Headquarters/components/form';

function NewHeadquarter({ history }) {
  const [, dispatch] = useStateValue();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({});
  const { langCode, translate } = useContext(I18nContext);
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(translate('headquarters.successfull.created.title'), translate('headquarters.successfull.created.description'), 'success', translate('continue')).then((result) => {
      result.value && history.push('/app/headquarters');
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const callback = await createHeadquarter(dispatch, {
      ...values, address: address.name, lat: address.lat, lon: address.lon,
    }, langCode);
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('headquarters.add')}
          link="/app/headquarters"
          buttonName={translate('back')}
        />
        <Grid container spacing={5} style={{ marginTop: 30 }} justify="center">
          <Grid item md={8}>
            <HeadquarterForm
              submit={onSubmit}
              translate={translate}
              langCode={langCode}
              handleChangeAddress={setAddress}
            />
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

NewHeadquarter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewHeadquarter;
