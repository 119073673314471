import React from 'react';
import {
  Content,
  Header,
  NumbersProduct,
  Social,
  Contact,
  Footer,
} from '../Components';
import { Banner, Description, Features } from './Components';
import { Container, Box } from '@material-ui/core';

const Home = () => (
  <Content>
    <Header />
    <Container maxWidth="md">
      <Box mt={6} mb={6}>
        <Banner />
      </Box>
    </Container>
    <Description />
    <Container maxWidth="md">
      <Box mt={6} mb={6}>
        <Features />
        <NumbersProduct />
      </Box>
    </Container>
    <Contact />
    <Social />
    <Footer />
  </Content>
);

export default Home;
