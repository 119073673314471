import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  container: {
    padding: '60px 0px',
    backgroundColor: '#8029CF',
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export default styles;
