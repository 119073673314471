import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { assignPassword, changePassword } from 'context/users/actions';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';
import Form from 'components/Forms/AssignPassword';
import { ROLES } from 'utils/constants';
import styles from './style';

function AssignPassword({ location, history }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const [{ session }, dispatch] = useStateValue();
  const classes = styles();
  const handleClose = () => setMessage(null);

  const redirect = () => {
    if (location.pathname === '/change-password') {
      history.push('/login');
      return;
    }

    if (session && session.role_cd === ROLES.operator) {
      history.push('/login');
      return;
    }

    history.push('/download-app');
  };

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), translate('passwordAssignedCorrectly'), 'success', translate('accept')).then((result) => {
      result.value && redirect();
    });
  };

  const onSubmit = async (values) => {
    const b_link = location.search.split('=')[1];
    setLoading(true);
    if (location.pathname === '/change-password') {
      const callback = await changePassword(dispatch, { ...values, b_link });
      callback.status
        ? handleMessageOk()
        : handleMessage(translate(callback.message));
    } else {
      const callback = await assignPassword(dispatch, { user: values, b_link });
      callback.status
        ? handleMessageOk()
        : handleMessage(translate(callback.message));
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={4} variant="outlined" className={classes.wrapper}>
        {loading && <Loading />}
        <img src="/logo.png" alt="logo" className={classes.logo} />
        <Typography variant="subtitle1" className="text-center">
          {translate('assignPassword.enterAPasswordForYourUser')}
        </Typography>
        <br />
        <Form onSubmit={onSubmit} translate={translate} />
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={handleClose}
          message={translate(message)}
        />
      </Paper>
    </div>
  );
}

AssignPassword.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default AssignPassword;
