import React, {
  useState,
  useContext,
  // useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { Loading, PaperBlock, BackButtonTitle } from 'components';
import { createDriver } from 'context/drivers/actions';
import { modalMessage } from 'utils';
// import { listHeadquarters } from 'context/headquarters/actions';
import FormDrivers from './form/FormDrivers';

function NewDriver({ history }) {
  const [{
    headquarters,
    // checkin
  }, dispatch] = useStateValue();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const { langCode, translate } = useContext(I18nContext);
  // const per_page = 10;
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const handleMessageOk = () => {
    setLoading(false);
    modalMessage(
      translate('driver.successfullyCreatedDriver'),
      translate('driver.linkHasBeenSentToTheEnteredEmail'),
      'success',
      translate('continue'),
    ).then((result) => {
      result.value && history.push('/app/drivers');
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const callback = await createDriver(
      dispatch,
      {
        user: { ...values, active_weekdays_attributes: schedule },
        user_type: 'driver',
      },
      langCode,
    );
    callback.status ? handleMessageOk() : handleMessage(callback.message);
  };

  // const getHeadquarters = async (page) => {
  //   setLoading(true);
  //   const callback = await listHeadquarters(
  //     dispatch,
  //     { page, per_page },
  //     langCode
  //   );
  //   callback.status ? setLoading(false) : handleMessage(callback.message);
  // };

  // useEffect(() => {
  //   const { addons } = checkin;
  //   if (addons) {
  //     addons.route_optimization && getHeadquarters(1);
  //   }
  // }, [checkin]); // eslint-disable-line

  return (
    <PaperBlock border={0}>
      {loading && <Loading />}
      <div className="white-page">
        <BackButtonTitle
          title={translate('driver.addDriver')}
          link="/app/drivers"
          buttonName={translate('back')}
        />
        <Grid container spacing={5} style={{ marginTop: 30 }} justify="center">
          <Grid item md={8}>
            <FormDrivers
              submit={onSubmit}
              translate={translate}
              headquarters={headquarters}
              handleChangeSchedule={setSchedule}
            />
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </PaperBlock>
  );
}

NewDriver.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewDriver;
