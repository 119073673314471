import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  imgFreeProduct: {
    position: 'absolute',
    right: '10px',
    top: '-24px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  carousel: {
    maxWidth: 525,
    marginTop: '60px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '70vw',
      margin: '60px auto 0px auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70vw',
      margin: '60px auto 0px auto',
    },
  },
  subtitle: {
    color: '#4F4F4F',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 40px',
    },
  },
  cardsBanner: {
    '& img': {
      margin: '10px 20px 10px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem',
    },
  },
}));

export default styles;
