import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    zIndex: 9,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    }
  },
  title: {
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left'
  },
  state: {
    textAlign: 'left',
    color: 'orange'
  },
  avatar: {
    width: 140,
    height: 140,
    border: '1px solid #e0e0e0',
    display: 'inline-block',
  },
  textCenter: {
    textAlign: 'center'
  },
  line: {
    borderColor: theme.palette.primary.main,
    borderWidth: '3px',
    marginTop: '5px'
  },
  driverData: {
    margin: '20px 0',
    textAlign: 'left',
    '& p': {
      marginBottom: 12
    }
  },
  cardBody: {
    padding: '30px 20px'
  },
  qualification: {
    margin: '15px 0 15px 15px',
    color: '#4a4a4a',
    fontSize: '14px',
    '& svg': {
      color: '#f8b133',
      marginLeft: '7px',
      position: 'relative',
      top: 6
    }
  },
  btnMessage: {
    fontSize: 20,
    color: '#ca58f4'
  }
}));

export default styles;