import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    width: '36ch',
  },
  content: {
    height: 450,
    overflowY: 'auto',
    paddingBottom: 75,
  },
  inline: {
    display: 'inline',
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  received: {
    backgroundColor: theme.palette.secondary.light,
    width: '75%',
    margin: 12,
    borderRadius: 12,
  },
  sent: {
    backgroundColor: theme.palette.grey[300],
    width: '75%',
    margin: 12,
    marginLeft: 'auto',
    borderRadius: 12,
  },
  textField: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  text: {
    overflowWrap: 'break-word',
  },
  delete: {
    color: theme.palette.primary.contrastText,
  },
}));

export default styles;
