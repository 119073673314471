import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
  Box,
  InputAdornment,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

FormVehicle.propTypes = {
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

function FormVehicle({ submit, translate, vehicle }) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
  });

  const minValidation = useCallback(
    (value, min) =>
      parseInt(value, 10) >= min || `${translate('minValidation')} ${min}`,
    [translate]
  );

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            name="name"
            label={translate('vehicles.name')}
            defaultValue={vehicle && vehicle.name}
            margin="normal"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: '70' }}
            inputRef={register({
              required: `${translate('requiredField')}`,
            })}
            helperText={errors.name && translate(errors.name.message)}
            error={!!errors.name}
          />
        </Grid>
        <Grid container spacing={2}>
          {/* <Grid item md={6} sm={12} xs={12} className='text-left'>
            <TextField
              name='volume'
              error={!!errors.volume}
              label={translate('vehicles.volume')}
              type='number'
              margin='normal'
              defaultValue={vehicle && vehicle.volume}
              helperText={errors.volume && translate(errors.volume.message)}
              variant='outlined'
              inputRef={register}
              required
              fullWidth
            />
          </Grid> */}
          <Grid item md={12} sm={12} xs={12} className="text-left">
            <TextField
              name="weight"
              label={`${translate('vehicles.weight')} (Kg)`}
              type="number"
              defaultValue={vehicle ? vehicle.weight : 5}
              margin="normal"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Kg</InputAdornment>
                ),
                inputProps: { min: 5 },
              }}
              inputRef={register({
                required: `${translate('requiredField')}`,
                pattern: {
                  value: /^[0-9]+$/,
                  message: `${translate('onlyNumbers')}`,
                },
                validate: {
                  min: (value) => minValidation(value, 5),
                },
              })}
              required
              error={!!errors.weight}
              helperText={errors.weight && translate(errors.weight.message)}
            />
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12} className="text-center">
          <Box mt={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
            >
              {vehicle ? translate('vehicles.edit') : translate('vehicles.add')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormVehicle;
