import { urlVehicles } from 'api';
import { get, post, patch } from 'api/requests';
import handleErrorMessage from 'context/common/actions';
import { LIST_VEHICLES, SELECTED_VEHICLE } from './constants';

export const listVehicles = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await get(urlVehicles, params, lang)
    .then((json) => json.data)
    .then((data) => {
      dispatch({ type: LIST_VEHICLES, vehicles: data });
      status = true;
    })
    .catch((error) => {
      const msg = handleErrorMessage(error, dispatch);
      status = false;
      message = msg;
    });
  return { status, message };
};

export const getByIdVehicle = async (dispatch, id, lang) => {
  let status = false;
  let message = null;
  await get(`${urlVehicles}/${id}`, {}, lang)
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: SELECTED_VEHICLE,
        vehicle: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const createVehicle = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await post(urlVehicles, { ...params }, lang)
    .then((_) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};

export const updateVehicle = async (dispatch, params, id, lang) => {
  let status = false;
  let message = null;
  await patch(`${urlVehicles}/${id}/edit`, { ...params }, lang)
    .then((_) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
};
