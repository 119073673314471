import React, { useContext } from 'react';
import {
  List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar,
  Typography, Toolbar, Badge,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { textPreview } from 'utils';
import styles from './styles';

function ChatList({ chats, handleClickItem }) {
  const classes = styles();
  const { translate } = useContext(I18nContext);

  return (
    <>
      <Toolbar className={classes.bar}>
        {translate('chat.title')}
      </Toolbar>
      <List className={classes.content}>
        {chats && chats.map((chat) => (
          <React.Fragment key={chat.taskId}>
            <ListItem alignItems="flex-start" button onClick={() => handleClickItem(chat)}>
              <ListItemAvatar>
                <Badge
                  color="secondary"
                  overlap="circle"
                  invisible={!chat.unreadOperator}
                  badgeContent={chat.unreadOperator}
                  max={9}
                >
                  <Avatar alt={chat.driverName} src="/broken-image.jpg" />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={`${translate('task')} ${chat.taskExternalId}`}
                secondary={(
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {chat.driverName}
                    </Typography>
                    {` — ${textPreview(chat.lastMessage, 48)}`}
                  </>
          )}
              />
            </ListItem>

            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    </>
  );
}

export default ChatList;
