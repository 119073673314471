import { GET_REPORT } from './constants';
import { urlReports } from 'api';
import { getExcelFile } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';

export const getReport = async (dispatch, params, lang) => {
  let status = false;
  let message = null;
  await getExcelFile(urlReports, params, lang)
    .then(data => {
      dispatch({
        type: GET_REPORT,
        reports: data
      });
      status = true;
    }).catch(error => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message = mssg;
    });
  return { status, message };
}
