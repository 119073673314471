import React from 'react';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import OptimizeForm from 'components/Forms/Orders/OptimizeForm';
import { useForm } from 'react-hook-form';
import FormatUpdate from 'components/BulkLoad/FormatUpdate';

const UploadFile = ({
  submit,
  translate,
  handleFile,
  handleSuggestOrigin,
  isValidOriginAddress,
  langCode,
  lastFormatUpdate,
  vehicles,
  files,
}) => {
  const {
    register, handleSubmit, control, errors, watch,
  } = useForm({
    mode: 'onChange',
  });
  return (
    <form style={{ marginTop: 10 }} onSubmit={handleSubmit(submit)}>
      <FormatUpdate
        date={lastFormatUpdate}
        element="optimization_tasks_file_readed"
      />
      <Typography variant="h6" className="text-center">
        <b>
          {translate('bulkLoad.loadExcelFile')}
          &nbsp; - &nbsp;
        </b>
        <Button
          color="secondary"
          href="/files/formatBulkUploadOptimize.xlsx"
          size="large"
          download
        >
          {translate('bulkLoad.downloadFormatHere')}
        </Button>
      </Typography>
      <div className="container-dropzone">
        <DropzoneArea
          onChange={handleFile}
          acceptedFiles={['.xlsx', '.xls', '.csv']}
          maxFileSize={5000000}
          filesLimit={1}
          dropzoneText={translate('bulkLoad.dragFileHereOrClick')}
          getFileLimitExceedMessage={() => translate('bulkLoad.pleaseUploadOnlyOneFile')}
          getFileAddedMessage={(fileName) => `${fileName} ${translate('bulkLoad.successfullyAdded')}`}
          getFileRemovedMessage={(fileName) => `${fileName} ${'bulkLoad.removed'}`}
          getDropRejectMessage={() => translate('bulkLoad.unsupportedFileType')}
        />
      </div>
      <br />
      <OptimizeForm
        translate={translate}
        control={control}
        register={register}
        errors={errors}
        langCode={langCode}
        handleSuggestOrigin={handleSuggestOrigin}
        vehicles={vehicles}
        watch={watch}
      />
      <br />
      <Button
        variant="contained"
        size="large"
        type="submit"
        color="primary"
        disabled={
          Object.keys(errors).length > 0
          || !isValidOriginAddress
          || !files.length
        }
        onClick={() => {}}
      >
        {translate('task.confirmTask')}
      </Button>
    </form>
  );
};

UploadFile.propTypes = {
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  handleFile: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

export default UploadFile;
