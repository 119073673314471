import React, { useContext, useState, useEffect } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
} from '@material-ui/core';
import moment from 'moment/moment';
import { I18nContext } from 'translations';
import { bulkStatusClass, getFileName } from 'utils';
import { BULK_LOAD_STATUSES } from 'utils/constants';
import styles from '../style';

function RouteFileTable({
  routes, recordsPerPage, getRoutes, statusList,
}) {
  const [currentPage, setCurrentPage] = useState(routes.page);
  const [totalCount, setTotalCount] = useState(routes.pages * recordsPerPage);
  const { translate } = useContext(I18nContext);
  const classes = styles();

  useEffect(() => {
    setTotalCount(routes.pages * recordsPerPage);
    setCurrentPage(routes.page);
  }, [routes, recordsPerPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getRoutes(newPage);
  };

  if (!routes || (routes && routes.data.length <= 0)) {
    return <p>{translate('noData')}</p>;
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>{translate('routes.name')}</TableCell>
          <TableCell>{translate('routes.creationDate')}</TableCell>
          <TableCell>{translate('routes.files')}</TableCell>
          <TableCell>{translate('status')}</TableCell>
          <TableCell>{translate('routes.actions')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {routes.data.length > 0
          && routes.data.map((route, index) => (
            <TableRow key={index.toString()}>
              <TableCell className="bold">{getFileName(route.url)}</TableCell>
              <TableCell>
                {moment(route.created_at).format('DD/MM/YYYY hh:mm a')}
              </TableCell>
              <TableCell>
                <Button href={route.url} color="primary" download>
                  {translate('routes.downloadFile')}
                </Button>
              </TableCell>
              <TableCell>
                <b className={bulkStatusClass(statusList[route._id])}>
                  {translate(BULK_LOAD_STATUSES[statusList[route._id]])}
                </b>
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  target="_blank"
                  href={`/app/optimize-routes/${route._id}`}
                  disabled={statusList[route._id] !== 1}
                >
                  {translate('routes.view')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={totalCount}
            page={currentPage - 1}
            rowsPerPageOptions={[]}
            rowsPerPage={recordsPerPage}
            onChangePage={(event, newPage) => {
              handleChangePage(event, newPage + 1);
            }}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export default RouteFileTable;
