import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import addon from 'utils/addon';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { ADDONS } from 'utils/constants';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Loading } from 'components';
import { listVehicles } from 'context/vehicles/actions';

function AddonFormControlVehicles({ control, vehicleSelected }) {
  const [{ vehicles }, dispatch] = useStateValue();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const per_page = 10;
  const { langCode, translate } = useContext(I18nContext);
  const handleClose = () => setMessage(null);

  const handleMessage = (mssg) => {
    setMessage(mssg);
    setLoading(false);
  };

  const getVehicles = async (page) => {
    setLoading(true);
    const callback = await listVehicles(dispatch, { page, per_page }, langCode);
    callback.status ? setLoading(false) : handleMessage(callback.message);
  };

  useEffect(() => {
    getVehicles(1);
  }, [langCode]); // eslint-disable-line

  return (
    <>
      {loading && <Loading />}
      {vehicles && vehicles.data && (
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="vehicle_profile_id">
            {`${translate('routes.vehicleType')}* `}
          </InputLabel>
          <Controller
            control={control}
            name="vehicle_profile_id"
            defaultValue={vehicleSelected || ''}
            label={`${translate('routes.vehicleType')}`}
            variant="outlined"
            as={(
              <Select>
                <MenuItem value="">
                  <Typography color="textSecondary">{`${translate('select')}`}</Typography>
                </MenuItem>
                {vehicles.data ? (
                  vehicles.data.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {translate(option.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="option_default" value="" disabled>
                    {translate('vehicles.empty.option')}
                  </MenuItem>
                )}
              </Select>
            )}
          />
        </FormControl>
      )}

      <Snackbar
        open={message && true}
        autoHideDuration={6000}
        onClose={handleClose}
        message={translate(message)}
      />
    </>
  );
}

const addonOptions = {
  name: ADDONS.routeOptimization,
  showError: false,
};

AddonFormControlVehicles.propTypes = {
  control: PropTypes.any.isRequired,
  vehicleSelected: PropTypes.string,
};

AddonFormControlVehicles.defaultProps = {
  vehicleSelected: '',
};

export default addon(AddonFormControlVehicles, addonOptions);
