import { urlContactForm } from 'api';
import { post } from 'api/requests';
import { loading, error } from 'context/common/actions';

export const submitContactForm = (dispatch, values, onSuccess) => {
  loading(dispatch, true);
  post(urlContactForm, values)
    .then(() => {
      loading(dispatch, false);
      onSuccess();
    })
    .catch((e) => {
      loading(dispatch, false);
      error(dispatch, e);
    });
};
