import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import styles from './Styles';

const Features = ({ classes, title, items }) => {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container>
      <Grid container item justify="flex-start" alignItems="center">
        <Grid item sm={12} md={7}>
          <Box mt={5}>
            <Typography
              gutterBottom
              align="left"
              className={classes.subtitle}
              variant="h5"
            >
              <b>{translate(title)}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item justify="flex-start" alignItems="center">
        <Grid item sm={12} md={3}>
          <Box
            mt={2}
            mb={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <img
              src={`images/landing/client-phone.gif`}
              alt="phone-gif"
              width={'100%'}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={4}>
          {items.map((item, index) => (
            <Box key={`box-${index}`} mt={2} mb={5} display="flex">
              <img
                width="30"
                height="30"
                alt="routing-box"
                src={`images/landing/${item.img}.png`}
                style={{ marginRight: '10px' }}
              />
              <Typography
                gutterBottom
                align="left"
                className={classes.subtitle}
                variant="body1"
              >
                {item.labels.map((item, index) =>
                  item.bold ? (
                    <b key={`bold-${index}`} style={{ color: '#8029CF' }}>
                      {` ${translate(item.text)} `}
                    </b>
                  ) : (
                    translate(item.text)
                  )
                )}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Features);
